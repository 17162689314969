import React, { createContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface OpportunityOpportunitiesContextProps {
  pageNo: number;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  sortOrder: 'asc' | 'desc';
  setSortOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  filterByState: string[];
  setFilterByState: React.Dispatch<React.SetStateAction<string[]>>;
  filterByProject: string[];
  setFilterByProject: React.Dispatch<React.SetStateAction<string[]>>;
  filterBySource: string[];
  setFilterBySource: React.Dispatch<React.SetStateAction<string[]>>;
  filterByLastImported: boolean;
  setFilterByLastImported: React.Dispatch<React.SetStateAction<boolean>>;
  filterByCustomField: string[];
  setFilterByCustomField: React.Dispatch<React.SetStateAction<string[]>>;
}

// Create the context
export const OpportunityOpportunitiesContext = createContext<OpportunityOpportunitiesContextProps | undefined>(undefined);

// Create the provider component
const OpportunityOpportunitiesContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [searchText, setSearchText] = useState<string>('');

  const [filterByState, setFilterByState] = useState<string[]>([]);
  const [filterByProject, setFilterByProject] = useState<string[]>([]);
  const [filterBySource, setFilterBySource] = useState<string[]>([]);
  const [filterByLastImported, setFilterByLastImported] = useState<boolean>(false);
  const [filterByCustomField, setFilterByCustomField] = useState<string[]>([]);

  return (
    <OpportunityOpportunitiesContext.Provider
      value={{
        pageNo,
        setPageNo,
        limit,
        setLimit,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        searchText,
        setSearchText,
        filterByState,
        setFilterByState,
        filterByProject,
        setFilterByProject,
        filterBySource,
        setFilterBySource,
        filterByLastImported,
        setFilterByLastImported,
        filterByCustomField,
        setFilterByCustomField,
      }}
    >
      {children}
    </OpportunityOpportunitiesContext.Provider>
  );
};

export default OpportunityOpportunitiesContextProvider
