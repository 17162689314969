import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import ExportIcon from 'assets/images/icons/export.svg';
import { createNotification } from 'helpers';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import OpportunityOpportunitiesAPI from 'Redux/API/opportunity/opportunityOpportunitiesAPI';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import ImportSummaryModal from './ImportSummaryModal';
import {
  ImportForType,
  ImportWizardContext,
} from 'Contexts/ImportWizardContextProvider';
import Loader from 'Components/Loader/loader';

let menuOptionsForContacts = ['Full Name', 'Email', 'Eijent Id'];
let menuOptionsForAccounts = ['Account Name'];
let menuOptionsForOpportunities = ['Opportunity Name'];

type MenuOptions = 'Full Name' | 'Email' | 'Account Name' | 'Opportunity Name';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type Errors = {
  file: String;
};

export type InvalidItem = {
  item: string;
  rowNumber: number;
  error: string;
  fieldName: string;
};

export type InvalidItems = InvalidItem[];

export type ValidationResponse = {
  success: boolean;
  inValidItemsCount: number;
  readyForImportCount: number;
  inValidItems: InvalidItems;
  idToAccessTheCorrectData: string;
  idToAccessTheCorrectDataOfAccount: string;
  idToAccessTheCorrectDataOfContact: string;
  idToAccessTheCorrectDataOfOpportunity: string;
  csvData: any;
  totalRows: number;
};

type KeyFieldsAndSkipExistingOptionsType = (
  | 'Contacts'
  | 'Accounts'
  | 'Opportunities'
)[];

function ImportContacts() {
  const importWizardContext = useContext(ImportWizardContext);
  if (!importWizardContext) {
    throw new Error(
      'ImportWizardModal must be used within an ImportWizardContextProvider',
    );
  }
  const { openedModal, dispatchOpenModal, importFor } = importWizardContext;
  const { openImportContacts } = openedModal;

  const fileInputRef = useRef(null);

  const [csv, setCsv] = useState<File | null>(null);
  const [keyFieldForContacts, setKeyFieldForContacts] =
    useState<MenuOptions>('Full Name');
  const [keyFieldForAccounts, setKeyFieldForAccounts] =
    useState<MenuOptions>('Account Name');
  const [keyFieldForOpportunities, setKeyFieldForOpportunities] =
    useState<MenuOptions>('Opportunity Name');
  const [keyFieldsAndSkipExistingOptions, setKeyFieldsAndSkipExistingOptions] =
    useState<KeyFieldsAndSkipExistingOptionsType>([]);
  const [skipExistingContacts, setSkipExistingContacts] = useState(false);
  const [skipExistingAccounts, setSkipExistingAccounts] = useState(false);
  const [skipExistingOpportunities, setSkipExistingOpportunities] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationResponse, setValidationResponse] =
    useState<ValidationResponse>({} as ValidationResponse);

  useEffect(() => {
    let keyFieldsAndSkipExistingOptions: KeyFieldsAndSkipExistingOptionsType =
      [];
    switch (importFor) {
      case 'Contacts':
        keyFieldsAndSkipExistingOptions = ['Contacts'];
        break;
      case 'Accounts':
        keyFieldsAndSkipExistingOptions = ['Accounts', 'Contacts'];
        break;
      case 'Opportunities':
        keyFieldsAndSkipExistingOptions = [
          'Opportunities',
          'Accounts',
          'Contacts',
        ];
        break;
    }
    setKeyFieldsAndSkipExistingOptions(keyFieldsAndSkipExistingOptions);
  }, [importFor]);

  useEffect(() => {
    if (!openImportContacts) {
      setCsv(null);
    }
    // setErrors({} as Errors);
  }, [openImportContacts]);

  const openFirstPage = () => {
    dispatchOpenModal('OPEN_IMPORT_WIZARD_PAGE');
  };

  const handleGoBack = () => {
    openFirstPage();
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const csvFile = event.target.files[0];

      if (!csvFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.');
        return false;
      }
      setCsv(event.target.files[0]);
    }
  };

  const downloadCsvFile = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    try {
      e.preventDefault();
      let res;
      if (importFor === 'Accounts') {
        const opportunityAccountsAPI = new OpportunityAccountsAPI();
        // @ts-ignore
        res = await opportunityAccountsAPI.downloadSampleCSVFile();
      }
      if (importFor === 'Opportunities') {
        const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
        // @ts-ignore
        res = await opportunityOpportunitiesAPI.downloadSampleCSVFile();
      }
      if (importFor === 'Contacts') {
        const opportunityContactsAPI = new OpportunityContactsAPI();
        // @ts-ignore
        res = await opportunityContactsAPI.downloadSampleCSVFile();
      }

      if (res?.data.csvData) {
        const url = window.URL.createObjectURL(new Blob([res?.data?.csvData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Sample.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    } catch (error: any) {
      console.log(error);
      createNotification('error', error.message);
    }
  };

  const isValidCsv = () => {
    let error: Errors = {} as Errors;
    let formIsValid = true;

    if (!csv) {
      formIsValid = false;
      error['file'] = '*Please select csvfile.';
    }
    // setErrors(error);
    return formIsValid;
  };

  const handleValidationCheck = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (isValidCsv()) {
      try {
        if (csv) {
          const formData = new FormData();
          formData.append('csv', csv);
          setIsLoading(true);
          let res;
          if (importFor === 'Accounts') {
            const queryParams = {
              keyFieldForAccounts: keyFieldForAccounts,
              keyFieldForContacts: keyFieldForContacts,
            };
            const opportunityAccountsAPI = new OpportunityAccountsAPI();
            // @ts-ignore
            res = await opportunityAccountsAPI.checkValidationForCsvImportFile(
              formData,
              queryParams,
            );
          }
          if (importFor === 'Opportunities') {
            const opportunityOpportunitiesAPI =
              new OpportunityOpportunitiesAPI();
            // @ts-ignore
            const queryParams = {
              keyField: keyFieldForOpportunities,
            };
            res = await opportunityOpportunitiesAPI.checkValidationForCsvImportFile(formData, queryParams);
          }
          if (importFor === 'Contacts') {
            const opportunityContactsAPI = new OpportunityContactsAPI();
            // @ts-ignore
            const queryParams = {
              keyField: keyFieldForContacts,
            };
            res = await opportunityContactsAPI.checkValidationForCsvImportFile(
              formData,
              queryParams,
            );
          }

          if (res?.status === 200 && res?.data.success) {
            setValidationResponse(res.data);
            dispatchOpenModal('OPEN_IMPORT_SUMMARY');
          } else if (res?.data.status === 201) {
            //   setResponseFromAPI(res?.data);
            //   setOpenValidationPopup(true);

            if (fileInputRef.current) {
              // @ts-ignore
              fileInputRef.current.value = '';
            }
            setCsv(null);
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          createNotification('error', err.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyFieldChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>, setKeyField: React.Dispatch<React.SetStateAction<MenuOptions>>
  ) => {
    setKeyField(e.target.value as MenuOptions);
  };

  const handleSkipExistingChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }, setSkipExisting: React.Dispatch<React.SetStateAction<boolean>>) => {
    setSkipExisting(event.target.checked);
  };

  const getMenuOptions = (optionsFor: ImportForType) => {
    let menuOptions: any = [];
    switch (optionsFor) {
      case 'Contacts':
        menuOptions = menuOptionsForContacts;
        break;
      case 'Accounts':
        menuOptions = menuOptionsForAccounts;
        break;
      case 'Opportunities':
        menuOptions = menuOptionsForOpportunities;
        break;
      default:
        menuOptions = [];
        break;
    }

    return menuOptions.map((option: any) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ));
  };

  return (
    <>
      <Modal
        open={openImportContacts}
        onClose={() => dispatchOpenModal('CLOSE_THE_WIZARD')}
        closeOnOverlayClick={false}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal wider',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid item xs={12} pb={4}>
            <Box component="p" mb={1} className="subsecondary">
              Import {importFor}
            </Box>
            <Box className="small-subtitle">
              Please upload a CSV file and choose how to import the data: import
              only new records, update existing records, or both.
            </Box>
          </Grid>
          {keyFieldsAndSkipExistingOptions.length &&
            keyFieldsAndSkipExistingOptions.map((option) => {
              let keyField;
              let setKeyField: React.Dispatch<React.SetStateAction<MenuOptions>>;
              let skipExisting;
              let setSkipExisting: React.Dispatch<React.SetStateAction<boolean>>;
              switch (option) {
                case 'Contacts':
                  keyField = keyFieldForContacts
                  setKeyField = setKeyFieldForContacts
                  skipExisting = skipExistingContacts
                  setSkipExisting = setSkipExistingContacts
                  break;
                case 'Accounts':
                  keyField = keyFieldForAccounts
                  setKeyField = setKeyFieldForAccounts
                  skipExisting = skipExistingAccounts
                  setSkipExisting = setSkipExistingAccounts
                  break;
                case 'Opportunities':
                  keyField = keyFieldForOpportunities
                  setKeyField = setKeyFieldForOpportunities
                  skipExisting = skipExistingOpportunities
                  setSkipExisting = setSkipExistingOpportunities
                  break;
              
                default:
                  break;
              }
              return (
                <Grid item xs={12} pb={1} borderBottom={'1px solid #EDEBFF'}>
                  <Box component="div" className="inputField">
                    <label>
                      Choose {option} Key Field
                      <span className="required">*</span>
                    </label>

                    <FormControl size="small" fullWidth>
                      <Select
                        type="text"
                        className="form-control select small"
                        placeholder="select"
                        value={keyField}
                        name="keyField"
                        // onChange={this.handleChange}
                        style={{ padding: '4px 0px' }}
                        MenuProps={MenuProps}
                        displayEmpty
                        //@ts-ignore
                        onChange={(e) => handleKeyFieldChange(e, setKeyField)}
                      >
                        <MenuItem value="" disabled hidden>
                          Select
                        </MenuItem>
                        {getMenuOptions(option || 'Contacts')}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={skipExisting}
                          onChange={(e) => handleSkipExistingChange(e, setSkipExisting)}
                        />
                      }
                      label={'Skip Existing ' + option}
                    />
                  </Box>
                </Grid>
              );
            })}

          <Grid item xs={12} pt={2} pb={4}>
            <Box component="div" className="inputField" pb={1}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <label>Select file</label>
                <Box className="small-subtitle">
                  Allowed file size up to: 5MB
                </Box>
              </Stack>
              <input
                type="file"
                className="form-control small"
                placeholder=""
                id="attendeeFile" // check this
                name="attendeeFile" // check this
                onChange={(e) => onFileChange(e)}
                accept=".csv"
                ref={fileInputRef}
              />
            </Box>
            <Box component="label" display="flex" alignItems="center">
              <img alt="" src={ExportIcon} />
              <Box
                component="a"
                href=""
                className="secondary-text small-subtitle"
                pl={1}
                onClick={(e) => {
                  downloadCsvFile(e);
                }}
              >
                Download Sample csv to import
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  // onClick={handleClickOpenImportSummary}
                  onClick={(e) => handleValidationCheck(e)}
                >
                  Continue
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <ImportSummaryModal
        validationResponse={validationResponse}
        keyFieldForContacts={keyFieldForContacts}
        keyFieldForAccounts={keyFieldForAccounts}
        keyFieldForOpportunities={keyFieldForOpportunities}
        skipExistingContacts={skipExistingContacts}
        skipExistingAccounts={skipExistingAccounts}
        skipExistingOpportunities={skipExistingOpportunities}
      />
      {isLoading && <Loader />}
    </>
  );
}

export default ImportContacts;
