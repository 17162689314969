import React, { FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';
import ChatWidget, { Message, WidgetActions } from './Widget';
import { EventTypes, RoutKeys } from 'constants/enums/EventTypes';
interface Props {
  agentId?: string;
  widgetStyle: string;
}
const WidgetContainer: FunctionComponent<Props> = ({ agentId, widgetStyle }) => {
  const widgetRef = useRef<WidgetActions>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [activeChatId, setActiveChatId] = useState<string>('');
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [botStyle, setBotStyle] = useState<any>(null);
  const pingInterval = useRef<number | null>(null);
  const [disableSend, setDisableSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const pingToServer = useCallback((socket: WebSocket) => {
    if (socket.readyState === WebSocket.OPEN) {
      const payload = {
        action: RoutKeys.CHATBOT,
        eventType: EventTypes.PING,
      };
      socket.send(JSON.stringify(payload));
    } else {
      console.error('WebSocket not available for ping');
    }
  }, []);

  const onConnect = useCallback(
    (event: Event, socket: WebSocket, chatId: string | null) => {
      console.log('Connected ', chatId, socket);

      if (socket.readyState === WebSocket.OPEN) {
        const payload = {
          action: RoutKeys.CHATBOT,
          eventType: EventTypes.CONNECT,
          chatbotId: agentId,
          service: 'chat',
          chatId: chatId,
          message: '',
        };
        socket.send(JSON.stringify(payload));

        setIsConnected(true);
        if (pingInterval.current !== null) {
          clearInterval(pingInterval.current);
        }
        pingInterval.current = window.setInterval(() => {
          pingToServer(socket);
        }, 300000); // % minutes interval
      } else {
        console.error('WebSocket not open during connect');
      }
    },
    [agentId, activeChatId]
  );

  const updateHandler = useCallback((update: any) => {
    console.info("Update received ", update);
    const chat = update.connection;
    if (chat && chat.chatId) {
      console.log("Chat id to store : ", chat.chatId);
      localStorage.setItem('illumeet_chat_id', chat.chatId);
      setActiveChatId(chat.chatId);
    }
    if (update.botStyle) {
      setBotStyle(update.botStyle);
      if (chat && chat.messages?.length) {
        const sortedMessages = chat.messages
          .filter((m: any) => m._id !== undefined)
          // .sort((a: any, b: any) => a.index - b.index)
          .map((mes: any) => ({
            _id: mes._id,
            sender: mes.sender,
            text: mes.text,
            timestamp: mes.timestamp,
            index: mes.index,
          }));
        setMessages(sortedMessages);
      } else if (update.botStyle?.initialMessages?.[0]) {
        setMessages([
          {
            sender: 'assistant',
            text: update.botStyle?.initialMessages?.[0],
            id: '000000000',
          },
        ]);
      }
    }
  }, []);
  const chunks: any[] = [];
  const handleChunkedMessage = useCallback((data: any) => {

    const messageChunk = {
      chunk: data.message,
      index: data.data.index,
      id: data.data?.messageId,
    };

    chunks.push(messageChunk);

    const fullMessage = chunks
      .filter(chunk => chunk.id === data.data?.messageId)
      //  .sort((a, b) => a.index - b.index)
      .map(chunk => chunk.chunk)
      .join('');

    if (fullMessage) {
      widgetRef.current?.updateMessageById(data.data?.messageId, fullMessage);
    } else {
      console.error('Incomplete message data received', data);
    }
  }, []);

  const onMessage = useCallback(
    (event: MessageEvent) => {
      setLoading(false);
      const data = JSON.parse(event.data);
      if (!data?.data?.type) {
        console.error('Invalid response type', data);
        return;
      }

      switch (data.data.type) {
        case 'error':
          console.error('Message Error:', data);
          setDisableSent(false);
          break;

        case 'update':
          updateHandler(data.data.update);
          break;

        case 'ping':
          console.log(new Date().toLocaleString(), ':: Connection refreshed via ping');
          break;

        default:
          if (data.message === "||$End#||") {
            setDisableSent(false);
            break;
          }
          handleChunkedMessage(data);
          break;
      }
    },
    [handleChunkedMessage, updateHandler]
  );


  const initializeSocketService = useCallback((chatId: string | null) => {
    if (agentId) {
      const webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
      const newSocket = new WebSocket(webSocketUrl);

      newSocket.onopen = (e) => {
        console.log('WebSocket opened:', e);
        onConnect(e, newSocket, chatId);
      };

      newSocket.onmessage = onMessage;
      newSocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        // if (socket) {
        //   socket.close(); // Close existing socket if there is an error
        // }
      };

      newSocket.onclose = (e) => {
        console.log('WebSocket closed:', e);
        setIsConnected(false);
        // if (pingInterval.current !== null) {
        //   clearInterval(pingInterval.current);
        // }
      };

      setSocket(newSocket);
    }
  }, [agentId, onMessage]);

  const startNewChat = useCallback(() => {
    localStorage.setItem('illumeet_chat_id', "");
    setActiveChatId("");
    if (socket) {
      socket.close();
    }
    initializeSocketService(null);
  }, []);
  useEffect(() => {
    const chatId = localStorage.getItem('illumeet_chat_id');
    if (chatId) {
      setActiveChatId(chatId);
    }

    initializeSocketService(chatId);

    return () => {
      if (socket) {
        console.log("unloading widget");
        socket.close();
      }
      if (pingInterval.current !== null) {
        clearInterval(pingInterval.current);
        pingInterval.current = null;
      }
    };
  }, [initializeSocketService]);

  const onUssrMessage = useCallback(
    (text: string, sender: 'user' | 'assistant', id: string = '') => {
      console.log("chat id while sending messgae: ", activeChatId);
      if (socket && socket.readyState === WebSocket.OPEN && text.length) {
        const payload = {
          action: RoutKeys.CHATBOT,
          eventType: EventTypes.MESSAGE,
          chatbotId: agentId,
          service: 'chat',
          chatId: activeChatId,
          message: text,
        };
        socket.send(JSON.stringify(payload));
        setLoading(true);
        setDisableSent(true);
      } else {
        console.error('WebSocket connection not open or available.');
      }
    },
    [agentId, activeChatId]
  );

  // const onWidgetToggle = useCallback(
  //   (isOpen: boolean) => {
  //     if (!isOpen && (!socket || socket.readyState === WebSocket.CLOSED)) {
  //       initializeSocketService(activeChatId);
  //     } else if (socket) {
  //       socket.close();
  //     }
  //   },
  //   [activeChatId, initializeSocketService]
  // );
  return (
    <div>
      {/* <button onClick={() => {
        if (socket) {
          socket.close();
        }
      }}>
        DISCONNECT
      </button> */}
      {botStyle && (
        <ChatWidget
          ref={widgetRef}
          loading={loading}
          widgetStyle={widgetStyle}
          disableSendButton={disableSend}
          messages={messages}
          onUssrMessage={onUssrMessage}
          onRefresh={startNewChat}
          //   onToggle={onWidgetToggle}
          chatInterface={botStyle}
        />
      )}
    </div>
  );
};

export default WidgetContainer;
