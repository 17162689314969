import React, { ReactNode, createContext, useReducer, useState } from 'react';

export type InitialStateType = {
  openImportWizard: boolean;
  openImportContacts: boolean;
  openImportSummary: boolean;
  openErrorsListModal: boolean;
};

export type ImportForType = "Contacts" | "Accounts" | "Opportunities"

type ActionType =
  | 'OPEN_IMPORT_WIZARD_PAGE' 
  | 'OPEN_CONTACT_PAGE' 
  | 'OPEN_IMPORT_SUMMARY' 
  | 'OPEN_ERROR_LIST_MODAL' 
  | 'CLOSE_THE_WIZARD' 

type ImportWizardContextType = {
  openedModal: InitialStateType;
  dispatchOpenModal: React.Dispatch<ActionType>;
  handleSetImportfor: (useFor: ImportForType) => void;
  importFor: ImportForType | undefined;
};

export const ImportWizardContext = createContext<
  ImportWizardContextType | undefined
>(undefined);

const initialState: InitialStateType = {
  openImportWizard: false,
  openImportContacts: false,
  openImportSummary: false,
  openErrorsListModal: false,
};

const openModalReducer = (
  state: InitialStateType,
  action: ActionType,
): InitialStateType => {
  console.log('action');
  switch (action) {
    case 'OPEN_IMPORT_WIZARD_PAGE':
      return {
        openImportContacts: false,
        openImportSummary: false,
        openErrorsListModal: false,
        openImportWizard: true,
      };
      
    case 'OPEN_CONTACT_PAGE':
      return {
        openImportContacts: true,
        openImportSummary: false,
        openErrorsListModal: false,
        openImportWizard: false,
      };

    case 'OPEN_IMPORT_SUMMARY':
      return {
        openImportSummary: true,
        openImportWizard: false,
        openImportContacts: false,
        openErrorsListModal: false,
      };

    case 'OPEN_ERROR_LIST_MODAL':
      return {
        openErrorsListModal: true,
        openImportSummary: false,
        openImportWizard: false,
        openImportContacts: false,
      };

      case 'CLOSE_THE_WIZARD':
      return {
        openErrorsListModal: false,
        openImportSummary: false,
        openImportWizard: false,
        openImportContacts: false,
      };
    default:
      return state;
  }
};

function ImportWizardContextProvider({ children }: { children: ReactNode }) {
  const [importFor, setImportFor] = useState<ImportForType | undefined>(undefined)
  const [openedModal, dispatchOpenModal] = useReducer(
    openModalReducer,
    initialState,
  );

  const handleSetImportfor = (useFor: ImportForType) => {
    setImportFor(useFor)
  }

  return (
    <ImportWizardContext.Provider value={{ openedModal, dispatchOpenModal, handleSetImportfor, importFor }}>
      {children}
    </ImportWizardContext.Provider>
  );
}

export default ImportWizardContextProvider;
