import React, { SyntheticEvent, useState, useEffect, useRef, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Stack, Box } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { theme } from '../../../theme/theme';
import MoreIcon from '../../../../src/assets/images/icons/more.svg';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import '../../../sass/main.scss';
import { ThemeProvider } from '@material-ui/core/styles';
import ManageCustomFields from '../CustomFields/ManageCustomFields';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { ProjectsList } from 'Pages/AIChatbot/Tabs/General/GeneralDashboard';
import {
  AccountCf,
  ContactCf,
  Opportunity,
  OpportunityState,
  Source,
  Status,
} from 'Redux/Reducers/opportunity/opportunities.reducer';
import moment from 'moment';
import FilterDropDown from 'Common/FilterDropDown';
import { AccountsState } from 'Redux/Reducers/opportunity/accounts.reducer';
import { createNotification, turnCamelCaseToNormalText } from 'helpers';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import Loader from 'Components/Loader/loader';
import DropdownSelectionPopUp from 'Common/DropdownSelectionPopUp';
import StatusDropdown from 'Common/StatusDropdown';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { getSystemLevelPermissions } from '../../../helpers/common';
import OpportunityOpportunitiesAPI from 'Redux/API/opportunity/opportunityOpportunitiesAPI';
import ImportModal from '../CommonComponents/ImportModal';
import AddOrEditOpportunity from './AddOrEditOpportunity';
import OpportunityTransactionalEmails from './OpportunityTransactionalEmails';
import EditIcon from '../../../../src/assets/images/icons/edit.svg';
import { formatDateField } from 'helpers/common';
import CustomFieldFilterDropDown from 'Common/CustomFieldFilterDropDown';
import ManageStates from './States/ManageStates';
import { 
  OpportunityStates as OpportunityDynamicStates, 
  OpportunityStates_State 
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';
import FilterByLastImported from '../Common/FilterByLastImported';
import CustomTooltip from 'Components/CustomHTMLTooltip';
import ViewLayout from '../Common/ViewLayout';
import { OpportunityOpportunitiesContext } from 'Contexts/OpportunityOpportunitiesProvider';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type StatusFilterArr = {
  _id: Status;
  name: string;
}[];

type SourceFilterArr = {
  _id: Source;
  name: string;
}[];

export const sourceFilterArr: SourceFilterArr = [
  { _id: 'aiAgent', name: 'AI Agent' },
  { _id: 'manuallyAdded', name: 'Manually Added' },
  { _id: 'imported', name: 'Imported' },
];

const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 8,
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
  },
};

export type FetchOpportunityQueryParams = {
  from: string;
  pageNo: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
  searchText: string;
  status?: string;
};

type OpportunityProps = {
  projectsList: ProjectsList;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
};

type User = {
  email: string;
  _id: string;
};

export type FetchAccountsQueryParams = {
  pageNo: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
  searchText: string;
  from: string;
  status?: string
};

export default function Opportunities({
  projectsList,
  handleTabChange,
}: OpportunityProps) {
  const opportunityOpportunitiesContext = useContext(OpportunityOpportunitiesContext);
  if (!opportunityOpportunitiesContext) {
    throw new Error(
      'Opportunities must be used within an opportunityOpportunitiesContextProvider',
    );
  }

  const {
    pageNo,
    setPageNo,
    limit,
    setLimit,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    searchText,
    setSearchText,
    filterByState,
    setFilterByState,
    filterByProject,
    setFilterByProject,
    filterBySource,
    setFilterBySource,
    filterByLastImported,
    setFilterByLastImported,
    filterByCustomField,
    setFilterByCustomField,
  } = opportunityOpportunitiesContext

  const [allowed, setAllowed] = useState<any>({});
  const location = useLocation();
  const dispatch = useDispatch();
  const initialRender = useRef(true);

  const { accounts, isLoading: isAccountsLoading } = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const { activeUsersForCompany } = useSelector((state: any) => state.Users);

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const {
    opportunities,
    filterCount,
    totalCount,
    isLoading,
    updateMultipleOpportunityOpportunitiesSuccess,
    deleteOpportunityOpportunitySuccess,
    createOpportunityOpportunitySuccess,
    isLoading: isOpportunitiesLoading,
  } = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  //custom states
  const {opportunityStates: opportunityDynamicStates, fetchAllOpportunityStateSuccess} = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const [openAssignToPopup, setOpenAssignToPopup] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openAction, setOpenAction] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRefAction = React.useRef(null);
  const [showManageCustomFields, setShowManageCustomFields] =
    useState<boolean>(false);
  const [showManageStates, setShowManageStates] = useState<boolean>(false);
  const [searchTextTemp, setSearchTextTemp] = useState<string>('');
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [opportunityToBeEdited, setOpportunityToBeEdited] = useState<
    Opportunity | undefined
  >({} as Opportunity);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [selectedFromStatusDropdown, setSelectedFromStatusDropdown] =
    useState<Status>('' as Status);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [openStatusConfirmationModel, setOpenStatusConfirmationModel] =
    useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [transactionEmailsComp, setTransactionEmailsComp] =
    useState<boolean>(false);
  const [statusToChangeFromConfPopup, setStatusToChangeFromConfPopup] =
    useState<string>('');
  const [dynamicStates, setDynamicStates] = useState<OpportunityDynamicStates>([]);

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchAllOpportunityCustomField();
    fetchAllOpportunityStates();
    fetchAllTheActiveUsers();
    setTransactionEmailsComp(
      (location.state as { transactionEmailsComp?: any; })
        ?.transactionEmailsComp,
    );
  }, []);

  useEffect(() => {
    if (deleteOpportunityOpportunitySuccess) {
      setSelectedCheckboxes([]);
    }
    if (createOpportunityOpportunitySuccess) {
      setSelectedCheckboxes([]);
    }
  }, [
    createOpportunityOpportunitySuccess,
    deleteOpportunityOpportunitySuccess,
  ]);

  useEffect(() => {
    fetchAllOpportunityOpportunities();
  }, [
    pageNo,
    limit,
    sortBy,
    sortOrder,
    searchText,
    filterByState,
    filterByProject,
    filterBySource,
    filterByLastImported,
    filterByCustomField
  ]);

  useEffect(() => {
    if (progressPercentage === 100) {
      fetchAllOpportunityOpportunities();
      setTimeout(() => {
        setProgressPercentage(0);
      }, 500);
    }
  }, [progressPercentage]);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [pageNo]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleEndCountForPagination(totalCount, limit, pageNo, setEndCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (updateMultipleOpportunityOpportunitiesSuccess) {
      setSelectedCheckboxes([]);
    }
  }, [updateMultipleOpportunityOpportunitiesSuccess]);

  useEffect(() => {
    if (location.state) {
      setSearchText(location.state as string);
      setSearchTextTemp(location.state as string);
    }
  }, [location.state]);

  useEffect(() => {
    if (fetchAllOpportunityStateSuccess) {
      setDynamicStates(opportunityDynamicStates);
    }    
  }, [fetchAllOpportunityStateSuccess]);

  const bodyParamsForfiltering = {
    filterByState,
    filterByProject,
    filterBySource,
    filterByCustomField,
    filterByLastImported,
  };

  const fetchAllOpportunityOpportunities = () => {
    const data = {
      queryParams,
      bodyParams: bodyParamsForfiltering,
    };
    dispatch(Actions.fetchAllOpportunityOpportunitiesRequest(data));
  };

  const fetchAllTheActiveUsers = () => {
    try {
      dispatch(Actions.getActiveUsersForCompanyRequest({}));
    } catch (error) {
      console.log('error', error);
    }
  };

  const queryParams: FetchAccountsQueryParams = {
    pageNo,
    limit,
    sortBy,
    sortOrder,
    searchText,
    from: 'viewOpportunity',
  };

  const fetchAllOpportunityCustomField = () => {
    const currentTab = location.pathname.split('/').at(-1);
    const data = {
      customFieldType: currentTab,
      from: 'viewOpportunity',
    };
    dispatch(Actions.fetchAllOpportunityCustomFieldRequest(data));
  };

  const fetchAllOpportunityStates = () => {
    const currentTab = location.pathname.split('/').at(-1);
  const data = {
    opportunityStateType:currentTab
  };
    dispatch(Actions.fetchAllOpportunityStateRequest(data));
  };

  const handleToggleSettings = () => {
    setOpenSettings((prevOpen) => !prevOpen);
  };

  const handleToggleAction = () => {
    setOpenAction((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpenSettings(false);
    setOpenAction(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSettings(false);
    }
  }

  const handleOpenManageCustomFields = () => {
    setShowManageCustomFields(true);
  };

  const handleCloseManageCustomFields = () => {
    setShowManageCustomFields(false);
  };

  const handleOpenManageStates = () => {
    setShowManageStates(true);
  };

  const handleCloseManageStates = () => {
    setShowManageStates(false);
  };

  const resetAddNewModal = () => {
    setOpportunityToBeEdited({} as Opportunity);
  };

  const handleAddOrUpdate = (
    opportunity?: Opportunity,
    isBeingEdited: boolean = false,
  ) => {
    if (isBeingEdited) {
      setOpportunityToBeEdited(opportunity);
      setShowViewModal(true);
    } else if (!isBeingEdited) {
      setOpportunityToBeEdited(opportunity);
      setShowAddNew(true);
    }
  };

  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSelectedCheckboxes([]);
  };

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accountId: string,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, accountId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((id) => id !== accountId),
      );
    }
  };

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      opportunities.forEach((opportunity) => arr.push(opportunity._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleCloseDropdowns = () => {
    setOpenSettings(false);
    setOpenAction(false);
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTextTemp(e.target.value);
  };

  const handleSearchTextSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchText(searchTextTemp);
    setPageNo(1); // pagination
    setStartCount(1); // pagination
    setSelectedCheckboxes([]);
  };

  const handleUpdateMultiple = (key: string, value: any, ids?: string[]) => {
    if (!ids && !selectedCheckboxes.length) {
      createNotification('error', 'Please select opportunities');
    } else {
      const bodyParams = {
        ...bodyParamsForfiltering,
        ids: ids || selectedCheckboxes, // ids will come from status dropdown component
        toUpdate: {
          [key]: value,
        },
      };

      if (bodyParams.toUpdate.assignedToId) {
        queryParams.from = 'assignOpportunity';
      } else if (bodyParams.toUpdate.status) {
        queryParams.from = 'changeOpportunityStatus';
      } else if (bodyParams.toUpdate.isDeleted) {
        queryParams.from = 'deleteOpportunity';
      }

      const data = {
        bodyParams,
        queryParams,
      };
      dispatch(Actions.updateMultipleOpportunityOpportunitiesRequest(data));
    }
    openStatusConfirmationModel && setOpenStatusConfirmationModel(false);
    handleCloseDropdowns();
    closeDeleteConfirmationModel();
  };

  const handleOpenDropdownSelectionPopup = (
    setOpenFn: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!selectedCheckboxes.length) {
      createNotification(
        'error',
        'Please select one or more opportunities to modify',
      );
    } else {
      setOpenFn(true);
    }
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleOpenDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(true);
  };
  const closeStatusChangeConfirmationModel = () => {
    setOpenStatusConfirmationModel(false);
  };

  const handleOpenStatusChangeConfirmationModel = (value: string) => {
    setOpenStatusConfirmationModel(true);
    setStatusToChangeFromConfPopup(value);
  };

  const handleExport = async (onlyExportSelected: boolean) => {
    let data = {
      queryParams,
      bodyParams: {
        ...bodyParamsForfiltering,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    if (onlyExportSelected) {
      data = {
        queryParams,
        bodyParams: {
          ...bodyParamsForfiltering,
          // @ts-ignore
          ids: selectedCheckboxes,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };
    }
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const res = await opportunityOpportunitiesAPI.export(data);
    if (res.data.csvData) {
      const url = window.URL.createObjectURL(new Blob([res?.data?.csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Opportunities.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleClickOpenImportModal = (doOpenImportModal: boolean) => {
    setOpenImportModal(doOpenImportModal);
  };

  const handleOpenImportModal = (isOpen: boolean) => {
    setOpenImportModal(isOpen);
  };

  const showRestOfPages = (cfs: string[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };
  const openTransactionEmailsModal = () => {
    setOpenSettings(false);
    setTransactionEmailsComp(true);
  };
  const closeTransactionEmailsModal = (transactionEmailsModal: boolean) => {
    setTransactionEmailsComp(transactionEmailsModal);
    setSearchText('');
    setSearchTextTemp('');
  };



  return (
    <ThemeProvider theme={theme}>
      {!transactionEmailsComp ? (
        <>
          <Box className="Opportunity-tab">
            {!showViewModal && !showAddNew ? (
            
                <Box className='tab-container'>
                  <Grid container spacing={2} mb={3} className="top-filter-area">
                    <Grid item>
                      <Grid container spacing={1}>
                        <FilterDropDown
                          dropdownArray={dynamicStates}
                          filterBy={filterByState}
                          setFilterBy={setFilterByState}
                          filtering="state"
                          filterCount={filterCount}
                          totalCount={totalCount}
                        />
                        <FilterDropDown
                          dropdownArray={projectsList}
                          filterBy={filterByProject}
                          setFilterBy={setFilterByProject}
                          filtering="projects"
                          filterCount={filterCount}
                          totalCount={totalCount}
                        />
                        <FilterDropDown
                          dropdownArray={sourceFilterArr}
                          filterBy={filterBySource}
                          setFilterBy={setFilterBySource}
                          filtering="source"
                          filterCount={filterCount}
                          totalCount={totalCount}
                        />
                        <Grid item>
                          <FilterByLastImported
                            filterByLastImported={filterByLastImported}
                            setFilterByLastImported={setFilterByLastImported}
                            filterCount={filterCount}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={1.25} className="team-dash-right">
                        <Grid item className="">
                          <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                          >
                            <CustomFieldFilterDropDown
                              dropdownArray={customFields}
                              filterBy={filterByCustomField}
                              setFilterBy={setFilterByCustomField}
                            />

                            <form onSubmit={handleSearchTextSubmit}>
                              <TextField
                                name="searchText"
                                placeholder="Search..."
                                className="search"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img alt="" src={SearchIcon} />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleSearchText}
                                value={searchTextTemp}
                              />
                            </form>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <div>
                            <Button
                              variant="outlined"
                              color="primary"
                              disableElevation
                              ref={anchorRefAction}
                              aria-controls={
                                openAction ? 'menu-list-grow' : undefined
                              }
                              aria-haspopup="true"
                              onClick={handleToggleAction}
                              endIcon={<ArrowDropDownIcon />}
                            >
                              Actions
                            </Button>
                            <Popper
                              open={openAction}
                              style={{ zIndex: 3 }}
                              placement="bottom-start"
                              transition
                              anchorEl={anchorRefAction.current}
                              role={undefined}
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Fade {...TransitionProps} timeout={250}>
                                  <Box
                                    sx={
                                      {
                                        backgroundColor:
                                          theme.palette.common.white,
                                        boxShadow:
                                          '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                      } as any
                                    }
                                  >
                                    <ClickAwayListener onClickAway={handleClose}>
                                      <MenuList
                                        autoFocusItem={openAction}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                      >
                                        {allowed['deleteOpportunity'] && (
                                          <MenuItem
                                            onClick={() =>
                                              handleOpenDeleteConfirmationModel()
                                            }
                                            disabled={!selectedCheckboxes.length}
                                            style={{
                                              color: theme.palette.secondary.main,
                                            }}
                                          >
                                            Delete
                                          </MenuItem>
                                        )}
                                        {allowed['assignOpportunity'] && (
                                          <MenuItem
                                            onClick={() =>
                                              handleOpenDropdownSelectionPopup(
                                                setOpenAssignToPopup,
                                              )
                                            }
                                            disabled={!selectedCheckboxes.length}
                                          >
                                            Assigned To
                                          </MenuItem>
                                        )}
                                        {allowed['viewOpportunity'] && (
                                          <>
                                            <MenuItem
                                              onClick={() => handleExport(true)}
                                              disabled={
                                                !selectedCheckboxes.length
                                              }
                                            >
                                              Export
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => handleExport(false)}
                                              disabled={
                                                selectedCheckboxes.length > 0
                                              }
                                            >
                                              Export All
                                            </MenuItem>
                                          </>
                                        )}
                                        {allowed['createOpportunity'] && (
                                          <MenuItem
                                            onClick={() =>
                                              handleClickOpenImportModal(true)
                                            }
                                          >
                                            Import
                                          </MenuItem>
                                        )}
                                        {/* {allowed['changeOpportunityStatus'] && (
                                          <>
                                            <hr />
                                            <Box
                                              px={2}
                                              className="small-subtitle"
                                            >
                                              Change Status
                                            </Box>
                                            <MenuItem
                                              onClick={() =>
                                                handleOpenStatusChangeConfirmationModel(
                                                  'new',
                                                )
                                              }
                                              disabled={
                                                !selectedCheckboxes.length
                                              }
                                            >
                                              New
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                handleOpenStatusChangeConfirmationModel(
                                                  'inProgress',
                                                )
                                              }
                                              disabled={
                                                !selectedCheckboxes.length
                                              }
                                            >
                                              In Progress
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                handleOpenStatusChangeConfirmationModel(
                                                  'onHold',
                                                )
                                              }
                                              disabled={
                                                !selectedCheckboxes.length
                                              }
                                            >
                                              On Hold
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                handleOpenStatusChangeConfirmationModel(
                                                  'done',
                                                )
                                              }
                                              disabled={
                                                !selectedCheckboxes.length
                                              }
                                            >
                                              Done
                                            </MenuItem>
                                          </>
                                        )} */}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Box>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        </Grid>
                        <Grid item>
                          <div>
                            <Button
                              variant="outlined"
                              color="primary"
                              disableElevation
                              ref={anchorRef}
                              aria-controls={
                                openSettings ? 'menu-list-grow' : undefined
                              }
                              aria-haspopup="true"
                              onClick={handleToggleSettings}
                              endIcon={<ArrowDropDownIcon />}
                            >
                              Settings
                            </Button>
                            <Popper
                              open={openSettings}
                              style={{ zIndex: 3 }}
                              placement="bottom-start"
                              transition
                              anchorEl={anchorRef.current}
                              role={undefined}
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Fade {...TransitionProps} timeout={250}>
                                  <Box
                                    sx={
                                      {
                                        backgroundColor:
                                          theme.palette.common.white,
                                        boxShadow:
                                          '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                      } as any
                                    }
                                  >
                                    <ClickAwayListener onClickAway={handleClose}>
                                      <MenuList
                                        autoFocusItem={openSettings}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                      >
                                        {allowed['opportunityCustomFields'] && (
                                          <MenuItem
                                            onClick={handleOpenManageCustomFields}
                                          >
                                            Custom Fields
                                          </MenuItem>
                                        )}

                                        <MenuItem
                                          onClick={handleOpenManageStates}
                                        >
                                          Custom States
                                        </MenuItem>

                                        {allowed['editOpportunity'] && (
                                          <MenuItem
                                            onClick={openTransactionEmailsModal}
                                          >
                                            Opportunity Transactional Emails
                                          </MenuItem>
                                        )}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Box>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        </Grid>
                        <Grid item>
                          {allowed['createOpportunity'] && (
                            <Button
                              variant="contained"
                              color="primary"
                              disableElevation
                              onClick={() => handleAddOrUpdate()}
                            >
                              Add New
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <TableContainer
                    className="list-table"
                    style={{ maxHeight: 'calc(100vh - 380px)' }}
                  >
                    <Table className="table">
                      <TableHead
                        style={{
                          position: 'sticky',
                          top: '0',
                          backgroundColor: '#f6f6fc',
                          zIndex: 2,
                        }}
                      >
                        <TableRow>
                          <TableCell className="checkbox sticky-cell">
                            <Checkbox
                              color="primary"
                              onClick={handleSelectAllCheckboxes}
                              checked={
                                opportunities.length
                                  ? selectedCheckboxes.length ===
                                    opportunities.length
                                  : false
                              }
                            />
                          </TableCell>
                          <TableCell
                            className={
                              'id sticky-cell ' +
                              (sortBy === 'uniqueEijentId'
                                ? sortOrder === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            style={{ left: 50 }}
                            onClick={() => handleSorting('uniqueEijentId')}
                          >
                           Eijent Id
                          </TableCell>
                          <TableCell
                            className={
                              'email sticky-cell ' +
                              (sortBy === 'name'
                                ? sortOrder === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            style={{ left: 150 }}
                            onClick={() => handleSorting('name')}
                          >
                            Opportunity Name
                          </TableCell>
                          <TableCell
                            className={
                              'status ' +
                              (sortBy === 'state'
                                ? sortOrder === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => handleSorting('state')}
                          >
                            State
                          </TableCell>
                          <TableCell
                            className={
                              'long-text ' +
                              (sortBy === 'assignedTo'
                                ? sortOrder === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => handleSorting('assignedTo.email')}
                          >
                            Assigned To
                          </TableCell>
                          <TableCell
                            className={
                              'custom-field ' +
                              (sortBy === 'projectId'
                                ? sortOrder === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => handleSorting('project.name')}
                          >
                            Project
                          </TableCell>
                          <TableCell
                            className={
                              'text ' +
                              (sortBy === 'source'
                                ? sortOrder === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => handleSorting('source')}
                          >
                            Source
                          </TableCell>
                          {customFields.map((cf) => {
                            return (
                              <TableCell
                                key={cf._id}
                                className={
                                  'long-text ' +
                                  (sortBy ===
                                  `customFieldsWithProperValues.${cf._id}`
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() =>
                                  handleSorting(
                                    `customFieldsWithProperValues.${cf._id}`,
                                  )
                                }
                              >
                                {cf.fieldData.fieldName}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            className={
                              'date-time ' +
                              (sortBy === 'updatedAt'
                                ? sortOrder === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => handleSorting('updatedAt')}
                          >
                            Updated On
                          </TableCell>
                          <TableCell className="options">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(opportunities) && opportunities.length ? (
                          opportunities.map((opportunity) => {
                            return (
                              <TableRow key={opportunity._id}>
                                <TableCell className="checkbox sticky-cell">
                                  <Checkbox
                                    color="primary"
                                    onClick={(e) =>
                                      handleSelectCheckBox(e, opportunity._id)
                                    }
                                    style={{ left: 0 }}
                                    checked={selectedCheckboxes.includes(
                                      opportunity._id,
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  className="id sticky-cell link"
                                  style={{ left: 50 }}
                                  onClick={() =>
                                    handleAddOrUpdate(opportunity, true)
                                  }
                                >
                                  {' '}
                                  {opportunity?.uniqueEijentId || '-'}
                                </TableCell>
                                <TableCell
                                  className="email sticky-cell link"
                                  style={{ left: 150 }}
                                  onClick={() =>
                                    handleAddOrUpdate(opportunity, true)
                                  }
                                >
                                  {' '}
                                  {opportunity?.name || '-'}
                                </TableCell>

                                <TableCell className="status">
                                  {opportunity?.state?.name || '-'}
                                </TableCell>
                                <TableCell className="long-text link" onClick={()=> {
                                  if(opportunity?.assignedTo?.email){
                                    window.open(`mailto:${opportunity?.assignedTo?.email}`)
                                  }
                                }}>
                                  {opportunity?.assignedTo?.email || '-'}
                                </TableCell>
                                <TableCell className="custom-field">
                                  {opportunity?.project?.name || '-'}
                                </TableCell>
                                <TableCell className="text">
                                  {opportunity?.source === 'aiAgent'
                                    ? 'AI Agent'
                                    : opportunity?.source === 'imported'
                                    ? 'Imported'
                                    : 'Manually Added'}
                                </TableCell>
                                {customFields.map((cf: CustomField) => {
                                  return Array.isArray(
                                    opportunity?.customFields?.[cf._id as string],
                                  ) ? (
                                    (
                                      opportunity?.customFields?.[
                                        cf._id as string
                                      ] as string[]
                                    ).length ? (
                                      <TableCell
                                        key={cf._id as string}
                                        className="status"
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems={'center'}
                                          spacing={1}
                                        >
                                          <span
                                            className={`long-text ${cf.fieldData.fieldType === "user" && "link"}`}
                                            style={{ maxWidth: '80%' }}
                                            onClick={()=> {
                                                if(cf.fieldData.fieldType === "user" && opportunity?.customFields?.[
                                                  cf._id as string
                                                ]){
                                                  window.open(`mailto:${(opportunity?.customFieldsWithProperValues?.[
                                                    cf._id as string
                                                  ] as string[])?.[0]}`)
                                                }
                                            }}
                                          >
                                            {' '}
                                            {opportunity?.customFields?.[
                                              cf._id as string
                                            ]
                                              ? (
                                                  opportunity?.customFieldsWithProperValues?.[
                                                    cf._id as string
                                                  ] as string[]
                                                )[0]
                                              : ''}
                                          </span>
                                          {opportunity?.customFields?.[
                                            cf._id as string
                                          ] &&
                                            (
                                              opportunity?.customFields?.[
                                                cf._id as string
                                              ] as string[]
                                            )?.length > 1 && (
                                              <div className="detail-popup">
                                                <span className="count">
                                                  +
                                                  {(
                                                    opportunity?.customFields?.[
                                                      cf._id as string
                                                    ] as string[]
                                                  )?.length - 1}
                                                </span>
                                                <div className="info">
                                                  {showRestOfPages(
                                                    opportunity?.customFieldsWithProperValues?.[
                                                      cf._id as string
                                                    ] as string[],
                                                  )?.map(
                                                    (cfValue: String, i: number) => {
                                                      return (
                                                        <div
                                                          key={i}
                                                          className={`${cf.fieldData.fieldType === "user" && "link"}`}  
                                                          style={{
                                                            zIndex: 999999,
                                                          }}
                                                          onClick={()=> {
                                                            if(cf.fieldData.fieldType === "user" && opportunity?.customFields?.[
                                                              cf._id as string
                                                            ]){
                                                              window.open(`mailto:${cfValue}`)
                                                            }
                                                          }}
                                                        >
                                                          <p>{cfValue}</p>
                                                        </div>
                                                      );
                                                    },
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                        </Stack>
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        className="long-text"
                                        key={cf._id}
                                      >
                                        -
                                      </TableCell>
                                    )
                                  ) : (
                                    <TableCell
                                      key={cf._id}
                                      className={`long-text ${
                                        (allowed['viewAccount'] ||
                                          allowed['viewContacts']) &&
                                        ['account', 'contact', 'url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(
                                          cf.fieldData.fieldType,
                                        ) && opportunity
                                        ?.customFieldsWithProperValues?.[
                                        cf._id as string
                                      ] as string &&
                                        'link'
                                      }`}
                                      onClick={(e) => {
                                        if (
                                          opportunity
                                            ?.customFieldsWithProperValues?.[
                                            cf._id as string
                                          ] as string
                                        ) {
                                          if (
                                            cf.fieldData.fieldType === 'account'
                                          ) {
                                            if (allowed['viewAccount']) {
                                              handleTabChange(
                                                e,
                                                '2',
                                                opportunity
                                                  ?.customFieldsWithProperValues?.[
                                                  cf._id as string
                                                ] as string,
                                              );
                                            } else {
                                              createNotification(
                                                'error',
                                                "Sorry! You don't have permission to view this account",
                                              );
                                            }
                                          } else if (
                                            cf.fieldData.fieldType === 'contact'
                                          ) {
                                            if (allowed['viewContacts']) {
                                              handleTabChange(
                                                e,
                                                '3',
                                                opportunity
                                                  ?.customFieldsWithProperValues?.[
                                                  cf._id as string
                                                ] as string,
                                              );
                                            } else {
                                              createNotification(
                                                'error',
                                                "Sorry! You don't have permission to view this contact",
                                              );
                                            }
                                          } else if (
                                            ['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)
                                          ) {
                                            let url = opportunity
                                              ?.customFieldsWithProperValues?.[
                                              cf._id as string
                                            ] as string;
                                            if (!/^https?:\/\//i.test(url)) {
                                              // If not, prepend 'https://'
                                              url = 'https://' + url;
                                            }
                                            window.open(url, '_blank');
                                          } else if(cf.fieldData.fieldType === 'email'){
                                            window.open(`mailto:${opportunity
                                              ?.customFieldsWithProperValues?.[
                                              cf._id as string
                                            ] as string}`)
                                          } else if(cf.fieldData.fieldType === 'phone'){
                                            window.open(`tel:${opportunity
                                              ?.customFieldsWithProperValues?.[
                                              cf._id as string
                                            ] as string}`)
                                          }
                                        }
                                      }}
                                    >
                                      {cf.fieldData.fieldType === 'contact' ? (
                                        (opportunity
                                          ?.customFieldsWithProperValues?.[
                                          cf._id as string
                                        ] as string) || '-'
                                      ) : cf.fieldData.fieldType === 'date' &&
                                        opportunity
                                          ?.customFieldsWithProperValues?.[
                                          cf._id as string
                                        ] ? (
                                        formatDateField(
                                          opportunity,
                                          cf,
                                          customFields,
                                        )
                                      ) : cf.fieldData.fieldType === 'boolean' ? (
                                        opportunity?.customFieldsWithProperValues?.[
                                          cf._id as string
                                        ]?.toString() == 'true' ? (
                                          'True'
                                        ) :  opportunity?.customFieldsWithProperValues?.[
                                          cf._id as string
                                        ]?.toString() == 'false' ? 'False' : '-'
                                      ) : cf.fieldData.fieldType === 'longText' &&
                                        opportunity
                                          ?.customFieldsWithProperValues?.[
                                          cf._id as string
                                        ] ? (
                                        <CustomTooltip
                                          interactive
                                          title={
                                            <Box className="upgrade-tooltip long-description">
                                              <p>
                                                {
                                                  opportunity
                                                    ?.customFieldsWithProperValues?.[
                                                    cf._id as string
                                                  ] as string
                                                }
                                              </p>
                                            </Box>
                                          }
                                          placement="bottom-start"
                                          arrow={false}
                                        >
                                          <span>
                                            {
                                              opportunity
                                                ?.customFieldsWithProperValues?.[
                                                cf._id as string
                                              ] as string
                                            }
                                          </span>
                                        </CustomTooltip>
                                      ) : (
                                        (opportunity
                                          ?.customFieldsWithProperValues?.[
                                          cf._id as string
                                        ] as string) || '-'
                                      )}
                                    </TableCell>
                                  );
                                })}
                                <TableCell className="date-time">
                                  {moment(opportunity.updatedAt).format(
                                    'MMM, Do YYYY, h:mm A',
                                  )}
                                </TableCell>
                                <TableCell className="options" align="center">
                                  {allowed['editOpportunity'] && (
                                    <div
                                      onClick={() =>
                                        handleAddOrUpdate(opportunity, false)
                                      }
                                    >
                                      <img alt="Edit" src={EditIcon} />{' '}
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{ textAlign: 'center' }}
                            >
                              There are no records to display
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <PaginationFunctional
                    startCount={opportunities.length === 0 ? 0 : startCount}
                    setStartCount={setStartCount}
                    endCount={opportunities.length === 0 ? 0 : endCount}
                    limit={limit}
                    setLimit={setLimit}
                    page_no={pageNo}
                    setPage_no={setPageNo}
                    totalItemsCount={totalCount}
                    listItemsFn={() => {}}
                  />
                </Box>
            ) : showAddNew ? (
                <Box className='tab-container'>
              <AddOrEditOpportunity
                usedFor="Account"
                setShowAddNew={setShowAddNew}
                queryParams={queryParams}
                toBeEdited={opportunityToBeEdited as Opportunity}
                resetAddNew={resetAddNewModal}
              />
              </Box>
            ) : (
              <ViewLayout
                closeViewModal={closeViewModal}
                opportunityToBeEdited={opportunityToBeEdited}
                setShowAddNew={setShowAddNew}
                setShowViewModal={setShowViewModal}
                handleTabChangeFromMainLayout={handleTabChange}
                type="opportunity"
              />
            )}

            {showManageCustomFields && (
              <ManageCustomFields
                handleCloseManageCustomFields={handleCloseManageCustomFields}
                showManageCustomFields={showManageCustomFields}
                handleOpenManageCustomFields={handleOpenManageCustomFields}
              />
            )}

            {showManageStates && (
              <ManageStates
                handleCloseManageStates={handleCloseManageStates}
                showManageStates={showManageStates}
                from={"opportunity"}
              />
            )}

            <DropdownSelectionPopUp
              open={openAssignToPopup}
              setOpen={setOpenAssignToPopup}
              dropdownArray={activeUsersForCompany}
              heading={'Assign To'}
              subheading={
                "Select the user's email from the list to assign the selected opportunities for the necessary actions"
              }
              label={'Assign To'}
              onSaveFn={(value: any) =>
                handleUpdateMultiple('assignedToId', value._id)
              }
            />
          </Box>
          <ConfirmationAlertPopUp
            openModal={openDeleteConfirmationModel}
            closeModalFunc={closeDeleteConfirmationModel}
            title={'Delete Confirmation!'}
            text={'Are you sure you want to delete it?'}
            confirmationButtonText={'Delete'}
            confirmationButtonColor="secondary"
            closeButtonText={'Cancel'}
            functionality={() => handleUpdateMultiple('isDeleted', true)}
          />
          <ConfirmationAlertPopUp
            openModal={openStatusConfirmationModel}
            closeModalFunc={closeStatusChangeConfirmationModel}
            title={'Status Change Confirmation!'}
            text={'Are you sure you want to change the status?'}
            confirmationButtonText={'Yes'}
            confirmationButtonColor="secondary"
            closeButtonText={'Cancel'}
            functionality={() =>
              handleUpdateMultiple('status', statusToChangeFromConfPopup)
            }
          />
          <ImportModal
            open={openImportModal}
            tab={'opportunities'}
            handleOpen={handleOpenImportModal}
            fetchAllOpportunityData={fetchAllOpportunityOpportunities}
          />
        </>
      ) : (
        <OpportunityTransactionalEmails
          closeTransactionEmailsModal={closeTransactionEmailsModal}
        />
      )}
      {(isLoading ||
        isCustomFieldsLoading ||
        isAccountsLoading ||
        isOpportunitiesLoading) && <Loader />}
    </ThemeProvider>
  );
}
