import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import OpportunityCommonAPI from 'Redux/API/opportunity/opportunityCommonAPI';
import { removeStorage } from 'helpers/common';

function* createCommentAndAttachment(action) {
    const opportunityCommonAPI = new OpportunityCommonAPI();
    const response = yield call(opportunityCommonAPI.createCommentAndAttachment, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.createCommentAndAttachmentSuccess(
                    response.data.commentsAndAttachments,
                    response.data.totalComments,
                    response.data.totalAttachments
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.createCommentAndAttachmentError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.createCommentAndAttachmentError(response.data.message));
        }
    } else {
        yield put(Actions.createCommentAndAttachmentError(response.data.message));
    }
}

function* uploadAttachmentToCloud(action) {
    const opportunityCommonAPI = new OpportunityCommonAPI();
    const response = yield call(opportunityCommonAPI.uploadAttachmentToCloud, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.uploadAttachmentToCloudSuccess(),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.uploadAttachmentToCloudError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.uploadAttachmentToCloudError(response.data.message));
        }
    } else {
        yield put(Actions.uploadAttachmentToCloudError(response.data.message));
    }
}

function* updateCommentAndAttachment(action) {
    const opportunityCommonAPI = new OpportunityCommonAPI();
    const response = yield call(opportunityCommonAPI.updateCommentAndAttachment, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.updateCommentAndAttachmentSuccess(
                    response.data.commentsAndAttachments,
                    response.data.totalComments,
                    response.data.totalAttachments
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.updateCommentAndAttachmentError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.updateCommentAndAttachmentError(response.data.message));
        }
    } else {
        yield put(Actions.updateCommentAndAttachmentError(response.data.message));
    }
}

function* deleteCommentAndAttachment(action) {
    const opportunityCommonAPI = new OpportunityCommonAPI();
    const response = yield call(opportunityCommonAPI.deleteCommentAndAttachment, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.deleteCommentAndAttachmentSuccess(
                    response.data.commentsAndAttachments,
                    response.data.totalComments,
                    response.data.totalAttachments
                )
            );
        } else if (response.data.status === 403) {
            yield put(Actions.deleteCommentAndAttachmentError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.deleteCommentAndAttachmentError(response.data.message));
        }
    } else {
        yield put(Actions.deleteCommentAndAttachmentError(response.data.message));
    }
}

function* fetchCommentAndAttachment(action) {
    const opportunityCommonAPI = new OpportunityCommonAPI();
    const response = yield call(opportunityCommonAPI.fetchCommentAndAttachment, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.fetchCommentAndAttachmentSuccess(
                    response.data.commentsAndAttachments,
                    response.data.totalComments,
                    response.data.totalAttachments
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.fetchCommentAndAttachmentError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.fetchCommentAndAttachmentError(response.data.message));
        }
    } else {
        yield put(Actions.fetchCommentAndAttachmentError(response.data.message));
    }
}

function* removeAttachment(action) {
    const opportunityCommonAPI = new OpportunityCommonAPI();
    const response = yield call(opportunityCommonAPI.removeAttachment, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.removeAttachmentSuccess(
                    response.data.commentsAndAttachments,
                    response.data.totalComments,
                    response.data.totalAttachments
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.removeAttachmentError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.removeAttachmentError(response.data.message));
        }
    } else {
        yield put(Actions.removeAttachmentError(response.data.message));
    }
}

const opportunityCommonSaga = {
    deleteCommentAndAttachment,
    updateCommentAndAttachment,
    createCommentAndAttachment,
    uploadAttachmentToCloud,
    fetchCommentAndAttachment,
    removeAttachment
};

export default opportunityCommonSaga;