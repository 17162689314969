import { Button } from '@material-ui/core';
import { Stack, Grid, Box } from '@mui/material';
import { AccountsState } from 'Redux/Reducers/opportunity/accounts.reducer';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { camelCaseToTitleCase, separateByComma } from 'helpers/common';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import {
  Contact,
  ContactCf,
  ContactState,
} from 'Redux/Reducers/opportunity/contacts.reducer';
import Loader from 'Components/Loader/loader';
import { Actions } from 'Redux/Actions';
import { getSystemLevelPermissions } from '../../../../helpers/common';
import moment from 'moment';

type Props = {
  closeViewModal: () => void;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  contactToBeEdited: Contact | undefined;
  commentSectionOpened: boolean;
};

function ContactDetails({ setShowAddNew, setShowViewModal, contactToBeEdited, commentSectionOpened }: Props) {
  const { isLoading: isAccountsLoading } = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const {
    isLoading: isContactsLoading,
    openedContact,
    fetchOpportunityContactSuccess,
  } = useSelector((state: any) => state.OpportunityContacts) as ContactState;

  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState<any>({});
  useState<Contact>({} as Contact);

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchContact(contactToBeEdited._id);
  }, []);

  const fetchContact = async (contactId: string) => {
    const params = {
      id: contactId,
    };
    const bodyParams = {
      sortAccountBy: 'account.name',
      sortAccountOrder: 'desc',
    };
    dispatch(Actions.fetchOpportunityContactRequest(params, bodyParams));
  };

   function formatDateField(listValue:any , cf:any, cid:any) {
  if (cf.fieldData.fieldType === 'date') {
    const dateValue = listValue?.customFields?.[cf._id];
    if (dateValue) {
      const customField = cid.find((format: { _id: any; }) => format._id === cf._id);
      if (customField) {
          const dateFormat = customField && customField.fieldData.dateFormat? customField.fieldData.dateFormat : 'MM/DD/YYYY';
        return moment(dateValue).format(dateFormat);
      }
    }
  }
  return null;
}
  return (
    <>
      <Box className="Opportunity-Account">
        {/* @ts-ignore */}

        <Box className="account-details">
          <Stack mb={2} direction={'row'} justifyContent={'space-between'}>
            <Box className="subtitle">Details</Box>
            {allowed['editContacts'] && (
              <Button
                onClick={() => {
                  setShowAddNew(true);
                  setShowViewModal(false);
                }}
                variant="contained"
                color="primary"
                disableElevation
                size='small'
              >
                Edit
              </Button>
            )}
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Name<span className="required">*</span>:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {contactToBeEdited?.fullName}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">Email:</Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text link"
                  onClick={()=> window.open(`mailto:${contactToBeEdited?.email}`)}>
                    {contactToBeEdited?.email}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    State:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {contactToBeEdited?.state? contactToBeEdited?.state.name:""}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Source:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text ">
                    {/* {openedContact?.source ? camelCaseToTitleCase(openedContact?.source) : '-'} */}
                    {contactToBeEdited?.source === 'aiAgent'
                      ? 'AI Agent'
                      : contactToBeEdited?.source === 'imported'
                      ? 'Imported'
                      : 'Manually Added'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Contact Groups:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text long-text">
                    {separateByComma(contactToBeEdited.audienceNames)}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {customFields.map((cf: CustomField) => {
              return (
                <Grid item xs={12} 
                sm={
                  cf.fieldData.fieldType === 'longText' ? 12 : 
                  commentSectionOpened ? 12: 6
                } 
                  lg={
                    cf.fieldData.fieldType === 'longText' ? 12 :
                    commentSectionOpened ? 6: 4
                  } 
                  key={cf._id}>
                  <Grid container>
                    <Grid item  xs={12}>
                      <Box className="small-subtitle bold primary-text">
                        {cf.fieldData.fieldName}:
                      </Box>
                    </Grid>
                    <Grid item xs={12}  >
                      <Stack direction={'row'} spacing={1}>
                        <Box
                          className={`small-subtitle primary-text ${
                            ['url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) ? 'link' : ''
                          } ${
                            cf.fieldData.fieldType === 'longText' ? '' : ''
                          }
                          `}
                          onClick={(e: SyntheticEvent<Element, Event>) => {
                            if(['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
                              let url = (contactToBeEdited
                                ?.customFields?.[
                                cf._id as string
                              ] as string)
                              if (!/^https?:\/\//i.test(url)) {
                                // If not, prepend 'https://'
                                url ='https://' + url;
                              }
                              window.open(url, "_blank")
                            }
                            if(cf.fieldData.fieldType === 'email'){
                              window.open(`mailto:${contactToBeEdited
                                ?.customFields?.[
                                cf._id as string
                              ] as string}`)
                            } else if(cf.fieldData.fieldType === 'phone'){
                              window.open(`tel:${contactToBeEdited
                                ?.customFieldsWithProperValues?.[
                                cf._id as string
                              ] as string}`)
                            }
                          }}
                        >
                          {Array.isArray(
                            contactToBeEdited?.customFields?.[cf._id as string]
                          )
                            ? 
                              separateByComma(
                                cf.fieldData.fieldType === "list" ? contactToBeEdited?.customFields?.[cf._id as string]
                                :
                                contactToBeEdited?.customFieldsWithProperValues?.[cf._id as string]
                              )?.map((value: string) => <span 
                                className={`long-text ${cf.fieldData.fieldType === "user" && "link"}`}
                                onClick={()=> {
                                  if(cf.fieldData.fieldType === "user" && contactToBeEdited?.customFields?.[
                                    cf._id as string
                                  ]){
                                    window.open(`mailto:${value}`)
                                  }
                                }}
                                style={{display: "block"}}
                              >{value}</span>)
                            : (cf.fieldData.fieldType === 'contact' &&
                                ((
                                  contactToBeEdited?.customFields?.[
                                    cf._id as string
                                  ] as ContactCf
                                ).firstName as string)) ||
                              cf.fieldData.fieldType === 'date' 
                              && contactToBeEdited?.customFields?.[
                                cf._id as string
                              ]
                              ? formatDateField(contactToBeEdited,cf,customFields)
                            : (cf.fieldData.fieldType === 'boolean' && contactToBeEdited?.customFields?.[cf._id as string]) ?
                              (contactToBeEdited?.customFields?.[cf._id as string]?.toString() === "true"? "True": "False")
                            : contactToBeEdited?.customFields?.[cf._id as string] ||
                              ''}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      {(isCustomFieldsLoading || isAccountsLoading) && <Loader />}
    </>
  );
}

export default ContactDetails;
