import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Box } from '@mui/material';
import { ContactState } from 'Redux/Reducers/opportunity/contacts.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { createNotification } from '../../../../helpers';
import { getSystemLevelPermissions } from '../../../../helpers/common';



type Props = {
  contactId: string;
  handleTabChangeFromMainLayout:(
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;

};

function LinkedOpportunities({contactId,handleTabChangeFromMainLayout}: Props) {
  const dispatch = useDispatch();
  const initialRender = useRef(true)
  const { isLoading: isContactsLoading, openedContact } = useSelector(
    (state: any) => state.OpportunityContacts,
  ) as ContactState;

  const [sortBy, setSortBy] = useState<string>('opportunity.name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [allowed, setAllowed] = useState<any>({});

  useEffect(()=>{
    setAllowed(getSystemLevelPermissions());
  },[])

  useEffect(() => {
    if(initialRender.current){
      initialRender.current = false
    } else {
      fetchContact(contactId);
    }
  }, [sortBy, sortOrder]);

  const fetchContact = async (contactId: string) => {
    const params = {
      id: contactId,
      sortOpportunityBy: sortBy,
      sortOpportunityOrder: sortOrder,
    };
    dispatch(Actions.fetchOpportunityContactRequest(params));
  };

  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Box>
      <TableContainer className="list-table">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell
                className={
                  'long-text sticky-cell ' +
                  (sortBy === 'opportunity.name'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('opportunity.name')}
              >
                Opportunity Name
              </TableCell>
              <TableCell
                className={
                  'text' +
                  (sortBy === 'contactDataTypeCf.fieldName'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('contactDataTypeCf.fieldName')}
              >
                Contact Type
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(openedContact.opportunityAndContactDataType) &&
              openedContact.opportunityAndContactDataType.length > 0 ?
              openedContact.opportunityAndContactDataType.map((obj, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell className="long-text sticky-cell link" onClick={(e) =>{
                     if (allowed['viewOpportunity']){
                      handleTabChangeFromMainLayout(e, '1', obj.opportunity.name)
                     }else{
                      createNotification('error', "Sorry! You don't have permission to view this opportunity");

                     }
                    }}>
                      {' '}
                      {obj.opportunity.name}
                    </TableCell>

                    <TableCell className="text">
                      {obj.contactDataTypeCf.fieldName}
                    </TableCell>
                  </TableRow>
                );
              })
              :
              <TableRow>
                <TableCell colSpan={2} align='center'>
                  No Records
                </TableCell>
              </TableRow>

              }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default LinkedOpportunities;
