import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { Modal } from 'react-responsive-modal';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { Button } from '@material-ui/core';
import { theme } from 'theme/theme';
import InvalidIcon from 'assets/images/icons/invalid.svg';
import FormControl from '@mui/material/FormControl';
import { InvalidItems, ValidationResponse } from './ImportContacts';
import { useLocation } from 'react-router';
import { ImportWizardContext } from 'Contexts/ImportWizardContextProvider';

const errorsTypes_contacts = [
  'Invalid Email',
  'Invalid Number',
  'Invalid Date',
  'Invalid Urls',
  'Invalid Boolean Values',
  'Invalid List Values',
  'Invalid User',
  'Mandatory Custom Field Missing',
  'Duplicate Key Field',
  'First Name Missing',
  'Invalid Contacts Groups',
];
const errorsTypes_accounts = [...errorsTypes_contacts, 'Invalid Contact'];
const errorsTypes_opportunities = [...errorsTypes_accounts, 'Invalid Account'];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type Props = {
  validationResponse: ValidationResponse;
};

function ErrorsListModalInImportWizard({ validationResponse }: Props) {
  const importWizardContext = useContext(ImportWizardContext);
  if (!importWizardContext) {
    throw new Error(
      'ImportWizardModal must be used within an ImportWizardContextProvider',
    );
  }

  const { openedModal, dispatchOpenModal, importFor } = importWizardContext;
  const { openErrorsListModal } = openedModal;
  const { inValidItems } = validationResponse;

  const [errorsCount, setErrorsCount] = useState(0);
  const [errorsTypes, setErrorsTypes] = useState<string[]>([]);
  const [list, setList] = useState<InvalidItems>([] as InvalidItems);
  const [selectedErrors, setSelectedErrors] = useState<string[]>([]);

  useEffect(() => {
    setErrorsCount(inValidItems?.length);
  }, [inValidItems]);

  useEffect(() => {
    switch (importFor) {
      case 'Contacts':
        setErrorsTypes(errorsTypes_contacts);
        break;
      case 'Accounts':
        setErrorsTypes(errorsTypes_accounts);
        break;
      case 'Opportunities':
        setErrorsTypes(errorsTypes_opportunities);
        break;
      default:
        setErrorsTypes([]);
        break;
    }
  }, [importFor]);

  useEffect(() => {
    setList(inValidItems);
  }, [inValidItems]);

  useEffect(() => {
    if (selectedErrors.length > 0) {
      const filteredData = inValidItems.filter((item) =>
        selectedErrors.includes(item.error),
      );
      setList(filteredData);
    } else {
      setList(inValidItems);
    }
  }, [selectedErrors]);

  const handleErrorChange = (e: SelectChangeEvent<string[]>) => {
    setSelectedErrors(
      typeof e.target.value === 'string'
        ? e.target.value.split(',')
        : e.target.value,
    );
  };
  const handleOpenImportSummary = () => {
    dispatchOpenModal('OPEN_IMPORT_SUMMARY');
  };

  const downloadErrorFile = () => {
    if (validationResponse.csvData) {
      const url = window.URL.createObjectURL(
        new Blob([validationResponse.csvData]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Error.csv'); // or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <Modal
      open={openErrorsListModal}
      onClose={() => dispatchOpenModal('CLOSE_THE_WIZARD')}
      closeOnOverlayClick={false}
      classNames={{
        overlay: '',
        modal: 'Invite-User-Modal wider',
      }}
      center
    >
      <Grid component="form" container className="modal-wrapper">
        <Grid item xs={12} pb={2}>
          <Box className="subsecondary">
            Validation Result!
          </Box>
          <Box className="small-subtitle">
            Please make changes in sheet and try again
          </Box>
          <Box mt={2}>
            <FormControl size="small" fullWidth>
              <Select
                multiple
                value={selectedErrors}
                onChange={handleErrorChange}
                // renderValue={(selected) => selected.join(', ')}
                renderValue={(selected) => selected.length === 0 ? 'Select' : selected.join(', ')}
                displayEmpty
                MenuProps={MenuProps}
              >
                {/* <MenuItem value="" disabled >
                  Select
                </MenuItem> */}
                {errorsTypes.map((errorType) => (
                  <MenuItem key={errorType} value={errorType}>
                    <Checkbox color='primary'
                      checked={selectedErrors.indexOf(errorType) > -1}
                    />
                    <ListItemText primary={errorType} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} pb={2}>
          <Box
            component="div"
            sx={{
              border: '1px solid rgba(0, 0, 86, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Box
              component={Grid}
              p={1.25}
              sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
              container
            >
              <Grid item xs>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    pl={1}
                    component={'strong'}
                    className=""
                    color={theme.palette.common.black}
                  >
                    <Box component={'span'} mr={1}>
                      <img alt="" src={InvalidIcon} />
                    </Box>
                    Invalid ({errorsCount})
                  </Box>
                  <Box
                    py={0.5}
                    px={1}
                    sx={{
                      borderRadius: '20px  !important',
                      border: '1px solid #5141E7',
                      color: '#5141E7 !important',
                      cursor: 'pointer',
                      fontSize: '12px',
                    }}
                    onClick={downloadErrorFile}
                  >
                    Download Errors File
                  </Box>
                </Stack>
              </Grid>
            </Box>
            <Box
              className="custom-fields-table"
              p={1.25}
              sx={{ maxHeight: '250px' }}
              component={TableContainer}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableBodyCell" scope="row">
                      <strong>Row</strong>
                    </TableCell>
                    <TableCell className="tableBodyCell" scope="row">
                      <strong>Field Name</strong>
                    </TableCell>
                    <TableCell className="tableBodyCell">
                      <strong>Error</strong>
                    </TableCell>
                    <TableCell className="tableBodyCell" >
                      <strong>Value</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {list?.length !== 0 ?

                    list?.map((item, index: number) => (
                      <TableRow key={index}>
                        <TableCell className="tableBodyCell" scope="row">
                          {item.rowNumber}
                        </TableCell>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>{item.fieldName}</strong>
                        </TableCell>
                        <TableCell className="tableBodyCell" >
                          {item.error}
                        </TableCell>
                        <TableCell className="tableBodyCell" >
                          {item.item}
                        </TableCell>
                      </TableRow>
                    ))
                    :
                    <TableRow >
                      <TableCell className="tableBodyCell" scope="row" align='center' colSpan={4}>
                        No records
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="end">
            <Stack spacing={1} direction="row">
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={() => dispatchOpenModal('OPEN_CONTACT_PAGE')}
              >
                Retry
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleOpenImportSummary}
              >
                Go Back
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default ErrorsListModalInImportWizard;
