import {createActions} from "reduxsauce"

const {Types, Creators} = createActions({
    createOpportunityStateRequest: ['data'],
    createOpportunityStateSuccess: ['opportunityStates', 'message'],
    createOpportunityStateError: ['message'],
    deleteOpportunityStateRequest: ['data'],
    deleteOpportunityStateSuccess: ['opportunityStates', 'message'],
    deleteOpportunityStateError: ['message'],
    updateOpportunityStateRequest: ['data'],
    updateOpportunityStateSuccess: ['opportunityStates', 'message', 'updatedOpportunityState'],
    updateOpportunityStateError: ['message'],
    fetchAllOpportunityStateRequest: ['data'],
    fetchAllOpportunityStateSuccess: ['opportunityStates'],
    fetchAllOpportunityStateError: ['message'],
    updateOpportunityStatesOrderRequest: ['data'],
    updateOpportunityStatesOrderSuccess: ['opportunityStates'],
    updateOpportunityStatesOrderError: ['message'],
})

export const Constants = Types
export default Creators