import Button from '@material-ui/core/Button';
import HorizontalTabContext from '@mui/lab/TabContext';
import HorizontalTabList from '@mui/lab/TabList';
import HorizontalTabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import HorizontalTab from '@mui/material/Tab';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { AIChatbotState, Color, LeadsOption, ProfilePic, Settings } from 'Redux/Reducers/aiChatbot.reducer';

import { theme } from '../../../../theme/theme';
import ChatInterface from './TabsSettings/ChatInterface/ChatInterface';
import Model from './TabsSettings/Model/Model';
import Opportunity, { CustomFieldsList } from './TabsSettings/Opportunity/Opportunity';
import { isValidEmail } from 'helpers/common';
import { createNotification } from 'helpers';

const styles = {
  customTabList: {},
  customTab: {
    minHeight: 42,
    width: 150,
    padding: '12px 16px',
    color: '#323338',
    fontWeight: 400,
  },
  customTabPanel: {
    padding: '0px',
  },

  previewChip: {
    minWidth: 160,
    width: '100%',
    border: 0,
    background: '#f3f3f3',
    borderRadius: 8,
    padding: 8,
    justifyContent: 'space-between',
    height: 'auto',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#000',
  },
};

export type TempratureType = number | number[] | boolean;

export type TabType = 'model' | 'opportunity' | 'chatInterface' | 'security';
const SettingsDashboard = () => {
  const { chatBot } = useSelector(
    (store: any) => store.AIChatbot,
  ) as AIChatbotState;
  const settings: Settings = chatBot.settings;
  const [value, setValue] = useState<string>('1');
  //const [state, setState] = useState<Settings>();
  const dispatch = useDispatch();

  const handleTabChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleChange = (
    tab: 'model' | 'opportunity' | 'chatInterface' | 'security',
    toChange: any,
    newVal:
      | string
      | TempratureType
      | ProfilePic
      | Color
      | LeadsOption[]
      | CustomFieldsList
      | boolean,
    toChange2?: string,
  ) => {
    if (toChange === 'collectOpportunityData') {
      dispatch(
        Actions.updateChatbot({
          ...chatBot,
          settings: {
            ...chatBot.settings,
            collectOpportunityData: newVal,
          },
        }),
      );
      return;
    }
    if (toChange === 'suggestedMessages') {
      dispatch(
        Actions.updateChatbot({
          ...chatBot,
          settings: {
            ...chatBot.settings,
            [tab as TabType]: {
              ...chatBot.settings[tab],
              suggestedMessages: [newVal],
            },
          },
        }),
      );
    }
    if (toChange === 'initialMessages') {
      dispatch(
        Actions.updateChatbot({
          ...chatBot,
          settings: {
            ...chatBot.settings,
            [tab as TabType]: {
              ...chatBot.settings[tab],
              initialMessages: [newVal],
            },
          },
        }),
      );
    } else {
      dispatch(
        Actions.updateChatbot({
          ...chatBot,
          settings: {
            ...chatBot.settings,
            [tab as TabType]: {
              ...chatBot.settings[tab],
              [toChange]: toChange2
                ? {
                  // @ts-ignore
                  ...chatBot.settings[tab][toChange],
                  [toChange2]: newVal,
                }
                : newVal,
            },
          },
        }),
      );
    }
  };

  const handleSwitchChange = (option: string, value: boolean, tab: TabType) => {
    // if (tab === 'opportunity') {
    //   setState({
    //     ...state,
    //     leads: {
    //       ...state.leads,
    //       leadsOptions: {
    //         ...state.leads.leadsOptions,
    //         [option]: value,
    //       },
    //     },
    //   });
    // } else
    if (tab === 'chatInterface' || tab === 'security') {
      dispatch(
        Actions.updateChatbot({
          ...chatBot,
          settings: {
            ...chatBot.settings,
            [tab]: {
              ...chatBot.settings[tab],
              [option]: value,
            },
          },
        }),
      );
    }
  };

  const handleSave = () => {
    // if (data) {
    //   settings.collectOpportunityData = data.opportunityFlag;
    //   data = settings;
    // } else {
    if (settings.model.adminEmail?.trim().length && !isValidEmail(settings.model.adminEmail)) {
      createNotification('error', "Please provide correct email address for transcript");
      return
    }
    let data = settings;
    const filteredData: CustomFieldsList = filterCheckedFields(
      data?.opportunity?.customFieldsArray || [],
    );
    data.opportunity.customFieldsArray = filteredData;

    dispatch(
      Actions.saveSettingsForAiChatbotRequest({
        settings: data,
        agentId: chatBot._id,
      }),
    );
  };

  // @ts-ignore
  const filterCheckedFields: (data: CustomFieldsList) => CustomFieldsList = (
    data,
  ) => {
    const arr = data.map((item) => {

      if (item.checked) {
        if (item.fields) {
          return {
            ...item,
            fields: filterCheckedFields(item.fields),
          };
        }
        return item;
      }
      return null;
    }
    );
    const filteredArr = arr.filter((item) => item);
    return filteredArr;
  };

  return (
    <Box className="ai-chat-general">
      <Grid container spacing={0.5} mb={3}>
        <Grid item xs={12} md={12} lg={12} xl={10}>
          <Stack direction={'row'}>
            <Grid
              container
              rowSpacing={4}
              columnSpacing={2.75}
              alignItems="center"
              className="inputField"
            >
              <Grid item>
                <Box component="div">
                  <span className="subtitle">Settings</span>
                </Box>
              </Grid>
            </Grid>

            <Button color="primary" variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3.5}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item xs={12} md={12} lg={12} xl={10}>
          <Box
            sx={{
              background: theme.palette.common.white,
              borderRadius: '8px',
            }}
            className="horizontal-tab"
          >
            <HorizontalTabContext value={value}>
              <Box>
                <HorizontalTabList
                  style={styles.customTabList}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={(e, v) => handleTabChange(v)}
                  aria-label="lab API tabs example"
                >
                  <HorizontalTab
                    style={styles.customTab}
                    iconPosition="start"
                    label="Model"
                    value="1"
                    className="tab"
                  ></HorizontalTab>
                  <HorizontalTab
                    style={styles.customTab}
                    iconPosition="start"
                    label="Opportunity"
                    value="2"
                    className="tab"
                  ></HorizontalTab>
                  <HorizontalTab
                    style={styles.customTab}
                    iconPosition="start"
                    label="Chat Interface"
                    value="3"
                    className="tab"
                  ></HorizontalTab>
                  {/* <HorizontalTab
                    style={styles.customTab}
                    iconPosition="start"
                    label="Security"
                    value="4"
                    className="tab"
                  ></HorizontalTab> */}
                </HorizontalTabList>
              </Box>

              <HorizontalTabPanel style={styles.customTabPanel} value="1">
                <Model state={settings} handleChange={handleChange} />
              </HorizontalTabPanel>
              <HorizontalTabPanel style={styles.customTabPanel} value="2">
                <Opportunity
                  handleChange={handleChange}
                  collectOpportunityData={Boolean(
                    chatBot?.settings?.collectOpportunityData,
                  )}
                  customFieldsArray={settings.opportunity?.customFieldsArray}
                />
              </HorizontalTabPanel>
              <HorizontalTabPanel style={styles.customTabPanel} value="3">
                <ChatInterface
                  state={settings}
                  agentId={chatBot._id}
                  handleChange={handleChange}
                  handleSwitchChange={handleSwitchChange}
                />
              </HorizontalTabPanel>
              {/* <HorizontalTabPanel
                style={styles.customTabPanel}
                value="4"
                className="tab-panel"
              >
                <Security
                  state={state}
                  handleChange={handleChange}
                  handleSwitchChange={handleSwitchChange}
                />
              </HorizontalTabPanel> */}
            </HorizontalTabContext>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsDashboard;
