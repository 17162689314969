export enum EventTypes {
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
  MESSAGE = 'MESSAGE',
  PING = "PING"
}
export enum RoutKeys {
  PING = 'ping',
  CHATBOT = 'chatbot',
}
