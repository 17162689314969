import React, { createContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface OpportunityContactsContextProps {
  sortOrder: 'asc' | 'desc';
  setSortOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  pageNo: number;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  filterByStatus: string[];
  setFilterByStatus: React.Dispatch<React.SetStateAction<string[]>>;
  filterBySource: string[];
  setFilterBySource: React.Dispatch<React.SetStateAction<string[]>>;
  filterByAudience: string[];
  setFilterByAudience: React.Dispatch<React.SetStateAction<string[]>>;
  filterByLastImported: boolean;
  filterByState: string[];
  setFilterByState: React.Dispatch<React.SetStateAction<string[]>>;
  setFilterByLastImported: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string,
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context
export const OpportunityContactsContext = createContext<OpportunityContactsContextProps | undefined>(undefined);

// Create the provider component
const OpportunityContactsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);

  const [filterByStatus, setFilterByStatus] = useState<string[]>([]);
  const [filterBySource, setFilterBySource] = useState<string[]>([]);
  const [filterByAudience, setFilterByAudience] = useState<string[]>([]);
  const [filterByState, setFilterByState] = useState<string[]>([]);
  const [filterByLastImported, setFilterByLastImported] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>('');

  return (
    <OpportunityContactsContext.Provider
      value={{
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        pageNo,
        setPageNo,
        limit,
        setLimit,
        filterByStatus,
        setFilterByStatus,
        filterBySource,
        setFilterBySource,
        filterByAudience,
        setFilterByAudience,
        filterByLastImported,
        setFilterByLastImported,
        searchText,
        setSearchText,
        filterByState,
        setFilterByState
      }}
    >
      {children}
    </OpportunityContactsContext.Provider>
  );
};

export default OpportunityContactsContextProvider