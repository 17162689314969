import { Constants } from "Redux/Actions";
import {createReducer} from "reduxsauce"

export type AccountsState = {
    isLoading: boolean,
    accounts: Account[],
    fetchAllOpportunityAccountsSuccess: boolean,
    error: boolean,
    loading: boolean,
    fetchOpportunityAccountSuccess: boolean,
    openedAccount: Account,
    message: string,
    createOpportunityAccountSuccess: boolean,
    updateOpportunityAccountSuccess: boolean,
    totalCount: number,
    deleteOpportunityAccountSuccess: boolean,
    contactsObj: ContactsObj,
    isSavingAsDraft: boolean,
    filterCount: FilterCount
}

export type FilterCount = {
    statusCount: StatusCount,
    statusAll: number,
    lastImportedAll: number,
}

export type StatusCount = {
    draft: number,
    new: number;
};

export type Account = {
    state: any;
    name: string,
    _id: string,
    customFields: CustomFields,
    customFieldsWithProperValues: CustomFields,
    updatedAt: Date,
    projects: Project[],
    source: Source,
    projectIds: Project[],
    status: "draft" | "",
    uniqueEijentId:string
}

export type Project = {
    eventName: string,
    _id: string;
};

export type Source = 'aiAgent' | 'manuallyAdded' | 'imported';

export type CustomFields = {
    [key: string]: string | string[] | ContactCf
}

export type ContactCf = {_id?: string, firstName: string, middleName?: string, lastName?: string, fullName: string}

export type ContactsObj = {
    [key: string]: string
}

const INITIAL_STATE: AccountsState = {
    isLoading: false,
    accounts: [],
    fetchAllOpportunityAccountsSuccess: false,
    error: false,
    loading: false,
    fetchOpportunityAccountSuccess: false,
    openedAccount: {} as Account,
    message: '',
    createOpportunityAccountSuccess: false,
    updateOpportunityAccountSuccess: false,
    totalCount: 0,
    deleteOpportunityAccountSuccess: false,
    contactsObj: {},
    isSavingAsDraft: false,
    filterCount: {} as FilterCount,
}


const fetchAllOpportunityAccountsRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        fetchAllOpportunityAccountsSuccess: false,
        error: false,
        accounts: [],
        loading: true,
        message: '',
        totalCount: 0,
        contactsObj: {},
    }
}

const fetchAllOpportunityAccountsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchAllOpportunityAccountsSuccess: true,
        error: false,
        accounts: action.accounts,
        loading: false,
        totalCount: action.totalCount,
        contactsObj: action.contactsObj,
        isLoading: false,
        filterCount: action.filterCount
    }
}

const fetchAllOpportunityAccountsError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchAllOpportunityAccountsSuccess: false,
        error: true,
        loading: false,
        accounts: [],
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        isLoading: false,
    }
}

const fetchOpportunityAccountRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchOpportunityAccountSuccess: false,
        error: false,
        openedAccount: {} as Account,
        loading: true,
        message: '',
        isLoading: true,
    }
}
const fetchOpportunityAccountSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchOpportunityAccountSuccess: true,
        error: false,
        openedAccount: action.account,
        isLoading: false,
    }
}
const fetchOpportunityAccountError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchOpportunityAccountSuccess: false,
        error: true,
        openedAccount: {} as Account,
        loading: false,
        message: action.message,
        isLoading: false,
    }
}

const createOpportunityAccountRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createOpportunityAccountSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0,
        contactsObj: {},
        isLoading: true,
    }
}
const createOpportunityAccountSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createOpportunityAccountSuccess: true,
        error: false,
        loading: false,
        openedAccount:  action.account,
        accounts: action.accounts,
        totalCount: action.totalCount,
        contactsObj: action.contactsObj,
        isLoading: false,
    }
}

const createOpportunityAccountError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createOpportunityAccountSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        isLoading: false,
    }
}
const updateOpportunityAccountRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        updateOpportunityAccountSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0,
        contactsObj: {},
        isLoading: true,
    }
}
const updateOpportunityAccountSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        updateOpportunityAccountSuccess: true,
        error: false,
        loading: false,
        openedAccount:  action.account,
        accounts: action.accounts,
        totalCount: action.totalCount,
        contactsObj: action.contactsObj,
        isLoading: false,
    }
}

const updateOpportunityAccountError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        updateOpportunityAccountSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0,
        isLoading: false,
    }
}
const deleteOpportunityAccountRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteOpportunityAccountSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0,
        contactsObj: {},
        isLoading: true,
    }
}
const deleteOpportunityAccountSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteOpportunityAccountSuccess: true,
        error: false,
        loading: false,
        accounts: action.accounts,
        totalCount: action.totalCount,
        contactsObj: action.contactsObj,
        isLoading: false,
    }
}

const deleteOpportunityAccountError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteOpportunityAccountSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        isLoading: false,
    }
}

const setIsSavingAsDraftForAccount = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isSavingAsDraft: action.isSavingAsDraft
    }
}

export const HANDLERS = {
    [Constants.FETCH_ALL_OPPORTUNITY_ACCOUNTS_REQUEST]: fetchAllOpportunityAccountsRequest,
    [Constants.FETCH_ALL_OPPORTUNITY_ACCOUNTS_SUCCESS]: fetchAllOpportunityAccountsSuccess,
    [Constants.FETCH_ALL_OPPORTUNITY_ACCOUNTS_ERROR]: fetchAllOpportunityAccountsError,
    [Constants.FETCH_OPPORTUNITY_ACCOUNT_REQUEST]: fetchOpportunityAccountRequest,
    [Constants.FETCH_OPPORTUNITY_ACCOUNT_SUCCESS]: fetchOpportunityAccountSuccess,
    [Constants.FETCH_OPPORTUNITY_ACCOUNT_ERROR]: fetchOpportunityAccountError,
    [Constants.CREATE_OPPORTUNITY_ACCOUNT_REQUEST]: createOpportunityAccountRequest,
    [Constants.CREATE_OPPORTUNITY_ACCOUNT_SUCCESS]: createOpportunityAccountSuccess,
    [Constants.CREATE_OPPORTUNITY_ACCOUNT_ERROR]: createOpportunityAccountError,
    [Constants.UPDATE_OPPORTUNITY_ACCOUNT_REQUEST]: updateOpportunityAccountRequest,
    [Constants.UPDATE_OPPORTUNITY_ACCOUNT_SUCCESS]: updateOpportunityAccountSuccess,
    [Constants.UPDATE_OPPORTUNITY_ACCOUNT_ERROR]: updateOpportunityAccountError,
    [Constants.DELETE_OPPORTUNITY_ACCOUNT_REQUEST]: deleteOpportunityAccountRequest,
    [Constants.DELETE_OPPORTUNITY_ACCOUNT_SUCCESS]: deleteOpportunityAccountSuccess,
    [Constants.DELETE_OPPORTUNITY_ACCOUNT_ERROR]: deleteOpportunityAccountError,
    [Constants.SET_IS_SAVING_AS_DRAFT_FOR_ACCOUNT]: setIsSavingAsDraftForAccount,

}


export default createReducer(INITIAL_STATE, HANDLERS)
