import React from 'react';
import { Grid, FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State,
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';

interface StateSelectProps {
  label: string;
  name: string;
  dynamicStates: OpportunityDynamicStates;
  selectedState: string;
  handleStateChange: (e: SelectChangeEvent<string>) => void;
  errors?: {
    [key: string]: boolean | string;
  };
}

const StateDropdown: React.FC<StateSelectProps> = ({
  label,
  name,
  dynamicStates,
  selectedState,
  handleStateChange,
  errors,
}) => {
  console.log("selectedState=====>>>",selectedState)
  return (
    <Grid item xs={6}>
      <label>{label}</label>
      <FormControl size="small" fullWidth>
        <Select
          type="text"
          className="form-control select small"
          placeholder="select"
          style={{ padding: '4px 0px' }}
          displayEmpty
          name={name}
          onChange={handleStateChange}
          value={selectedState}
        >
          <MenuItem value="">Select</MenuItem>
          {dynamicStates
            .filter((state) => state.stateType !== 'Draft')
            .map((state) => (
              <MenuItem key={state._id} value={JSON.stringify({ _id: state._id, name: state.name })}>
                {state.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {errors && <span className="error_mesage required">{errors[name]}</span>}
    </Grid>
  );
};

export default StateDropdown;
