import ChatWidget, { WidgetActions } from 'Components/ChatWidget/Widget';
import {
  AIChatbotState,
  ChatInterfaceType,
} from 'Redux/Reducers/aiChatbot.reducer';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

type ChatInterfaceProps = {
  chatInterface: ChatInterfaceType;
};
export default function ChatPreview({ chatInterface }: ChatInterfaceProps) {
  const widgetRef = useRef<WidgetActions>(null);
  const state = useSelector((store: any) => store.AIChatbot) as AIChatbotState;
  return (
    <div>
      <ChatWidget
        disableSendButton={true}
        ref={widgetRef}
        widgetStyle='inline'
        messages={state.widgetPreviewMessages}
        chatInterface={chatInterface}
        for="preview"
      />
    </div>
  );
}
