import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, Radio, RadioGroup } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import {
  CustomFields,
  Source,
  Account,
  Project,
} from '../../../Redux/Reducers/opportunity/accounts.reducer';
import {
  CustomField,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { createNotification } from 'helpers';
import { isValidEmail, isValidURL, validateSocialMediaUrl } from 'helpers/common';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { Opportunity, OpportunityState } from 'Redux/Reducers/opportunity/opportunities.reducer';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import CustomSearchableDropdownForContactAndAccountDataTypeCF from 'Components/CustomDropdowns/CustomSearchableDropdownForContactAndAccountDataTypeCF';
import CreateOrEditContact from '../Accounts/CreateOrEditContact';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import ViewContactOrAccountDetails from '../Accounts/ViewContactOrAccountDetails';
import CustomSearchableMultiSelectedDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMore';
import { ProjectsList } from 'Pages/AIChatbot/Tabs/General/GeneralDashboard';
import { EventsAllAPIWithPagination, getActiveUsersForCompanyAPI } from 'Redux/API';
import DropdownForListDataTypeCF from '../Common/DropdownForListDataTypeCF';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import InputFieldForPhoneDataTypeCF from '../Common/InputFieldForPhoneDataTypeCF';
import moment from 'moment';
import CustomDropdownWithActiveUsers from 'Components/CustomDropdowns/CustomDropdownWithActiveUsers';
import ReactDatePicker from 'react-datepicker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type CustomFieldsValidationError = {
  [key: string]: boolean | string;
};

type CustomFieldsObj = {
  [key: string]: string | string[];
};

type Props = {
  closeAddNewPopup: (cfId?: string) => void;
  cfId: string;
  handleAccountCFChangeForSearchableDropdown: (
    openedAccount: Account | null,
    cfId: string,
    isSaving?: boolean
  ) => void;
  accountToBeEdited?: Account;
  onClose: () => void;
  addNewAccount: (cfId: string, addingNew: boolean) => void;
  toBeEdited?: Opportunity;
  callFrom: string;
};

type BodyParams = {
  name: string;
  customFields: CustomFieldsObj;
  projectIds?: string[];
  _id?: string;
  source: Source;
};

type BodyToCheckEmptyObject = {
  [key: string]: CustomFieldsInBody | string | string[];
};

type CustomFieldsInBody = {
  [key: string]: string;
};

type AlreadyExistingData = {
  name?: string,
  projectIds?: string[],
  customFields?: CustomFieldsObj;
};

type GetProjectsParams = {
  page_no: number;
  limit: number;
  searchText: string;
};

let confirmationPopupMessgae = "";
let changesMade = false;
let changesMadeInCreateOrEditContact = false;
const limitForEventsList: number = 20;

const CreateOrEditAccount = forwardRef(({
  cfId,
  handleAccountCFChangeForSearchableDropdown,
  accountToBeEdited,
  onClose,
  closeAddNewPopup,
  addNewAccount,
  toBeEdited,
  callFrom,
}:
  Props, ref) => {
  const dispatch = useDispatch();
  const childRefs = useRef<any>([]);
  const savingContactAlso = useRef(false);
  const firstRender3 = useRef(true);
  const firstRender1 = useRef(true);
  const haveToCallCreateOrUpdateContactFunctionInChild = useRef(false);
  const completeFormIsValid = useRef(false);
  const isSavingAccount = useRef(false);
  const isUnlinkingAccount = useRef(false);
  const addingNewAccount = useRef(false);
  const isValidForDraft = useRef(false);
  const isUnlinkingFromParent = useRef(false);

  const opportunityState = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const { activeUsersForCompany } = useSelector((state: any) => state.Users);

  const [customFields, setCustomFields] = useState<CustomFields>({});
  const [customFieldsObj, setCustomFieldsObj] = useState<CustomFieldsObj>({});
  const [idBeingEdited, setIdBeingEdited] = useState<string>('');
  const [customFieldsValidationError, setCustomFieldsValidationError] =
    useState<CustomFieldsValidationError>({} as CustomFieldsValidationError);
  const [name, setName] = useState<string>('');
  const [source, setSource] = useState<Source>('manuallyAdded');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCustomFieldsLoading, setIsCustomFieldsLoading] = useState<boolean>(false);
  const [alreadyExistingDataInTheForm, setAlreadyExistingDataInTheForm] = useState<AlreadyExistingData>({ name: "", projectIds: [] });
  const [openConfirmationPop, setOpenConfirmationPop] = useState<boolean>(false);
  const [isFormEmpty, setIsFormEmpty] = useState<boolean>(false);
  const [isUnlinking, setIsUnlinking] = useState<boolean>(false);
  const [contactsSearchLists, setContactsSearchLists] = useState<{
    [key: string]: Contact;
  } | null>(null);
  const [selectedContactAsCFValue, setSelectedContactAsCFValue] = useState<{
    [key: string]: Contact;
  }>({});
  const [dynamicContactOpenState, setdynamicContactOpenState] = useState<{
    [key: string]: boolean;
  }>({});
  const [customFieldsFromContacts, setCustomFieldsFromContacts] = useState<
    CustomField[]
  >({} as CustomField[]);
  const [
    previouslySelectedContactAsCFValue,
    setPreviouslySelectedContactAsCFValue,
  ] = useState<{
    [key: string]: Contact;
  }>({});
  const [openAddNewContactConfirmationPopup, setOpenAddNewContactConfirmationPopup] = useState<boolean>(false);
  const [openChangeSelectedContactConfPopup, setOpenChangeSelectedContactConfPopup] = useState<boolean>(false);
  const [optionForHandleContactCFChangeForSearchableDropdown, setOptionForHandleContactCFChangeForSearchableDropdown] = useState<any>({});
  const [cfidForHandleContactCFChangeForSearchableDropdown, setCfidForHandleContactCFChangeForSearchableDropdown] = useState<string>("");
  const [activeCfIdForContactDropdown, setActiveCfIdForContactDropdown] =
    useState<string>('');
  const [searchTextForContactDropdown, setSearchTextForContactDropdown] =
    useState<string>('');
  const [cfIdForAddNewContact, setCfIdForAddNewContact] = useState<string>("");
  const [contactConfirmationText, setContactConfirmationText] = useState<string>("");
  const [validationRequired, setValidationRequired] = useState<boolean[]>([]);
  const [projectsList, setProjectsList] = useState<ProjectsList>([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [searchTextForProjectDropdown, setSearchTextForProjectDropdown] =
    useState<string>('');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [listOfSelectedProjectId, setListOfSelectedProjectId] = useState<
    string[]>([]);
  const [formIsLoaded, setFormIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsCustomFieldsLoading(true);
    fetchAllAccountsCustomField();
    fetchAllContactsCustomField();
  }, []);

  useEffect(() => {
    let timeout: any;
    if (firstRender1.current) {
      firstRender1.current = false;
    } else {
      if (name.length) {
        timeout = setTimeout(() => {
          checkIfNameAlreadyExists();
        }, 500);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [name]);

  const checkIfNameAlreadyExists = async () => {
    let opportunityAccountsAPI = new OpportunityAccountsAPI();
    const bodyParams = {
      name: name.trim(),
      id: idBeingEdited || ""
    };
    let res = await opportunityAccountsAPI.checkIfNameAlreadyExists(bodyParams);
    if (res.data) {
      setCustomFieldsValidationError(prev => ({ ...prev, name: "Name already exists" }));
    } else {
      setCustomFieldsValidationError(prev => ({ ...prev, name: "" }));
    }
  };

  useEffect(() => {
    // if(idBeingEdited){
    const isObjEmpty = isEmptyObject({
      // @ts-ignore
      customFields: customFieldsObj,
      name,
      projectIds: listOfSelectedProjectId,
    });
    setIsFormEmpty(isObjEmpty);
    // }
  }, [customFieldsObj, name, listOfSelectedProjectId.length]);

  useEffect(() => {
    let timeout: any;
    if (firstRender3.current) {
      firstRender3.current = false;
    } else {
      if (searchTextForContactDropdown.length > 2) {
        timeout = setTimeout(() => {
          fetchAllContacts();
        }, 500);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [searchTextForContactDropdown]);

  const fetchAllAccountsCustomField = async () => {
    try {
      let from = '';
      if (toBeEdited &&
        Object?.keys(toBeEdited)?.length) {
        if (callFrom === "opportunity") {
          from = 'editOpportunity';
        } else {
          from = 'editAccount';
        }
      } else {
        if (callFrom === "opportunity") {
          from = 'createOpportunity';
        } else {
          from = 'createAccount';
        }
      }
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'accounts',
        from: from,
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      if (res.data.success) {
        setCustomFields(res.data.customFields);
        settingCustomFieldObjWithUndeletedCustomFields(res.data.customFields);
      }
      setIsCustomFieldsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllContactsCustomField = async () => {
    const opportunityAccountsCustomFieldsAPI =
      new OpportunityAccountsCustomFieldsAPI();
    let from = 'createAccount';
    if (toBeEdited && Object?.keys(toBeEdited).length) {
      from = "editAccount";
    }
    try {
      const data = {
        customFieldType: 'contacts',
        from: from
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      if (res.data.success) {
        setCustomFieldsFromContacts(res.data.customFields);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const settingCustomFieldObjWithUndeletedCustomFields = (
    customFieldsAsArgument: CustomFields,
  ) => {
    const customFieldsArr = customFieldsAsArgument || customFields;
    const obj: CustomFieldsObj = {};
    Array.isArray(customFieldsArr) &&
      customFieldsArr.forEach((cf) => {
        if (cf.fieldData.fieldType === 'boolean') {
          return (obj[cf._id as string] = '');
        } else {
          return (obj[cf._id as string] = '');
        }
      });
    return obj;
  };

  const settingCustomFieldObjWithExistingCustomFieldsForAGivenContact = (
    existingCFInContact: CustomFieldsObj,
  ) => {
    let commonKeysObj: CustomFieldsObj = {};
    let obj: any = {};
    Array.isArray(customFields) &&
      customFields.forEach((cf) => {
        if (Object.keys(existingCFInContact).includes(cf._id as string)) {
          if (cf.fieldData.fieldType === 'contact') {
            fetchContactByIdToSetInSelectedContactAsCFValue2(
              // have to check if customFields format needs to change 
              (existingCFInContact[cf._id as string] as unknown as string), cf._id as string
            );
          }
          return (commonKeysObj[cf._id as string] =
            existingCFInContact[cf._id as string]);
        } else {
          if (cf.fieldData.fieldType === 'boolean') {
            commonKeysObj[cf._id as string] = '';
          } else if (cf.fieldData.fieldType === 'contact') {
            obj[cf._id] = "";
            commonKeysObj[cf._id as string] = "";
            setSelectedContactAsCFValue(obj);
          }
          else {
            commonKeysObj[cf._id as string] = '';
          }
        }
      });
    setCustomFieldsObj(commonKeysObj);
    setAlreadyExistingDataInTheForm(prev => {
      return {
        ...prev,
        customFields: commonKeysObj
      };
    });
  };

  useEffect(() => {
    resetForm();
  }, [accountToBeEdited, customFields]);

  useEffect(() => {
    if (validationRequired.length && validationRequired.length === Object.keys(dynamicContactOpenState).length) {
      const isAccountFormValid = isValid();
      const isValidationRequiredInContactForms = validationRequired.includes(true);
      if (!isValidationRequiredInContactForms && isAccountFormValid) {
        completeFormIsValid.current = true;
        setValidationRequired([]);
        saveContactInTheChildComp();
      } else {
        setValidationRequired([]);
      }
    }
  }, [validationRequired]);

  useEffect(() => {
    if (savingContactAlso.current && Object.keys(dynamicContactOpenState).length === 0) {
      saveNewAccountOrUpdate();
      savingContactAlso.current = false;
    }
  }, [dynamicContactOpenState]);

  useEffect(() => {
    const params = {
      page_no: pageNo,
      limit: limitForEventsList,
      searchText: searchTextForProjectDropdown,
    };
    getProjectList(params);
  }, [pageNo]);

  useEffect(() => {
    setPageNo(1);
    const params = {
      page_no: 1,
      limit: limitForEventsList,
      searchText: searchTextForProjectDropdown,
    };
    const timeout = setTimeout(() => {
      getProjectList(params);
    }, 400);
    return () => clearTimeout(timeout);
  }, [searchTextForProjectDropdown]);

  useImperativeHandle(ref, () => {
    return {
      handleAddOrUpdate,
      handlevalidation,
      checkIfFormHasChanged,
      cfId,
      saveAccountBeforeUnlinking,
      idBeingEdited,
      isFormEmpty,
      resetForm,
      completeFormIsValid: completeFormIsValid.current,
      saveContactInTheChildComp,
      checkValidationInChildContactComp,
      checkIfContactInfoHasChangedInTheChild,
      isValid,
      setCustomFieldsValidationErrorToDefault,
      setErrorsToDefaultInContactChildComp,
      handleValidationCfIsMandatory,
      isValidForDraft: isValidForDraft.current
    };
  });

  const setErrorsToDefaultInContactChildComp = () => {
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.setCustomFieldsValidationErrorToDefault) {
        childRef.setCustomFieldsValidationErrorToDefault();
      }
    });
  };

  const setCustomFieldsValidationErrorToDefault = () => {
    setCustomFieldsValidationError({});
  };

  const convertCustomFieldsObjToTheFormatAccordingToModel = (
    customFieldsObj: CustomFields,
  ) => {
    let copiedCustomFieldsObj = { ...customFieldsObj };
    for (const key in copiedCustomFieldsObj) {
      if (Object.prototype.hasOwnProperty.call(copiedCustomFieldsObj, key)) {
        const element = copiedCustomFieldsObj[key];
        if (
          element !== null &&
          !Array.isArray(element) &&
          typeof element === 'object' &&
          Object.keys(element).length
        ) {
          copiedCustomFieldsObj[key] = element._id as string;
        }
      }
    }
    return copiedCustomFieldsObj;
  };

  const resetForm = (fromParent?: boolean) => {
    if (Array.isArray(customFields) && customFields.length > 0) {
      if (
        typeof accountToBeEdited === 'object' &&
        accountToBeEdited !== null &&
        Object.keys(accountToBeEdited).length
      ) {
        let alreadyExistingData: AlreadyExistingData = {};
        if (Object.keys(accountToBeEdited).length && !fromParent) {
          if (accountToBeEdited.name) {
            setFormIsLoaded(true);
            setName(accountToBeEdited.name);
            alreadyExistingData.name = accountToBeEdited.name;
          } else {
            alreadyExistingData.name = "";
            setName("");
          }
          if (accountToBeEdited.customFields) {
            let customFields = convertCustomFieldsObjToTheFormatAccordingToModel(accountToBeEdited.customFields as any);
            settingCustomFieldObjWithExistingCustomFieldsForAGivenContact(
              (customFields as any),
            );
          }
          if (accountToBeEdited.source) {
            setSource(accountToBeEdited.source);
          }
          if (accountToBeEdited.projects) {
            let projectIds = accountToBeEdited.projects.map(el => el._id);
            setListOfSelectedProjectId(projectIds);
            alreadyExistingData.projectIds = projectIds;
          } else {
            setListOfSelectedProjectId([]);
            alreadyExistingData.projectIds = [];
          }
          setIdBeingEdited(accountToBeEdited._id);
          setAlreadyExistingDataInTheForm(prev => ({ ...prev, ...alreadyExistingData }));
        }
      } else {
        setFormIsLoaded(true);
        setName('');
        setIdBeingEdited('');
        settingCustomFieldObjWithExistingCustomFieldsForAGivenContact({} as any);
        setListOfSelectedProjectId([]);
        // setSelectedContactAsCFValue({})
      }
    }
  };

  const checkIfFormHasChanged = () => {
    const dataEntered: any = {
      customFields: customFieldsObj,
      name,
      projectIds: listOfSelectedProjectId,
    };
    return areDifferentObjects(alreadyExistingDataInTheForm, dataEntered) || checkIfContactInfoHasChangedInTheChild();
  };

  const handlevalidation = (unlinkingFromParent?: boolean) => {
    //step 3  for saving opportunity with adding/creatig account
    if (!isFormEmpty) {
      let isChildContactFormValid = false;
      let isAccountFormValid = isValid();
      if (unlinkingFromParent) {
        isChildContactFormValid = checkValidationInChildContactComp();
      } else {
        isChildContactFormValid = checkOnlyValidationInChildContactComp();
      }
      const isFormValid: boolean = (isAccountFormValid && isChildContactFormValid) ? true : false;
      return isFormValid;
    } else {
      return true;
    }
  };

  const handleValidationCfIsMandatory = () => {
    if (!isFormEmpty) {
      let isChildContactFormValid = false;
      let isAccountFormValid = isValid();
      isChildContactFormValid = checkOnlyValidationInChildContactCompMandatory();
      const isFormValid: boolean = (isAccountFormValid && isChildContactFormValid) ? true : false;
      return isFormValid;
    } else {
      return false;
    }
  };

  //if child contact forms are closed then handleAddOrUpdate is called directly without calling handlevalidation
  const handleAddOrUpdate = (savingBeforeUnlinkingFromMainParent?: boolean) => {
    // checking if any create or edit contact box is open
    for (const [key, element] of Object.entries(dynamicContactOpenState)) {
      if (element === true) {
        haveToCallCreateOrUpdateContactFunctionInChild.current = true;
        break; // Break out of the loop
      }
    }
    // if any create or edit contact box is open then first contact will be saved
    if (!savingBeforeUnlinkingFromMainParent) {
      haveToCallCreateOrUpdateContactFunctionInChild.current ? checkValidationInChildContactComp() : saveNewAccountOrUpdate(savingBeforeUnlinkingFromMainParent);
    } else {
      saveContactInTheChildComp(savingBeforeUnlinkingFromMainParent);
    }
  };

  const checkValidationInChildContactComp = () => {
    let isValid = true;
    let validationArray: boolean[] = [];
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handlevalidation) {
        let childIsValid = childRef.handlevalidation();
        if (!childIsValid) {
          isValid = false;
          validationArray.push(true);
        } else {

          validationArray.push(false);
        }
      }
    });
    setValidationRequired([...validationArray]);
    return isValid;
  };

  const checkOnlyValidationInChildContactComp = () => {
    let isValid = true;
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handlevalidation) {
        if (!childRef.handlevalidation()) {
          isValid = false;
        }
      }
    });
    return isValid;
  };

  const checkOnlyValidationInChildContactCompMandatory = () => {
    let isValid = true;
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handlevalidation) {
        if (!childRef.handleValidationCfIsMandatory()) {
          isValid = false;
        }
      }
    });
    return isValid;
  };

  function isEmptyObject(body: BodyToCheckEmptyObject) {
    const obj = { ...body };
    delete obj.source;
    // Check if obj is not an object or is null
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    // Check if obj has any own properties
    if (Object.keys(obj).length === 0) {
      return true;
    }

    // Iterate over each property of obj
    for (let key in obj) {
      // If the property value is an object, recursively call isEmptyObject
      if (Array.isArray(obj[key]) && obj[key].length) {
        return false;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!isEmptyObject(obj[key] as BodyToCheckEmptyObject)) {
          return false;
        }
      } else {
        // If the property value is not an object and not empty, return false
        if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
          return false;
        }
      }
    }
    // If all properties are empty, return true
    return true;
  }

  const areDifferentObjects = (obj1?: any, obj2?: any, seen = new WeakMap()) => {
    // If both values are strictly equal, they are the same
    if (obj1 === obj2) {
      return false;
    }

    // If either value is null or not an object, they are different
    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return true;
    }

    // Handle circular references
    if (seen.has(obj1) && seen.has(obj2)) {
      return seen.get(obj1) !== seen.get(obj2);
    }
    seen.set(obj1, obj2);
    seen.set(obj2, obj1);

    // Compare the number of keys in both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return true;
    }

    // Compare each key and value in both objects
    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key) || areDifferentObjects(obj1[key], obj2[key], seen)) {
        return true;
      }
    }

    return false;
  };

  const isValid = () => {
    let isValid = true;
    let errorsObj: CustomFieldsValidationError = {};
    try {
      if (!name || name.trim() === '') {
        errorsObj.name = "Please enter the account's name";
      }

      if (!opportunityState.isSavingAsDraft) {
        Array.isArray(customFields) &&
          customFields.forEach((cf: CustomField) => {
            for (const key in customFieldsObj) {
              if (key === cf._id) {
                if (cf.isMandatory) {
                  if (cf.fieldData.fieldType === 'contact') {
                    if (!customFieldsObj[key]) {
                      errorsObj[key] = true;
                      childRefs.current.forEach((childRef: any) => {
                        if (childRef && childRef.cfId === key) {
                          if (
                            childRef &&
                            childRef.handleValidationCfIsMandatory
                          ) {
                            if (
                              childRef.handleValidationCfIsMandatory()
                            ) {
                              delete errorsObj[key];
                            }
                          }
                        }
                      });
                    } else {
                      childRefs.current.forEach((childRef: any) => {
                        if (childRef && childRef.cfId === key) {
                          if (
                            childRef &&
                            childRef.handleValidationCfIsMandatory
                          ) {
                            if (
                              !childRef.handleValidationCfIsMandatory()
                            ) {
                              errorsObj[key] = true;
                            }
                          }
                        }
                      });
                    }
                  } else if (cf.fieldData.fieldType === 'date') {
                    if ((customFieldsObj?.[cf._id as string] &&
                      moment(
                        customFieldsObj?.[cf._id as string] as string,
                      ).format('YYYY-MM-DD') === 'Invalid date' || !customFieldsObj?.[cf._id as string])) {
                      errorsObj[key] = true;
                    }

                  } else if (
                    Array.isArray(customFieldsObj[key]) &&
                    !customFieldsObj[key].length
                  ) {
                    errorsObj[key] = true;
                  } else if (!customFieldsObj[key]) {
                    errorsObj[key] = true;
                  }
                }
                if (cf.fieldData.fieldType === 'email') {
                  if (
                    customFieldsObj[key] &&
                    !isValidEmail(customFieldsObj[key] as string)
                  ) {
                    errorsObj[key] = true;
                  }
                }
                if (cf.fieldData.fieldType === 'url') {
                  if (
                    customFieldsObj[key] &&
                    !isValidURL(customFieldsObj[key] as string)
                  ) {
                    errorsObj[key] = true;
                  }
                }
                if (cf.fieldData.fieldType === 'facebook' || cf.fieldData.fieldType === 'linkedin' ||
                  cf.fieldData.fieldType === 'x' || cf.fieldData.fieldType === 'pinterest' ||
                  cf.fieldData.fieldType === 'instagram'
                ) {
                  if (
                    customFieldsObj[key] &&
                    !validateSocialMediaUrl(customFieldsObj[key] as string, cf.fieldData.fieldType)
                  ) {
                    errorsObj[key] = true;
                  }
                }
                if (cf.fieldData.fieldType === 'number') {
                  const numbersAndHyphensRegex = /^[0-9-]*$/;
                  if (
                    !numbersAndHyphensRegex.test(
                      customFieldsObj[key] as string,
                    )
                  ) {
                    errorsObj[key] = true;
                  }
                }
                if (cf.fieldData.fieldType === 'date') {
                  if (customFieldsObj?.[cf._id as string] &&
                    moment(
                      customFieldsObj?.[cf._id as string] as string,
                    ).format('YYYY-MM-DD') === 'Invalid date') {
                    errorsObj[key] = true;
                  }
                }
              }
            }
          });
      }
    } catch (error) {
      console.log(error);
    }

    if (customFieldsValidationError.name) {
      isValid = false;
    }
    if (Object.keys(errorsObj).length) {
      setCustomFieldsValidationError(prev => ({ ...prev, ...errorsObj }));
      isValid = false;
    } else {
      setCustomFieldsValidationError(prev => ({ ...prev, ...errorsObj }));
    }

    if (opportunityState.isSavingAsDraft) {
      isValid ? isValidForDraft.current = false : isValidForDraft.current = true;
    }

    return isValid;
  };


  const closeConfirmationPop = () => {
    setIsUnlinking(false);
    setOpenConfirmationPop(false);
  };

  const handleOpenConfirmationPop = () => {
    // if (!checkValidationInChildContactComp() || !isValid()) {
    //     return;
    // }
    let childContactHasChanged = checkIfContactInfoHasChangedInTheChild();
    const dataEntered = {
      customFields: customFieldsObj,
      name,
      projectIds: listOfSelectedProjectId,
    };
    setIsUnlinking(true);
    if (idBeingEdited) {
      if (areDifferentObjects(alreadyExistingDataInTheForm, dataEntered) || childContactHasChanged) {
        confirmationPopupMessgae = "You have made some changes, do you want to save the account before unlinking?";
        changesMade = true;
      } else {
        confirmationPopupMessgae = "Are you sure you want to unlink the account?";
        changesMade = false;
      }
    } else {
      confirmationPopupMessgae = "Are you sure you want to discard these changes?";
    }
    setOpenConfirmationPop(true);
  };

  const checkIfContactInfoHasChangedInTheChild = () => {
    let childContactHasChanged = false;
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.checkIfContactInfoHasChanged) {
        if (childRef.checkIfContactInfoHasChanged()) {
          childContactHasChanged = true;
        }
      }
    });
    return childContactHasChanged;
  };

  const handleCustomFieldsObj = (
    e: any,
    id: string,
    fieldType?: string,
    isMultiSelect?: boolean,
  ) => {
    const setingCFObj = (value: any,) => {
      let obj = {
        ...customFieldsObj,
      };
      if (fieldType === 'user') {
        let prevArr = (obj[id as string] as string[]);
        if (!prevArr) {
          prevArr = [];
        }
        if (prevArr.includes(value)) {
          prevArr = prevArr.filter(el => el !== value);
          obj[id as string] = [...prevArr];
        } else {
          obj[id as string] = [...prevArr, value];
        }
      } else {
        obj[id as string] = value;
      }
      setCustomFieldsObj(obj);
    };
    if (fieldType === 'number') {
      const regex = /^[0-9-]*$/;
      if (regex.test(e.target.value)) {
        setingCFObj(e.target.value);
      }
    } else if (fieldType === 'list' && !isMultiSelect) {
      setingCFObj([e.target.value]);
    } else if (fieldType === 'phone') {
      setingCFObj(e);
    } else if (fieldType === 'user') {
      setingCFObj(e);
    } else {
      setingCFObj(e.target.value);
    }
  };

  const handleCheckboxes = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    value: string,
  ) => {
    let obj = {
      ...customFieldsObj,
    };
    let checkboxArr = obj[id] as string[];
    if (e.target.checked) {
      obj[id] = [...checkboxArr, value];
    } else {
      let arr = Array.isArray(checkboxArr)
        ? checkboxArr.filter((v) => value !== v)
        : [];
      obj[id] = arr;
    }
    setCustomFieldsObj(obj);
  };

  const errorStatement = (cf: CustomField) => {
    if (customFieldsValidationError?.[cf._id as string]) {
      if (cf.fieldData.fieldType === 'email') {
        return 'Please enter a valid email';
      }
      if (cf.fieldData.fieldType === 'url') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'number') {
        return 'Please enter a valid number';
      }
      if (cf.fieldData.fieldType === 'date') {
        return 'Please enter a valid date';
      }
      if (cf.fieldData.fieldType === 'boolean') {
        return 'Please choose at least one option';
      }
      if (cf.fieldData.fieldType === 'list') {
        return 'Please choose an option';
      }
      if (cf.fieldData.fieldType === 'contact') {
        return 'Please fill the mandatory fields';
      } else {
        return 'Please fill this field';
      }
    } else return '';
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const saveAccountBeforeUnlinking = (savingBeforeUnlinkingFromMainParent?: boolean, unlinkingFromParent?: boolean, savingBeforeUnlinking?: boolean, addingNewAccountFromParent?: boolean) => {
    if (!checkOnlyValidationInChildContactComp() || !isValid()) {
      closeConfirmationPop();
      return;
    }
    isUnlinkingFromParent.current = unlinkingFromParent || true;
    isSavingAccount.current = savingBeforeUnlinking || false;
    addingNewAccount.current = addingNewAccountFromParent || false;
    setIsUnlinking(true);
    handleAddOrUpdate(savingBeforeUnlinkingFromMainParent);
  };

  const saveContactBeforeUnlinking = (savingContact: boolean) => {
    let isChildContactFormValid = true;
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfIdForAddNewContact) {
        if (childRef.handlevalidation) {
          if (!childRef.handlevalidation()) {
            isChildContactFormValid = false;
          }
        }
      }
    });
    if (isChildContactFormValid) {
      childRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.cfId === cfIdForAddNewContact) {
          if (childRef.saveContactBeforeUnlinking) {
            childRef.saveContactBeforeUnlinking(true, savingContact);
            addNewContact(cfIdForAddNewContact, true);
          }
        }
      });
    } else {
      closeAddNewContactConfirmationPopup();
    }
  };

  const handleOpenChangeSelectedContactConfPopup = (option: any,
    cfId: string | undefined,
    isSaving?: boolean) => {
    if (selectedContactAsCFValue?.[cfId as string]) {
      if (selectedContactAsCFValue?.[cfId as string]._id !== option._id) {
        setOpenChangeSelectedContactConfPopup(true);
        setOptionForHandleContactCFChangeForSearchableDropdown(option);
        setCfidForHandleContactCFChangeForSearchableDropdown(cfId as string);
      }
    } else {
      handleContactCFChangeForSearchableDropdown(option, cfId, isSaving);
    }
  };

  const handleSearchListInSearchableDropdownForContactTypeCF = (
    cfId: string,
    text: any,
  ) => {
    if (text) {
      setActiveCfIdForContactDropdown(cfId);
      setSearchTextForContactDropdown(text);
    } else {
    }
  };

  const handleContactCFChangeForSearchableDropdown = (
    option: any,
    cfId: string | undefined,
    isSaving?: boolean,
    contactInfoChanged?: boolean,
  ) => {
    if (option?._id) {
      setSelectedContactAsCFValue({
        ...selectedContactAsCFValue,
        [cfId as string]: option,
      });
    }

    if (cfId) {
      let contactDetailsToAppend: any = "";
      if (option) {
        if (!contactInfoChanged) {
          contactDetailsToAppend = option._id;
        } else {
          contactDetailsToAppend = { data: option, type: "contact" };
        }
      }
      let cfObj = { [cfId]: contactDetailsToAppend };
      setCustomFieldsObj((prev) => ({ ...prev, ...cfObj }));
    }

    if (isSaving) {
      savingContactAlso.current = true;
      setdynamicContactOpenState(prev => {
        delete prev[cfId as string];
        return { ...prev };
      });
    };
    openChangeSelectedContactConfPopup && setOpenChangeSelectedContactConfPopup(false);
  };

  const setPreviousContactAsCFValueOnClosingEditBox = (cfId: string) => {

    setSelectedContactAsCFValue((prev) => {
      const prevCopy = { ...prev };
      delete prevCopy[cfId];
      return { ...prevCopy };
    });

    setCustomFieldsObj((prev) => {
      const prevCopy = { ...prev };
      prevCopy[cfId] = "";
      return { ...prevCopy };
    });
    closeAddNewContactPopup(cfId);
  };

  const closeAddNewContactPopup = (cfId: string) => {
    const openState = { ...dynamicContactOpenState };
    delete openState[cfId];
    setdynamicContactOpenState(openState);
  };


  const addNewContact = (cfId: string, addingNew?: boolean) => {
    openAddNewContactConfirmationPopup && closeAddNewContactConfirmationPopup();
    if (addingNew) {
      childRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.cfId === cfId) {
          childRef.resetForm();
          childRef.setCustomFieldsValidationErrorToDefault();
        }
      });
      if (selectedContactAsCFValue[cfId]) {
        setPreviouslySelectedContactAsCFValue((prev) => ({
          ...prev,
          [cfId]: selectedContactAsCFValue[cfId],
        }));
        const obj = { ...selectedContactAsCFValue };
        delete obj[cfId];

        setSelectedContactAsCFValue(obj);
      }
    }
    const openState = { ...dynamicContactOpenState };
    openState[cfId] = true;
    for (let key in openState) {
      if (key === cfId) {
        openState[key] = true;
      } else {
        openState[key] = true;
      }
    }
    setdynamicContactOpenState({
      ...openState,
    });
  };

  const clearSelectedContactAndCloseViewContactDetailBox = (cfId: string) => {
    const obj = { ...selectedContactAsCFValue };
    delete obj[cfId];
    setSelectedContactAsCFValue(obj);
    const openState = { ...dynamicContactOpenState };
    delete openState[cfId];
    setdynamicContactOpenState({
      ...openState,
    });

    let cfObj = {
      ...customFieldsObj,
      [cfId]: '',
    };
    setCustomFieldsObj({ ...customFieldsObj, ...cfObj });
  };

  const closeAddNewContactConfirmationPopup = () => {
    setOpenAddNewContactConfirmationPopup(false);
    changesMadeInCreateOrEditContact = false;
  };

  const fetchAllContacts = async () => {
    let from = 'createAccount';
    if (toBeEdited && Object?.keys(toBeEdited).length) {
      from = "editAccount";
    }
    const data = {
      queryParams: {
        pageNo: 1,
        searchOnlyName: true,
        from: from
      },
      bodyParams: { filterArray: [], searchText: searchTextForContactDropdown },
    };
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const res = await opportunityContactsAPI.fetchAllOpportunityContacts(data);
    if (res.data.success) {
      setContactsSearchLists((prev) => ({
        ...prev,
        [activeCfIdForContactDropdown]: res.data.contacts,
      }));
    }

  };

  const fetchContactByIdToSetInSelectedContactAsCFValue2 = async (
    contactId: string,
    cfId: string,
  ) => {
    if (contactId) {
      const opportunityContactsAPI = new OpportunityContactsAPI();
      const res = await opportunityContactsAPI.fetchOpportunityContact({
        id: contactId as string,
      });
      if (res.data.success) {
        setSelectedContactAsCFValue((prev) => ({
          ...prev,
          [cfId as string]: res.data.contact,
        }));
      }

    }
  };

  const handleOpenAddNewContactConfirmationPopup = (cfId: string) => {
    let isUnlinkingExistingContact = false;
    let isContactFormEmpty = false;
    let isContactSelected = selectedContactAsCFValue?.[cfId]?._id;
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfId) {
        if (childRef) {
          changesMadeInCreateOrEditContact = childRef.checkIfFormHasChanged();
          isUnlinkingExistingContact = childRef.idBeingEdited;
          isContactFormEmpty = childRef.isFormEmpty;
        }
      }
    });
    if (isContactFormEmpty) {
      return;
    }
    if (changesMadeInCreateOrEditContact) {
      setContactConfirmationText("You have made some changes, do you want to save the contact before unlinking?");
    } else if (!changesMadeInCreateOrEditContact && isUnlinkingExistingContact) {
      setContactConfirmationText("Are you sure you want to unlink this contact?");
    } else if (!changesMadeInCreateOrEditContact && !isUnlinkingExistingContact && !isContactSelected) {
      setContactConfirmationText("Are you sure you want to unlink this contact?");
      addNewContact(cfId, true);
      return;
    } else {
      setContactConfirmationText("Are you sure you want to unlink this contact?");
    }
    setCfIdForAddNewContact(cfId);
    setOpenAddNewContactConfirmationPopup(true);
  };

  const closeChangeSelectedContactConfPopup = () => {
    setOpenChangeSelectedContactConfPopup(false);
    changesMadeInCreateOrEditContact = false;
  };

  const saveContactInTheChildComp = (savingBeforeUnlinkingFromMainParent?: boolean) => {
    if (Object.keys(dynamicContactOpenState).length) {
      childRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.handleAddOrUpdate) {
          childRef.modifyIsUnlinkingFromParent(isUnlinkingFromParent.current);
          childRef.modifyIsSavingContact(true);
          childRef.modifyIsSavingAccount(isSavingAccount.current);
          childRef.handleAddOrUpdate(savingBeforeUnlinkingFromMainParent);
        }
      });
    } else {
      saveNewAccountOrUpdate(savingBeforeUnlinkingFromMainParent);
    }
    setValidationRequired([]);
    openAddNewContactConfirmationPopup && setOpenAddNewContactConfirmationPopup(false);
  };

  const saveNewAccountOrUpdate = async (savingBeforeUnlinkingFromMainParent?: boolean) => {
    if (!isFormEmpty) {
      let isValidForm = true;
      if (isUnlinkingFromParent.current || isUnlinking) {
        isValidForm = isValid();
        openConfirmationPop && setOpenConfirmationPop(false);
      }
      if (isValidForm) {
        const bodyParams: BodyParams = {
          customFields: customFieldsObj,
          projectIds: listOfSelectedProjectId,
          name: name.trim(),
          source: source,
        };

        const isEmpty = isEmptyObject(bodyParams as BodyToCheckEmptyObject);

        if (isEmpty) {
          createNotification('error', 'At least one value is required');
          return;
        }

        if (idBeingEdited) {
          bodyParams._id = idBeingEdited;
        }
        let data = {
          bodyParams: bodyParams,
        };

        if (idBeingEdited) {
          if (savingBeforeUnlinkingFromMainParent) {
            await update(data);
            onClose();
          } else if (
            isUnlinkingFromParent.current || isUnlinking || isUnlinkingAccount.current
          ) {
            if (isSavingAccount.current) {
              await update(data);
              isUnlinkingAccount.current = false;
              isUnlinkingFromParent.current = false;
              isSavingAccount.current = false;
            }
            isUnlinking && setIsUnlinking(false);
            isUnlinkingFromParent.current = false;
            if (isUnlinking && !addingNewAccount.current) {
              onClose();
            }
          } else {
            // @ts-ignore
            handleAccountCFChangeForSearchableDropdown(
              bodyParams,
              cfId,
              true,
              // @ts-ignore
              checkIfFormHasChanged(),
            );
            isUnlinking && setIsUnlinking(false);
          }
        } else {
          if (!isUnlinkingFromParent.current) {
            // @ts-ignore
            handleAccountCFChangeForSearchableDropdown(
              bodyParams,
              cfId,
              true,
              // @ts-ignore
              checkIfFormHasChanged(),
            );
          } else {
            addNewAccount(cfId, true);
            resetForm();
          }
        }
        // idBeingEdited ? updateAccount(data) : createAccount(data);
      }
    } else {
      // @ts-ignore
      handleAccountCFChangeForSearchableDropdown(null, cfId, true, checkIfFormHasChanged());
    }
  };

  const update = async (data: any) => {
    // return 
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const response = await opportunityAccountsAPI.updateOpportunityAccountForAccountTab(data);
    if (addingNewAccount.current) {
      addNewAccount(cfId, true);
    }
    return response;
  };

  const create = async (data: any) => {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const response = await opportunityAccountsAPI.createAccountForOpportunityTab(data);
    return response;
  };

  const updateAccount = async (data: any) => {
    try {
      setIsLoading(true);
      const opportunityAccountsAPI = new OpportunityAccountsAPI();
      const response = await opportunityAccountsAPI.updateOpportunityAccountForAccountTab(data);
      if (response.status === 200) {
        if (response.data.success && response.data.updatedAccount) {
          setIsLoading(false);
          if (isUnlinkingFromParent.current || isUnlinking) {
            isUnlinking && setIsUnlinking(false);
            isUnlinkingFromParent.current = false;
            if (isUnlinking) {
              onClose();
            }
          } else {
            handleAccountCFChangeForSearchableDropdown(response.data.updatedAccount, cfId, true);
            isUnlinking && setIsUnlinking(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createAccount = async (data: any) => {
    try {
      setIsLoading(true);
      const opportunityAccountsAPI = new OpportunityAccountsAPI();
      const response = await opportunityAccountsAPI.createAccountForOpportunityTab(data);
      if (response.status === 200 && response.data.account) {
        if (response.data.success) {
          setIsLoading(false);
          if (!isUnlinkingFromParent.current) {
            handleAccountCFChangeForSearchableDropdown(response.data.account, cfId, true);
          } else {
            addNewAccount(cfId, true);
            resetForm();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectList = async (params: GetProjectsParams) => {
    try {
      const result: any = await EventsAllAPIWithPagination(params);
      const projects: ProjectsList = result.data.events;
      if (projects.length < result.data.total) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
      setProjectsList(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectCheckbox = (option: any, checked: boolean) => {
    let arr: string[] = [...listOfSelectedProjectId];
    if (checked) {
      arr.push(option._id);
    } else {
      arr = arr.filter((el) => el !== option._id);
    }
    setListOfSelectedProjectId(arr);
  };

  const handleClearAll = (checked: boolean) => {
    let arr: string[] = [...listOfSelectedProjectId];
    if (checked) {
      setListOfSelectedProjectId([]);
    }
  };

  const handleSearchListInSearchableDropdownForProjects = (text: any) => {
    setSearchTextForProjectDropdown(text);
  };

  const handlePageChange = () => {
    setPageNo((prev) => prev + 1);
  };

  const clearContactsSearchList = () => {
    setContactsSearchLists(null);
  };

  const handleClearAllUsers = (checked: boolean, cfId: string) => {
    let obj = { ...customFieldsObj };
    if (checked) {
      obj[cfId] = [];
      setCustomFieldsObj({ ...obj });
    }
  };

  return formIsLoaded ? (
    <Grid item xs={12} mt={2}>
      <Box
        className="account-details add-contacts"
        //   @ts-ignore
        sx={{ backgroundColor: '#EDEBFF' }}
      >
        <Stack
          mb={2}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box className="paragraph bold primary-text">Account Details</Box>
          <Stack direction={'row'} spacing={1}>
            <IconButton size="small" onClick={() => {
              if (!idBeingEdited) {
                isFormEmpty ? closeAddNewPopup() : handleOpenConfirmationPop();
              } else {
                handleOpenConfirmationPop();
              }
            }}>
              {<LinkOffIcon color={"secondary"} />}
            </IconButton>
          </Stack>
        </Stack>

        <Box
          component={Grid}
          //   @ts-ignore
          rowSpacing={2}
          columnSpacing={2}
          container
          className="inputField"
        >
          <Grid item xs={12} sm={6}>
            <label>
              Account Name
              <span className="required">*</span>
            </label>
            <input
              onChange={handleNameChange}
              value={name}
              type="text"
              className="form-control small"
              placeholder=""
            />
            <span className="error_mesage required">
              {customFieldsValidationError.name}
            </span>
          </Grid>

          <Grid item xs={12} sm={6}>
            <label>Projects</label>
            <CustomSearchableMultiSelectedDropdownWithLoadMore
              options={projectsList}
              handleChange={handleProjectCheckbox}
              handleClearAll={handleClearAll}
              handleAllCheckbox={() => { }}
              handleSearchListInSearchableDropdown={
                handleSearchListInSearchableDropdownForProjects
              }
              listOfSelectedIds={listOfSelectedProjectId}
              dropdownFor="projectsChatWidgetList"
              handlePageChange={handlePageChange}
              disabled={disabledButton}
            />
          </Grid>

          {Array.isArray(customFields) &&
            customFieldsObj &&
            customFields.map((cf) => {
              return (
                <Grid
                  item
                  xs={
                    ['contact', 'account', 'longText'].includes(
                      cf.fieldData.fieldType,
                    )
                      ? 12
                      : 6
                  }
                  key={cf._id}
                >
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box>
                      <label>
                        {cf.fieldData.fieldName}
                        {cf.isMandatory && (
                          <span className="required">*</span>
                        )}
                      </label>
                    </Box>
                    {(cf.fieldData.fieldType === 'contact' ||
                      cf.fieldData.fieldType === 'account') && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() =>
                            handleOpenAddNewContactConfirmationPopup(
                              cf._id as string,
                            )
                          }
                        >
                          Add New
                        </Button>
                      )}
                  </Stack>

                  {cf.fieldData.fieldType === 'list' ? (
                    <DropdownForListDataTypeCF
                      cf={cf}
                      customFieldsObj={customFieldsObj}
                      handleCustomFieldsObj={
                        handleCustomFieldsObj
                      }
                    />
                  ) : cf.fieldData.fieldType === 'boolean' ? (
                    <FormControl fullWidth component="fieldset">
                      <RadioGroup
                        // @ts-ignore
                        aria-label={cf.fieldData.fieldName}
                        name={cf.fieldData.fieldName}
                        row
                        onChange={(e) =>
                          handleCustomFieldsObj(
                            e,
                            cf._id as string,
                          )
                        }
                        value={
                          customFieldsObj?.[
                            cf._id as string
                          ]?.toString() || ''
                        }
                      >
                        <FormControlLabel
                          value={'true'}
                          control={<Radio color="primary" />}
                          label="True"
                        />
                        <FormControlLabel
                          value={'false'}
                          control={<Radio color="primary" />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : cf.fieldData.fieldType === 'date' ? (
                    <>
                      {/* <input
                                      type="date"
                                      className="form-control small"
                                      value={
                                        customFieldsObj?.[cf._id as string] &&
                                        moment(
                                          customFieldsObj?.[
                                            cf._id as string
                                          ] as string,
                                        ).format('YYYY-MM-DD')
                                      }
                                      onChange={(e) =>
                                        handleCustomFieldsObj(
                                          e,
                                          cf._id as string,
                                        )
                                      }
                                    /> */}
                      <div className="date-picker-with-icon form-control">
                        <ReactDatePicker
                          selected={customFieldsObj?.[cf._id] ? new Date(customFieldsObj[cf._id]) : null}
                          onChange={(date) =>
                            handleCustomFieldsObj(
                              { target: { value: date ? moment(date).format('YYYY-MM-DD') : null } },
                              cf._id
                            )
                          }
                          id={`date-picker-${[cf._id]}`}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          className="form-control small"
                        />
                        <CalendarTodayIcon fontSize='small' style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => document.querySelector(`#date-picker-${[cf._id]}`).focus()} />
                      </div>
                      {customFieldsObj?.[cf._id as string] &&
                        moment(
                          customFieldsObj?.[
                          cf._id as string
                          ] as string,
                        ).format('YYYY-MM-DD') ===
                        'Invalid date' && (
                          <>
                            {customFieldsObj?.[cf._id as string]}
                          </>
                        )}
                    </>
                  ) : cf.fieldData.fieldType === 'contact' ? (
                    <>
                      <CustomSearchableDropdownForContactAndAccountDataTypeCF
                        options={
                          contactsSearchLists &&
                          contactsSearchLists[cf._id as string]
                        }
                        value={selectedContactAsCFValue}
                        handleChange={(option: any) =>
                          handleOpenChangeSelectedContactConfPopup(
                            option,
                            cf._id,
                          )
                        }
                        cfId={cf._id}
                        handleSearchListInSearchableDropdown={(
                          text: string,
                        ) =>
                          handleSearchListInSearchableDropdownForContactTypeCF(
                            cf._id as string,
                            text,
                          )
                        }
                        clearSearchList={clearContactsSearchList}
                      />
                      {dynamicContactOpenState[
                        cf._id as string
                      ] && (
                          <CreateOrEditContact
                            ref={(ref) => {
                              let arr = [];
                              for (let key in dynamicContactOpenState) {
                                if (
                                  dynamicContactOpenState[key] ===
                                  true
                                ) {
                                  arr.push(key);
                                }
                              }
                              arr.map((el, i) => {
                                if (el === cf._id) {
                                  return (childRefs.current[i] =
                                    ref);
                                }
                              });
                            }}
                            closeAddNewPopup={() =>
                              closeAddNewContactPopup(
                                cf?._id as string,
                              )
                            }
                            cfId={cf._id as string}
                            handleContactCFChangeForSearchableDropdown={
                              handleContactCFChangeForSearchableDropdown
                            }
                            contactToBeEdited={
                              (selectedContactAsCFValue !== null &&
                                Object.keys(
                                  selectedContactAsCFValue,
                                ).length &&
                                selectedContactAsCFValue[
                                cf._id as string
                                ]) ||
                              undefined
                            }
                            onClose={() =>
                              setPreviousContactAsCFValueOnClosingEditBox(
                                cf._id as string,
                              )
                            }
                            addNewContact={addNewContact}
                            toBeEdited={toBeEdited}
                            callFrom={'account'}
                          />
                        )}
                      {selectedContactAsCFValue?.[
                        cf._id as string
                      ] &&
                        !dynamicContactOpenState[
                        cf._id as string
                        ] && (
                          <ViewContactOrAccountDetails
                            selected={
                              selectedContactAsCFValue[
                              cf?._id as string
                              ]
                            }
                            addNew={() =>
                              addNewContact(cf._id as string)
                            }
                            clearSelectedContactAndCloseViewContactDetailBox={() =>
                              clearSelectedContactAndCloseViewContactDetailBox(
                                cf._id as string,
                              )
                            }
                            usedFor="contact"
                            customFields={
                              customFieldsFromContacts
                            }
                            parentItem="account"
                          />
                        )}
                    </>
                  ) : cf.fieldData.fieldType === 'number' ? (
                    <input
                      type="text"
                      className="form-control small"
                      placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                      onChange={(e) =>
                        handleCustomFieldsObj(
                          e,
                          cf._id as string,
                          cf.fieldData.fieldType,
                        )
                      }
                      value={
                        customFieldsObj?.[
                        cf._id as string
                        ] as string
                      }
                    />
                  ) : cf.fieldData.fieldType === 'phone' ? (
                    <InputFieldForPhoneDataTypeCF
                      handleCustomFieldsObj={
                        handleCustomFieldsObj
                      }
                      cf={cf}
                      customFieldsObj={customFieldsObj}
                    />
                  ) : cf.fieldData.fieldType === 'longText' ? (
                    <textarea
                      className="form-control"
                      placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                      onChange={(e) =>
                        handleCustomFieldsObj(e, cf._id as string)
                      }
                      value={
                        customFieldsObj?.[
                        cf._id as string
                        ] as string
                      }
                      rows={4}
                    ></textarea>
                  ) : cf.fieldData.fieldType === 'user' ? (
                    <CustomDropdownWithActiveUsers
                      handleChange={(option: {
                        _id: string;
                        email: string;
                      }) =>
                        handleCustomFieldsObj(
                          option,
                          cf._id as string,
                          cf.fieldData.fieldType,
                        )
                      }
                      handleClearAll={(checked: boolean) =>
                        handleClearAllUsers(checked, cf._id as string)
                      }
                      handleAllCheckbox={() => { }}
                      listOfSelectedIds={
                        customFieldsObj?.[cf._id as string] || []
                      }
                      dropdownFor="users"
                      handlePageChange={() => { }}
                      disabled={false}
                    />
                  ) : (
                    <input
                      type={
                        cf.fieldData.fieldType === 'number'
                          ? 'number'
                          : cf.fieldData.fieldType === 'email'
                            ? 'email'
                            : 'text'
                      }
                      className="form-control small"
                      placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                      onChange={(e) =>
                        handleCustomFieldsObj(e, cf._id as string)
                      }
                      value={customFieldsObj?.[cf._id as string]}
                    />
                  )}
                  <span className="error_mesage required">
                    {errorStatement(cf as CustomField)}
                  </span>
                </Grid>
              );
            })}
        </Box>
      </Box>
      {/* {isLoading && <Loader />} */}
      <ConfirmationAlertPopUp
        openModal={openConfirmationPop}
        closeModalFunc={() => {
          if (idBeingEdited) {
            if (changesMade) {
              setIsUnlinking(false);
              onClose();
            } else {
              closeConfirmationPop();
            }
          } else {
            if (changesMade) {
              setIsUnlinking(false);
              closeAddNewPopup();
              closeConfirmationPop();
            } else {
              setIsUnlinking(false);
              closeConfirmationPop();
            }
          }
        }}
        handleCrossIconClick={() => {
          closeConfirmationPop();
        }}
        title={idBeingEdited ? 'Unlink Account!' : 'Discard Changes'}
        text={confirmationPopupMessgae}
        confirmationButtonText={idBeingEdited ? (changesMade ? 'Save Account And Unlink' : 'Unlink') : 'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={idBeingEdited ? (changesMade ? 'Unlink Without Saving' : 'Cancel') : 'Cancel'}
        functionality={() => {
          if (idBeingEdited) {
            if (changesMade) {
              isUnlinkingAccount.current = true;
              saveAccountBeforeUnlinking(false, true, true);
            } else {
              setIsUnlinking(false);
              onClose();
            }
          } else {
            closeAddNewPopup();
          }
          confirmationPopupMessgae = "";
        }}
      />
      <ConfirmationAlertPopUp
        openModal={openAddNewContactConfirmationPopup}
        closeModalFunc={() => {
          if (changesMadeInCreateOrEditContact) {
            addNewContact(cfIdForAddNewContact, true);
          } else {
            closeAddNewContactConfirmationPopup();
          }
        }}
        title={'Unlink!'}
        text={contactConfirmationText}
        confirmationButtonText={changesMadeInCreateOrEditContact ? 'Save Contact And Unlink' : 'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={changesMadeInCreateOrEditContact ? 'Unlink Without Saving' : 'Cancel'}
        functionality={() => {
          if (changesMadeInCreateOrEditContact) {
            saveContactBeforeUnlinking(true);
          } else {
            addNewContact(cfIdForAddNewContact, true);
          }
        }}
      />
      <ConfirmationAlertPopUp
        openModal={openChangeSelectedContactConfPopup}
        closeModalFunc={closeChangeSelectedContactConfPopup}
        title={'Change Selected Contact'}
        text={'Are you sure you want to change the linked contact?'}
        confirmationButtonText={'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => { handleContactCFChangeForSearchableDropdown(optionForHandleContactCFChangeForSearchableDropdown, cfidForHandleContactCFChangeForSearchableDropdown); }}
      />
    </Grid>
  ) : (<Stack
    spacing={1}
    alignItems={'center'}
    py={8}
    className="account-details"
    mb={3}
  >
    <CircularProgress />
  </Stack>);
});

export default CreateOrEditAccount;
