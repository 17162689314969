import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { Actions } from '../Actions';
import { Url } from '../../constants/constants';
import {
  addPointsGamificationLeaderBoardsAPI,
  listGamificationLeaderBoardsAPI,
  UpdateGamificationGradesAPI,
  ListGamificationGradesAPI,
  SendTestEmailAPI,
  ListAttendeesNotAddedToCampaignAPI,
  ListCampaignAudienceGroupsAPI,
  ListAttendeesOfCampaignsAPI,
  DeleteCampaignAPI,
  GetCampaignAPI,
  UpdateCampaignAPI,
  CreateCampaignAPI,
  CheckCampaignAPI,
  ListCampaignTemplatesAPI,
  ListCampaignsAPI,
  ImportAttendeesAPI,
  ListTransactionalEmailTemplatesAPI,
  ListBadgesTemplateAPI,
  EditAttendeesGroupNameAPI,
  SearchAttendeesGroupsAPI,
  SearchAttendeesOfUnassignGroupAPI,
  SearchAttendeesOfGroupAPI,
  AssignAttendeesFromGroupAPI,
  UnassignAttendeesFromGroupAPI,
  ListAssignedAttendeesAPI,
  ListUnassignedAttendeesAPI,
  SortAttendeesAPI,
  DeleteAttendeesGroupsAPI,
  AddAttendeesGroupsAPI,
  ListAttendeesGroupsAPI,
  EditAttendeeAPI,
  checkedInHistoryAttendeeAPI,
  EnableAttendeeStatusAPI,
  ActionAssignGroupsAPI,
  CheckinActionStatusAPI,
  DeleteAttendeeAPI,
  AddAttendeesAPI,
  ListAttendeesAPI,
  UpdateCustomFieldsAPI,
  DeleteCustomFieldsAPI,
  AddCustomFieldsAPI,
  GetCustomFieldsAPI,
  UpdateProjectNotificationAPI,
  EditProjectNotificationAPI,
  SearchProjectNotificationAPI,
  DeleteProjectNotificationAPI,
  CreateProjectNotificationAPI,
  GetProjectNotificationsAPI,
  LoginAPI,
  EmailVerifyAPI,
  DeleteAccountAPI,
  ForgotPasswordAPI,
  ResetPasswordAPI,
  EmailVerifiedAPI,
  UserRegisterAPI,
  wixAutoLoginAPI,
  UpdateUserAPI,
  ServicesStatusApiAutoLogin,
  AutoLoginAdminAPI,
  CreateEventAPI,
  GetEventsAPI,
  CreateContactAPI,
  ListContactAPI,
  CreateGroupContactAPI,
  ImportContactAPI,
  UpdateContactAPI,
  DeleteContactAPI,
  DeleteManyContactAPI,
  AssignGroupAPI,
  SearchContactAPI,
  CsvContactAPI,
  XslContactAPI,
  ListGroupAPI,
  UpdateGroupAPI,
  DeleteGroupAPI,
  SearchMemberAPI,
  AddMemberAPI,
  UpdateMemberAPI,
  DeleteMemberAPI,
  ListMemberAPI,
  GetUsersListAPI,
  UpdateMemberStatusAPI,
  EventEmailAPI,
  UpdateEventAPI,
  UpdateProjectAPI,
  getEventById,
  getProjectById,
  ExportEventsAPI,
  SearchEventsAPI,
  EmailsInfoAPI,
  EmailsDataInfoAPI,
  ListNotiAPI,
  UpdateNotiAPI,
  CheckEventDomainAPI,
  EmailsRegistrationDataInfoAPI,
  ListAllContactAPI,
  SearchGroupAPI,
  SearchUserAPI,
  DeleteUserAPI,
  InviteUserAPI,
  ChangeUserRoleAPI,
  ChangeUserStatusAPI,
  getRolesAPI,
  getPermissionsAPI,
  createRoleAPI,
  editRoleAPI,
  deleteRoleAPI,
  ListProjectUsersAPI,
  addUserRoleProjectAPI,
  SearchAttendeesAPI,
  SortFilterAttendeesAPI,
  ListAgendaAPI,
  DownloadCsvAPI,
  DeleteAgendaAPI,
  exportAttendeesAPI,
  AddAgendaAPI,
  EditAgendaAPI,
  checkJWTAuthAPI,
  SearchAgendaAPI,
  exportCheckInHistoryAPI,
  GetReportAPI,
  CheckGroupAPI,
  GetPublishedEventsAPI,
  getGamificationChallengesAPI,
  listConfigrationChallengesAPI,
  ListAllCampaignsAPI,
  ListAllTransactionalEmailTemplatesAPI,
  AddChallengesAPI,
  UpdateChallengesAPI,
  DeleteChallengesAPI,
  ChallengeStatusAPI,
  getAttendeesChartDataAPI,
  getEventSummaryDetailsAPI,
  getleaderboardAPI,
  getChallengesEngagedUsersAPI,
  deleteProjectAPI,
  listWebhooksAPI,
  toggleWebhookAPI,
  generateSecretKeyForWebhookAPI,
  checkWebhookEventIdAPI,
  getMyRankAPI,
  getReportChallengesAPI,
  createGroupAPI,
  replicateAgendaAPI,
  replicateNotificationAPI,
  replicateProjectAPI,
  replicatePageAPI,
  listAccessCodeAPI,
  exportAccesCodeAPI,
  addAccessCodeAPI,
  editAccessCodeAPI,
  deleteAccessCodeAPI,
  changeAccessCodeAPI,
  editUserPermissionAPI,
  ImportAccessCodeAPI,
  FindIfAllowedFromSuperadminAPI,
  FindIfAllowedFromSuperadminOuterAPI,
  listMeetingAPI,
  GetBillingDataAPI,
  GetProjectsBillingDataAPI,
  addMeetingAPI,
  DeleteMeetingAPI,
  UpdateMeetingAPI,
  listAttendeesOfNotAddedToMeetingAPI,
  attendeeGroupsForMeetingAPI,
  audienceForMeetingAPI,
  searchForMeetingAPI,
  getAudienceContactAPI,
  getMeetingTemplateAPI,
  replicateMeetingAPI,
  ReplicateCampaignAPI,
  assignAccessCodeAPI,
  getUnusedAccessCodeAPI,
  getAccessCodeAPI,
  assignAccessCodeProfileAPI,
  GetCustomFieldsProfileAPI,
  GetUserDetailsAPI,
  ListRejectsContactAPI,
  GetGroupsForGoogleWorkspaceAPI,
  UpdateGoogleManagementListAPI,
  getExcludeAttendeeCountAPI,
  getExcludeCountAPI,
  checkGoogleSignInValidTokenAPI,
  changeLanguageForEventAPI,
  changeLanguageForUserAPI,
  checkMeetingTitleAPI,
  ListAllMeetingsAPI,
  checkDisabledAttendeeAPI,
  addAudienceCustomFieldsAPI,
  listAudienceCustomFieldsAPI,
  deleteAudienceCustomFieldsAPI,
  updateAudienceCustomFieldsAPI,
  assignManagerAPI,
  allUserListAPI,
  listAllUsersAPI,
  getUsersByAcceptStAPI,
  getActiveUsersForCompanyAPI,
  updateSlotsAPI,
  getSlotsAPI,
  createFolderForFilesAPI,
  addImagesAPI,
  getFoldersListAPI,
  getFileToDownloadAPI,
  renameFolderAPI,
  deleteFolderByIdAPI,
  addDocumentAPI,
  uploadVideoAPI,
  getApiKeyAPI,
  generateApiKeyAPI
} from '../API';
import tawkToSaga from './integrations/tawkTo';
import integrationSaga from './integrations/integration';
import vanityDomainSaga from './vanityDomain';
import { languageCode, removeStorage } from 'helpers/common';
import IntegrationAPI from 'Redux/API/integrationAPI';
import { createNotification } from 'helpers';
import GoogleSignInAPI from 'Redux/API/googleSignInAPI';
import aiChatbotSaga from './aiChatbot/aiChatbot';
import applicationsSaga from './integrations/applications/applications';
import opportunityAccountsSaga from './opportunity/opportunityAccounts';
import opportunityCustomFieldsSaga from './opportunity/opportunityCustomFields';
import opportunityStatesSaga from './opportunity/opportunityStates';
import opportunityOpportunitiesSaga from './opportunity/opportunityOpportunities';
import opportunityContactsSaga from './opportunity/opportunityContacts';
import opportunityAudienceSaga from './opportunity/opportunityAudience';
import opportunityCommonSaga from './opportunity/opportunityCommon';



function* login(actions) {
  const response = yield call(LoginAPI, actions.data);
  yield delay(2000);
  if (response.status === 200) {
    if (response.data.user) {
      localStorage.setItem('user_details', JSON.stringify(response.data.user));
      localStorage.setItem('user_role', response.data.user.role);
      yield put(
        Actions.loginSuccess(response.data.user.token, response.data.user),
      );
    } else if (response.data.status === 402 || response.data.status === 405) {
      localStorage.setItem('user_id', response.data.user_id);
      yield put(Actions.subscriptionError(response.data.message));
    } else {
      yield put(Actions.loginFailure(response.data.message));
    }
  } else {
    yield put(Actions.loginFailure(response.data.message));
  }
}

function* autoLogin(actions) {
  const response = yield call(wixAutoLoginAPI, actions.data);
  yield delay(1000);
  if (response.status === 200) {
    localStorage.setItem('user_details', JSON.stringify(response.data.user));
    localStorage.setItem('user_role', response.data.user.role);

    localStorage.setItem('token', response.data.user.token);
    localStorage.setItem('userData', JSON.stringify(response.data.user.token));
    yield delay(500);
    const statusResult = yield call(
      ServicesStatusApiAutoLogin,
      response.data.user,
    );
    if (statusResult && !statusResult.active) {
      setTimeout(function () {
        localStorage.clear();
        window.location.href = Url.wixUrl;
      }, 1500);
    } else if (statusResult && statusResult.isSubscribed) {
      // window.location.href = '/my-events'
      yield put(
        Actions.autoLoginSuccess(response.data.user.token, response.data.user),
      );
    } else if (
      statusResult &&
      response.data.user.role === 1 &&
      !statusResult.isSubscribed
    ) {
      setTimeout(function () {
        window.location.href = Url.wixUrl + '/plans-pricing';
      }, 1500);
    } else {
      setTimeout(function () {
        window.location.href = '/my-events';
      }, 1500);
    }
  }
}

function* adminAutoLogin(actions) {
  const response = yield call(AutoLoginAdminAPI, actions.data);
  yield delay(2000);
  if (response.status === 200) {
    localStorage.setItem('user_details', JSON.stringify(response.data.user));
    localStorage.setItem('user_role', response.data.user.role);

    localStorage.setItem('token', response.data.user.token);
    localStorage.setItem('userData', JSON.stringify(response.data.user.token));
    yield delay(1000);
    const statusResult = yield call(
      ServicesStatusApiAutoLogin,
      response.data.user,
    );
    yield delay(1000);

    if (statusResult && !statusResult.active) {
      setTimeout(function () {
        localStorage.clear();
        window.location.href = process.env.WIX_URL;
      }, 1500);
    } else if (statusResult && statusResult.isSubscribed) {
      //window.location.href = "/profile";
      window.location.href = '/my-events';
      yield put(
        Actions.autoLoginSuccess(response.data.user.token, response.data.user),
      );
    } else {
      setTimeout(function () {
        window.location.href = process.env.WIX_URL + '/plans-pricing';
      }, 1500);
    }
  }
}

function* updateProfile(actions) {
  const response = yield call(UpdateUserAPI, actions.data);
  yield delay(2000);
  if (response.status === 200) {
    if (response.data.status === 200) {
      localStorage.setItem('user_details', JSON.stringify(response.data.user));
      localStorage.setItem('user_role', response.data.user.role);
      yield put(
        Actions.profileSuccess(
          response.data.user.token,
          response.data.user,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.profileError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.profileError(response.data.message));
    }
  } else {
    yield put(Actions.profileError(response.data.message));
  }
}

function* emailVerifyEmail(actions) {
  const response = yield call(EmailVerifyAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.emailVerifySuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.emailVerifyError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.emailVerifyError(response.data.message));
    }
  } else {
    yield put(Actions.emailVerifyError(response.data.message));
  }
}

function* emailVerifiedEmail(actions) {
  const response = yield call(EmailVerifiedAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.emailVerifiedSuccess(response.data.message));
    } else {
      yield put(Actions.emailVerifiedError(response.data.message));
    }
  } else {
    yield put(Actions.emailVerifiedError(response.response.data.message));
  }
}

function* forgotPassword(actions) {
  const response = yield call(ForgotPasswordAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.forgotPasswordSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.forgotPasswordError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.forgotPasswordError(response.data.message));
    }
  } else {
    yield put(Actions.forgotPasswordError(response.data.message));
  }
}

function* resetPassword(actions) {
  const response = yield call(ResetPasswordAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.resetPasswordSuccess(response.data.message));
    } else {
      yield put(Actions.resetPasswordError(response.data.message));
    }
  } else {
    yield put(Actions.resetPasswordError(response.data.message));
  }
}

function* deleteAccount(actions) {
  const response = yield call(DeleteAccountAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteAccountSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.deleteAccountError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteAccountError(response.data.message));
    }
  } else {
    yield put(Actions.deleteAccountError(response.data.message));
  }
}

function* userRegister(actions) {
  const response = yield call(UserRegisterAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.userRegisterSuccess(response.data.message));
    } else if (response.data.status === parseInt(400)) {
      yield put(Actions.userRegisterFailure(response.data.message));
    }
  } else {
    yield put(Actions.userRegisterFailure(response.data.message));
  }
}

function* createEvent(actions) {
  const response = yield call(CreateEventAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.eventsSuccess(response.data.event, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.eventsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.eventsError(response.data.message));
    }
  } else {
    yield put(Actions.eventsError(response.data.message));
  }
}
function* listEvent(actions) {
  const response = yield call(GetEventsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.eventsListSuccess(
          response.data.totalResults,
          response.data.events,
          response.data.message,
          response.data.allEvents,
          response.data.filterByEvent,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listEventsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listEventsError(response.data.message));
    }
  } else {
    yield put(Actions.listEventsError(response.data.message));
  }
}

function* createContact(actions) {
  const response = yield call(CreateContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.contactSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.contactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.contactError(response.data.message));
    }
  } else {
    yield put(Actions.contactError(response.data.message));
  }
}

function* listContact(actions) {
  const response = yield call(ListContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listContactSuccess(
          response.data.contacts,
          response.data.totalResults,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listContactError(response.data.message));
    }
  } else {
    yield put(Actions.listContactError(response.data.message));
  }
}

function* listAllContact(actions) {
  const response = yield call(ListAllContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAllContactSuccess(
          response.data.allContactsList,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listAllContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listAllContactError(response.data.message));
    }
  } else {
    yield put(Actions.listAllContactError(response.data.message));
  }
}

function* createGroupContact(actions) {
  const response = yield call(CreateGroupContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.groupContactSuccess(
          response.data.importContactProgressId,
          response.data.message,
          response.data?.copyCount,
          response.data?.importlength,
          response.data?.copyCheck,
          response.data?.importCheck,
        ),
      );
    } else if (response.data.status === 201) {
      yield put(
        Actions.importGroupContactValidation(
          response.data.message,
          response.data.duplicateGroupContactsInCsv,
          response.data.invalidGroupEmailsRejection,
          response.data.rejectedGroupEmails,
          response.data.mandetoryMissingInCsvGroup
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.groupContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.groupContactError(response.data.message));
    }
  } else {
    yield put(Actions.groupContactError(response.data.message));
  }
}

function* importContact(actions) {
  const response = yield call(ImportContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.importContactSuccess(
          response.data.importContactProgressId,
          response.data.message,
          response.data?.importedCount,
        ),
      );
    } else if (response.data.status === 201) {
      yield put(
        Actions.importContactValidation(
          response.data.message,
          response.data.duplicateContactsInCsv,
          response.data.invalidEmailsRejection,
          response.data.alreadyExistInDb,
          response.data.rejectedEmails,
          response.data.mandetoryMissingInCsv
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.importContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.importContactError(response.data.message));
    }
  } else {
    yield put(Actions.importContactError(response.data.message));
  }
}

function* updateContact(actions) {
  const response = yield call(UpdateContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateContactSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.updateContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateContactError(response.data.message));
    }
  } else {
    yield put(Actions.updateContactError(response.data.message));
  }
}

function* deleteContact(actions) {
  const response = yield call(DeleteContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteContactSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.deleteContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteContactError(response.data.message));
    }
  } else {
    yield put(Actions.deleteContactError(response.data.message));
  }
}
function* deleteManyContact(actions) {
  const response = yield call(DeleteManyContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteContactSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.deleteContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteContactError(response.data.message));
    }
  } else {
    yield put(Actions.deleteContactError(response.data.message));
  }
}

function* assignGroup(actions) {
  const response = yield call(AssignGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateContactSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.updateContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateContactError(response.data.message));
    }
  } else {
    yield put(Actions.updateContactError(response.data.message));
  }
}

function* searchContact(actions) {
  const response = yield call(SearchContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchContactSuccess(
          response.data.contacts,
          response.data.totalResults,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.searchContactError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.searchContactError(response.data.message));
    }
  } else {
    yield put(Actions.searchContactError(response.data.message));
  }
}

function* csvContact(actions) {
  const response = yield call(CsvContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.csvSuccess(response.data.fileCSV, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.csvError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.csvError(response.data.message));
    }
  } else {
    yield put(Actions.csvError(response.data.message));
  }
}

function* xslContact(actions) {
  const response = yield call(XslContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.xslSuccess(response.data.fileXSL, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.xslError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.xslError(response.data.message));
    }
  } else {
    yield put(Actions.xslError(response.data.message));
  }
}

function* listGroup(actions) {
  const response = yield call(ListGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.groupSuccess(
          response.data.groups,
          response.data.message,
          response.data.totalResults,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.groupError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.groupError(response.data.message));
    }
  } else {
    yield put(Actions.groupError(response.data.message));
  }
}

function* updateGroup(actions) {
  const response = yield call(UpdateGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.updateGroupSuccess(response.data.groups, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.updateGroupError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateGroupError(response.data.message));
    }
  } else {
    yield put(Actions.updateGroupError(response.data.message));
  }
}

function* deleteGroup(actions) {
  const response = yield call(DeleteGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.deleteGroupSuccess(response.data.groups, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteGroupError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteGroupError(response.data.message));
    }
  } else {
    yield put(Actions.deleteGroupError(response.data.message));
  }
}

function* searchGroup(actions) {
  const response = yield call(SearchGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.groupSuccess(response.data.groups, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.groupError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.groupError(response.data.message));
    }
  } else {
    yield put(Actions.groupError(response.data.message));
  }
}

function* addMember(actions) {
  const response = yield call(AddMemberAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.memberSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.memberError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.memberError(response.data.message));
    }
  } else {
    yield put(Actions.memberError(response.data.message));
  }
}

function* listMember(actions) {
  const response = yield call(ListMemberAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listMemberSuccess(
          response.data.members,
          response.data.totalResults,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listMemberError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listMemberError(response.data.message));
    }
  } else {
    yield put(Actions.listMemberError(response.data.message));
  }
}

function* listProjectUsers(actions) {
  const response = yield call(ListProjectUsersAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listMemberSuccess(
          response.data.members,
          response.data.totalResults,
          response.data.message,
          response.data.filterCnt,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listMemberError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listMemberError(response.data.message));
    }
  } else {
    yield put(Actions.listMemberError(response.data.message));
  }
}

function* addProjectUsers(actions) {
  yield call(addUserRoleProjectAPI, actions.data);
}

function* getUsersList(actions) {
  try {
    const response = yield call(GetUsersListAPI, actions.data);
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(
          Actions.listUsersSuccess(
            response.data.users,
            response.data.totalResults,
            response.data.message,
            response.data.filterCnt,
            response.data.filterByManager,
          ),
        );
      } else if (response.data.status === 403) {
        throw new Error('Something went wrong.');
      } else {
        throw new Error('Something went wrong.');
      }
    } else {
      throw new Error('Something went wrong.');
    }
  } catch (error) {
    return error;
  }
}

function* updateMember(actions) {
  const response = yield call(UpdateMemberAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateMemberSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.updateMemberError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateMemberError(response.data.message));
    }
  } else {
    yield put(Actions.updateMemberError(response.data.message));
  }
}
function* updateProjectNotification(actions) {
  const response = yield call(UpdateProjectNotificationAPI, actions);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateProjectNotificationSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.updateProjectNotificationError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateProjectNotificationError(response.data.message));
    }
  } else {
    yield put(Actions.updateProjectNotificationError(response.data.message));
  }
}
function* editProjectNotification(actions) {
  const response = yield call(EditProjectNotificationAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.editProjectNotificationSuccess(
          response.data.message,
          response.data.notification,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.editProjectNotificationError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.editProjectNotificationError(response.data.message));
    }
  } else {
    yield put(Actions.editProjectNotificationError(response.data.message));
  }
}

function* searchMember(actions) {
  const response = yield call(SearchMemberAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchMemberSuccess(
          response.data.members,
          response.data.totalResults,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.searchMemberError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.searchMemberError(response.data.message));
    }
  } else {
    yield put(Actions.searchMemberError(response.data.message));
  }
}
function* searchProjectNotification(actions) {
  const response = yield call(SearchProjectNotificationAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchProjectNotificationSuccess(
          response.data.notifications,
          response.data.totalResults,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.searchProjectNotificationError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.searchProjectNotificationError(response.data.message));
    }
  } else {
    yield put(Actions.searchProjectNotificationError(response.data.message));
  }
}

function* invitationMember(actions) {
  const response = yield call(UpdateMemberStatusAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.invitationSuccess(response.data.message));
    } else {
      yield put(Actions.invitationError(response.data.message));
    }
  } else {
    yield put(Actions.invitationError(response.response.data.message));
  }
}

function* eventEmail(actions) {
  const response = yield call(EventEmailAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.eventEmailSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.eventEmailError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.eventEmailError(response.data.message));
    }
  } else {
    yield put(Actions.eventEmailError(response.data.message));
  }
}

function* updateEvent(actions) {
  const response = yield call(UpdateEventAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateEventsSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.updateEventsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateEventsError(response.data.message));
    }
  } else {
    yield put(Actions.updateEventsError(response.data.message));
  }
}
function* updateProjectRequest(actions) {
  const response = yield call(UpdateProjectAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateEventsSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.updateEventsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateEventsError(response.data.message));
    }
  } else {
    yield put(Actions.updateEventsError(response.data.message));
  }
}

function* listSingleEvent(actions) {
  const response = yield call(getEventById, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listSingleEventSuccess(
          response.data.event,
          response.data.templates,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listSingleEventError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listSingleEventError(response.data.message));
    }
  } else {
    yield put(Actions.listSingleEventError(response.data.message));
  }
}

function* getProjectRequest(actions) {
  const response = yield call(getProjectById, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getProjectSuccess(response.data.project, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.getProjectError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.getProjectError(response.data.message));
    }
  } else {
    yield put(Actions.getProjectError(response.data.message));
  }
}

function* searchEvent(actions) {
  const response = yield call(SearchEventsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchEventsSuccess(
          response.data.totalResults,
          response.data.events,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.searchEventsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.searchEventsError(response.data.message));
    }
  } else {
    yield put(Actions.searchEventsError(response.data.message));
  }
}

function* csvEvent(actions) {
  const response = yield call(ExportEventsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.csvEventsSuccess(response.data.fileCSV, response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.csvEventsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.csvEventsError(response.data.message));
    }
  } else {
    yield put(Actions.csvEventsError(response.data.message));
  }
}

function* emailReports(actions) {
  const response = yield call(EmailsInfoAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.emailReportsSuccess(
          response.data.reports,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.emailReportsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.emailReportsError(response.data.message));
    }
  } else {
    yield put(Actions.emailReportsError(response.data.message));
  }
}

function* emailRegistrationReports(actions) {
  const response = yield call(EmailsRegistrationDataInfoAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.emailRegistrationDataReportsSuccess(
          response.data.data,
          response.data.monthsData,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(
        Actions.emailRegistrationDataReportsError(response.data.message),
      );
      removeStorage();
    } else {
      yield put(
        Actions.emailRegistrationDataReportsError(response.data.message),
      );
    }
  } else {
    yield put(Actions.emailRegistrationDataReportsError(response.data.message));
  }
}

function* emailDataReports(actions) {
  const response = yield call(EmailsDataInfoAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.emailDataReportsSuccess(
          response.data.data,
          response.data.monthsData,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.emailDataReportsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.emailDataReportsError(response.data.message));
    }
  } else {
    yield put(Actions.emailDataReportsError(response.data.message));
  }
}

function* listNoti(actions) {
  const response = yield call(ListNotiAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listNotiSuccess(
          response.data.notifications,
          response.data.notiCount,
          response.data.unreadCount,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listNotiError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listNotiError(response.data.message));
    }
  } else {
    yield put(Actions.listNotiError(response.data.message));
  }
}

function* updateNoti(actions) {
  const response = yield call(UpdateNotiAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateNotiSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.updateNotiError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.updateNotiError(response.data.message));
    }
  } else {
    yield put(Actions.updateNotiError(response.data.message));
  }
}

function* checkEventDomain(actions) {
  const response = yield call(CheckEventDomainAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.checkEventDomainSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.checkEventDomainError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.checkEventDomainError(response.data.message));
    }
  } else {
    yield put(Actions.checkEventDomainError(response.data.message));
  }
}

function* proute(actions) {
  yield put(Actions.prouteSuccess(actions.data));
}

function* searchUser(actions) {
  const response = yield call(SearchUserAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchUserSuccess(
          response.data.users,
          response.data.totalResults,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.searchUserError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.searchUserError(response.data.message));
    }
  } else {
    yield put(Actions.searchUserError(response.data.message));
  }
}

function* deleteUser(actions) {
  const response = yield call(DeleteUserAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.deleteUserSuccess(response.data.message, response.data.user),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteUserError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteUserError(response.data.message));
    }
  } else {
    yield put(Actions.deleteUserError(response.data.message));
  }
}

function* inviteUser(actions) {
  const response = yield call(InviteUserAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.inviteUserSuccess(response.data.message, response.data.user),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.inviteUserError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.inviteUserError(response.data.message));
    }
  } else {
    yield put(Actions.inviteUserError(response.data.message));
  }
}

function* editUserPermission(actions) {
  const response = yield call(editUserPermissionAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.editUserPermissionSuccess(
          response.data.message,
          response.data.user,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.editUserPermissionError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.editUserPermissionError(response.data.message));
    }
  } else {
    yield put(Actions.editUserPermissionError(response.data.message));
  }
}

function* changeUserRole(actions) {
  const response = yield call(ChangeUserRoleAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.changeUserRoleSuccess(
          response.data.message,
          response.data.user,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.changeUserRoleError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.changeUserRoleError(response.data.message));
    }
  } else {
    yield put(Actions.changeUserRoleError(response.data.message));
  }
}

function* changeUserStatus(actions) {
  const response = yield call(ChangeUserStatusAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.changeUserStatusSuccess(
          response.data.message,
          response.data.user,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.changeUserStatusError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.changeUserStatusError(response.data.message));
    }
  } else {
    yield put(Actions.changeUserStatusError(response.data.message));
  }
}

function* getRoles(actions) {
  const response = yield call(getRolesAPI, actions.data);
  if (response.status === 200) {
    if (response?.data?.status === 200) {
      yield put(
        Actions.getRolesSuccess(
          response.data?.message,
          response.data?.data,
          response.data?.cntRoleData,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.getRolesError(response.data?.message));
      removeStorage();
    } else {
      yield put(Actions.getRolesError(response.data?.message));
    }
  } else {
    yield put(Actions.getRolesError(response.data?.message));
  }
}

function* getPermissionLists(actions) {
  const response = yield call(getPermissionsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.permissionSuccess(
        response.data.message,
        response.data.data
      ));
    } else if (response.data.status === 403) {
      yield put(Actions.permissionError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.permissionError(response.data.message));
    }
  } else {
    yield put(Actions.permissionError(response.data.message));
  }
}

function* createRole(actions) {
  const response = yield call(createRoleAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.createRoleSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.createRoleError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.createRoleError(response.data.message));
    }
  } else {
    yield put(Actions.createRoleError(response.data.message));
  }
}

function* editRole(actions) {
  const response = yield call(editRoleAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.editRoleSuccess(response?.data?.message, response?.data?.role),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.editRoleError(response?.data?.message));
      removeStorage();
    } else {
      yield put(Actions.editRoleError(response?.data?.message));
    }
  } else {
    yield put(Actions.editRoleError(response?.data?.message));
  }
}

function* deleteRole(actions) {
  const response = yield call(deleteRoleAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.deleteRoleSuccess(response.data.message, response.data.role),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteRoleError(response.data?.message));
      removeStorage();
    } else {
      yield put(Actions.deleteRoleError(response.data?.message));
    }
  } else {
    yield put(Actions.deleteRoleError(response.data?.message));
  }
}

function* listProjectNotifications(actions) {
  const response = yield call(GetProjectNotificationsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listProjectNotificationsSuccess(
          response.data.notifications,
          response.data.message,
          response.data.totalResults,
          response.data.totalResults1,
          response.data.filterByStatus,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listProjectNotificationsError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.listProjectNotificationsError(response.data.message));
    }
  } else {
    yield put(Actions.listProjectNotificationsError(response.data.message));
  }
}

function* createProjectNotification(actions) {
  const response = yield call(CreateProjectNotificationAPI, actions.data);
  if (response.status === 200) {
    if (response.data.success === true) {
      yield put(
        Actions.projectNotificationSuccess(
          response.data.notification,
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.projectNotificationError(response?.data?.message));
    }
  } else {
    yield put(Actions.projectNotificationError(response?.data?.message));
  }
}

function* deleteProjectNotification(actions) {
  const response = yield call(DeleteProjectNotificationAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteProjectNotificationSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.deleteProjectNotificationError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteProjectNotificationError(response.data.message));
    }
  } else {
    yield put(Actions.deleteProjectNotificationError(response.data.message));
  }
}

function* deleteMember(actions) {
  const response = yield call(DeleteMemberAPI, actions.data);
  if (response.status === 200) {
    if (response.data) {
      yield put(Actions.deleteMemberSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.deleteMemberError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteMemberError(response.data.message));
    }
  } else {
    yield put(Actions.deleteMemberError(response.data.message));
  }
}

function* customFieldsList(actions) {
  const response = yield call(GetCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    yield put(
      Actions.customFieldsSuccess(
        response.data.message,
        response.data.customFields,
        response.data.googleCutomFields,
      ),
    );
  } else {
    yield put(Actions.customFieldsError(response.data.message));
  }
}

function* addCustomFields(actions) {
  const response = yield call(AddCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.addCustomFieldsSuccess(response.data.message));
    } else {
      yield put(Actions.addCustomFieldsError(response.data.error));
    }
  } else {
    yield put(Actions.addCustomFieldsError(response.data.error));
  }
}

function* deleteCustomFields(actions) {
  const response = yield call(DeleteCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteCustomFieldsSuccess(response.data.message));
    } else {
      yield put(Actions.deleteCustomFieldsError(response.data.error));
    }
  } else {
    yield put(Actions.deleteCustomFieldsError(response.data.error));
  }
}

function* updateCustomField(actions) {
  const response = yield call(UpdateCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.updateCustomFieldSuccess(
          response.data.message,
          response.data.customField,
        ),
      );
    } else {
      yield put(Actions.updateCustomFieldError(response.data.error));
    }
  } else {
    yield put(Actions.updateCustomFieldError(response.data.error));
  }
}

function* listAttendees(actions) {
  const response = yield call(ListAttendeesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAttendeesSuccess(
          response.data.message,
          response.data.data,
          response.data.totalRecords,
        ),
      );
    } else {
      yield put(Actions.listAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.listAttendeesError(response.data.error));
  }
}

function* searchAttendees(actions) {
  const response = yield call(SearchAttendeesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchAttendeesSuccess(
          response.data.message,
          response.data.data,
          response.data.totalRecords,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.searchAttendeesError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.searchAttendeesError(response.data.message));
    }
  } else {
    yield put(Actions.searchAttendeesError(response.data.message));
  }
}

function* sortFilterAttendees(actions) {
  const response = yield call(SortFilterAttendeesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.sortFilterAttendeesSuccess(
          response.data.message,
          response.data.data,
          response.data.totalRecords,
          response.data.filterCnt,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.sortFilterAttendeesError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.sortFilterAttendeesError(response.data.message));
    }
  } else {
    yield put(Actions.sortFilterAttendeesError(response.data.message));
  }
}

function* addAttendees(actions) {
  const response = yield call(AddAttendeesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.addAttendeesSuccess(response.data.message, response.data.data),
      );
    } else {
      yield put(Actions.addAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.addAttendeesError(response.data.error));
  }
}

function* deleteAttendee(actions) {
  const response = yield call(DeleteAttendeeAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteAttendeesSuccess(response.data.message));
    } else {
      yield put(Actions.deleteAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.deleteAttendeesError(response.data.error));
  }
}

function* importAttendees(actions) {
  const response = yield call(ImportAttendeesAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.importAttendeesSuccess(response.data.message, response.data.uniqueAttendees, response.data?.importAttendeesId));
    } else if (response.data.status === 201) {
      yield put(
        Actions.importAttendeesValidation(
          response.data.message,
          response.data.duplicateEmailsInCsv,
          response.data.invalidEmailsRejection,
          response.data.alreadyExistInDb,
          response.data.groupNotExists,
          response.data.rejectedEmails,
        ),
      );
    } else {
      yield put(Actions.importAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.importAttendeesError(response.data.error));
  }
}

function* downloadCsv(actions) {
  const response = yield call(DownloadCsvAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.downloadCsvSuccess(response.data.message));
    } else {
      yield put(Actions.downloadCsvError(response.data.error));
    }
  } else {
    yield put(Actions.downloadCsvError(response.data.error));
  }
}

function* exportAttendees(actions) {
  const response = yield call(exportAttendeesAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.exportAttendeesSuccess(response.data.message));
    } else {
      yield put(Actions.exportAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.exportAttendeesError(response.data.error));
  }
}

function* exportCheckInHistory(actions) {
  const response = yield call(exportCheckInHistoryAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.exportCheckInHistorySuccess(response.data.message));
    } else {
      yield put(Actions.exportCheckInHistoryError(response.data.error));
    }
  } else {
    yield put(Actions.exportCheckInHistoryError(response.data.error));
  }
}

function* enableAttendeeStatus(actions) {
  const response = yield call(EnableAttendeeStatusAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.enabledStatusSuccess(response.data.message));
    } else {
      yield put(Actions.enabledStatusError(response.data.error));
    }
  } else {
    yield put(Actions.enabledStatusError(response.data.error));
  }
}

function* checkinAttendeeStatus(actions) {
  const response = yield call(CheckinActionStatusAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.actionCheckinSuccess(response.data.message));
    } else {
      yield put(Actions.actionCheckinError(response.data.error));
    }
  } else {
    yield put(Actions.actionCheckinError(response.data.error));
  }
}

function* actionAssignGroups(actions) {
  const response = yield call(ActionAssignGroupsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.actionAssignSuccess(response.data.message));
    } else {
      yield put(Actions.actionAssignError(response.data.error));
    }
  } else {
    yield put(Actions.actionAssignError(response.data.error));
  }
}

function* editAttendee(actions) {
  const response = yield call(EditAttendeeAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.editAttendeeSuccess(response.data.message));
    } else {
      yield put(Actions.editAttendeeError(response.data.error));
    }
  } else {
    yield put(Actions.editAttendeeError(response.data.error));
  }
}

function* checkedInHistoryAttendee(actions) {
  const response = yield call(checkedInHistoryAttendeeAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.checkedInHistoryAttendeeSuccess(response.data.message));
    } else {
      yield put(Actions.checkedInHistoryAttendeeError(response.data.error));
    }
  } else {
    yield put(Actions.checkedInHistoryAttendeeError(response.data.error));
  }
}

function* listAttendeesGroups(actions) {
  const response = yield call(ListAttendeesGroupsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAttendeesGroupsSuccess(
          response.data.message,
          response.data.data,
          response.data.totalGroupsToListInGroupsTab,
        ),
      );
    } else {
      yield put(Actions.listAttendeesGroupsError(response.data.error));
    }
  } else {
    yield put(Actions.listAttendeesGroupsError(response.data.error));
  }
}

function* addAttendeesGroups(actions) {
  const response = yield call(AddAttendeesGroupsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.addAttendeesGroupsSuccess(response.data.message));
    } else {
      yield put(Actions.addAttendeesGroupsError(response.data.error));
    }
  } else {
    yield put(Actions.addAttendeesGroupsError(response.data.error));
  }
}

function* deleteAttendeesGroups(actions) {
  const response = yield call(DeleteAttendeesGroupsAPI, actions.data);
  if (response.status === 200) {
    yield put(Actions.deleteAttendeesGroupsSuccess(response.data.message));
  } else {
    yield put(Actions.deleteAttendeesGroupsError(response.data.error));
  }
}

function* sortAttendees(actions) {
  const response = yield call(SortAttendeesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.sortAttendeesSuccess(
          response.data.message,
          response.data.data,
          response.data.totalRecords,
        ),
      );
    } else {
      yield put(Actions.sortAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.sortAttendeesError(response.data.error));
  }
}

function* listUnassignedAttendees(actions) {
  const response = yield call(ListUnassignedAttendeesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listUnassignedAttendeesSuccess(
          response.data.message,
          response.data.data,
          response.data.totalResults,
        ),
      );
    } else {
      yield put(Actions.listUnassignedAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.listUnassignedAttendeesError(response.data.error));
  }
}

function* listAssignedAttendees(actions) {
  const response = yield call(ListAssignedAttendeesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAssignedAttendeesSuccess(
          response.data.message,
          response.data.data,
          response.data.totalResults,
          response.data.filterByStatus,
        ),
      );
    } else {
      yield put(Actions.listAssignedAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.listAssignedAttendeesError(response.data.error));
  }
}

function* unassignAttendeesFromGroup(actions) {
  const response = yield call(UnassignAttendeesFromGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.unassignAttendeesSuccess(response.data.message));
    } else {
      yield put(Actions.unassignAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.unassignAttendeesError(response.data.error));
  }
}

function* assignAttendeesFromGroup(actions) {
  const response = yield call(AssignAttendeesFromGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.assignAttendeesSuccess(response.data.message));
    } else {
      yield put(Actions.assignAttendeesError(response.data.error));
    }
  } else {
    yield put(Actions.assignAttendeesError(response.data.error));
  }
}

function* searchAttendeesOfGroup(actions) {
  const response = yield call(SearchAttendeesOfGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchAttendeesOfGroupSuccess(
          response.data.message,
          response.data.data,
          response.data.filterByStatus
        ),
      );
    } else {
      yield put(Actions.searchAttendeesOfGroupError(response.data.message));
    }
  } else {
    yield put(Actions.searchAttendeesOfGroupError(response.data.message));
  }
}

function* searchAttendeesOfUnassignGroup(actions) {
  const response = yield call(SearchAttendeesOfUnassignGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchAttendeesOfUnassignGroupSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(
        Actions.searchAttendeesOfUnassignGroupError(response.data.message),
      );
    }
  } else {
    yield put(
      Actions.searchAttendeesOfUnassignGroupError(response.data.message),
    );
  }
}

function* searchAttendeesGroups(actions) {
  const response = yield call(SearchAttendeesGroupsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchAttendeesGroupsSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(Actions.searchAttendeesGroupsError(response.data.error));
    }
  } else {
    yield put(Actions.searchAttendeesGroupsError(response.data.error));
  }
}

function* editAttendeesGroupName(actions) {
  const response = yield call(EditAttendeesGroupNameAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.editAttendeesGroupNameSuccess(response.data.message));
    } else {
      yield put(Actions.editAttendeesGroupNameError(response.data.error));
    }
  } else {
    yield put(Actions.editAttendeesGroupNameError(response.data.error));
  }
}

function* listTransactionalEmailTemplates(actions) {
  const response = yield call(ListTransactionalEmailTemplatesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listTransactionalEmailTemplatesSuccess(
          response.data.message,
          response.data.templates,
        ),
      );
    } else {
      yield put(
        Actions.listTransactionalEmailTemplatesError(response.data.error),
      );
    }
  } else {
    yield put(Actions.listTransactionalEmailTemplatesError(response.data.error));
  }
}

function* listBadgesTemplate(actions) {
  const response = yield call(ListBadgesTemplateAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listBadgesTemplateSuccess(
          response.data.message,
          response.data.templates,
        ),
      );
    } else {
      yield put(
        Actions.listBadgesTemplateError(response.data.error),
      );
    }
  } else {
    yield put(Actions.listBadgesTemplateError(response.data.error));
  }
}

function* listAllTransactionalEmailTemplates(actions) {
  const response = yield call(
    ListAllTransactionalEmailTemplatesAPI,
    actions.data,
  );
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAllTransactionalEmailTemplatesSuccess(
          response.data.message,
          response.data.templates,
        ),
      );
    } else {
      yield put(
        Actions.listAllTransactionalEmailTemplatesError(response.data.error),
      );
    }
  } else {
    yield put(
      Actions.listAllTransactionalEmailTemplatesError(response.data.error),
    );
  }
}

function* listCampaignsRequest(actions) {
  const response = yield call(ListCampaignsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listCampaignsSuccess(
          response.data.message,
          response.data.campaigns,
          response.data.totalResults,
          response.data.filterByStatus,
        ),
      );
    } else {
      yield put(Actions.listCampaignsError(response.data.error));
    }
  } else {
    yield put(Actions.listCampaignsError(response.data.error));
  }
}

function* listAllCampaignsRequest(actions) {
  const response = yield call(ListAllCampaignsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAllCampaignsSuccess(
          response.data.message,
          response.data.campaigns,
        ),
      );
    } else {
      yield put(Actions.listAllCampaignsError(response.data.error));
    }
  } else {
    yield put(Actions.listAllCampaignsError(response.data.error));
  }
}

function* checkCampaignRequest(actions) {
  const response = yield call(CheckCampaignAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.checkCampaignSuccess(response.data.message));
    } else {
      yield put(Actions.checkCampaignError(response.data.message));
    }
  } else {
    yield put(Actions.checkCampaignError(response.data.message));
  }
}

function* listCampaignTemplates(actions) {
  const response = yield call(ListCampaignTemplatesAPI, actions.data);
  if (response.status === 200) {
    yield put(
      Actions.listCampaignTemplatesSuccess(
        response.data.message,
        response.data.campaignTemplates,
        response.data.filterTemplatesByType,
      ),
    );
  } else {
    yield put(Actions.listCampaignTemplatesError(response.data.message));
  }
}

function* createCampaign(actions) {
  const response = yield call(CreateCampaignAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.createCampaignSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(
        Actions.createCampaignError(response.data.error, response.data.message),
      );
    }
  } else {
    yield put(
      Actions.createCampaignError(response.data.error, response.data.message),
    );
  }
}

function* updateCampaign(actions) {
  const response = yield call(UpdateCampaignAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.updateCampaignSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(
        Actions.updateCampaignError(response.data.error, response.data.message),
      );
    }
  } else {
    yield put(
      Actions.updateCampaignError(response.data.error, response.data.message),
    );
  }
}

function* getCampaign(actions) {
  const response = yield call(GetCampaignAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getCampaignSuccess(
          response.data.message,
          response.data.campaign,
        ),
      );
    } else {
      yield put(Actions.getCampaignError(response?.data?.error));
    }
  } else {
    yield put(Actions.getCampaignError(response?.data?.error));
  }
}

function* getReport(actions) {
  const response = yield call(GetReportAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getReportSuccess(response.data.message, response.data.report),
      );
    } else {
      yield put(Actions.getReportError(response.data.error));
    }
  } else {
    yield put(Actions.getReportError(response.data.error));
  }
}

function* getPublishedEvents(actions) {
  const response = yield call(GetPublishedEventsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getPublishedEventsSuccess(
          response.data.message,
          response.data.events,
        ),
      );
    } else {
      yield put(Actions.getPublishedEventsError(response.data.error));
    }
  } else {
    yield put(Actions.getPublishedEventsError(response.data.error));
  }
}

function* listAgenda(actions) {
  const response = yield call(ListAgendaAPI, actions.data);
  if (response?.status === 200) {
    if (response?.data.status === 200) {
      yield put(
        Actions.listAgendaSuccess(
          response.data.message,
          response.data.Agenda,
          response.data.agendaCount,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.listAgendaError(response.data.message));
      removeStorage();
    }

    else {
      yield put(Actions.listAgendaError(response.data.error));
    }
  } else {
    yield put(Actions.listAgendaError(response.data.error));
  }
}

function* deleteAgenda(actions) {
  const response = yield call(DeleteAgendaAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteAgendaSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.deleteAgendaError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.deleteAgendaError(response.data.message));
    }
  } else {
    yield put(Actions.deleteAgendaError(response.data.message));
  }
}

function* addAgenda(actions) {
  const response = yield call(AddAgendaAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.addAgendaSuccess(response.data.message, response.data.data),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.addAgendaError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.addAgendaError(response.data.message));
    }
  } else {
    yield put(Actions.addAgendaError(response.data.message));
  }
}

function* editAgenda(actions) {
  const response = yield call(EditAgendaAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.editAgendaSuccess(response.data.message, response.data.Agenda),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.editAgendaError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.editAgendaError(response.data.message));
    }
  } else {
    yield put(Actions.editAgendaError(response.data.message));
  }
}

function* deleteCampaign(actions) {
  const response = yield call(DeleteCampaignAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteCampaignSuccess(response.data.message));
    } else {
      yield put(Actions.deleteCampaignError(response.data.error));
    }
  } else {
    yield put(Actions.deleteCampaignError(response.data.error));
  }
}

function* listAttendeesOfCampaigns(actions) {
  const response = yield call(ListAttendeesOfCampaignsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAttendeesOfCampaignsSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(Actions.listAttendeesOfCampaignsError(response.data.error));
    }
  } else {
    yield put(Actions.listAttendeesOfCampaignsError(response.data.error));
  }
}

function* listCampaignAudienceGroups(actions) {
  const response = yield call(ListCampaignAudienceGroupsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAudienceGroupsSuccess(
          response.data.message,
          response.data.groups,
        ),
      );
    } else {
      yield put(Actions.listAudienceGroupsError(response.data.error));
    }
  } else {
    yield put(Actions.listAudienceGroupsError(response.data.error));
  }
}

function* getExcludeAttendeeCount(actions) {
  const response = yield call(getExcludeAttendeeCountAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getExcludeAttendeeCountSuccess(
          response.data.excludeAttendeeCount,
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.getExcludeAttendeeCountError(response.data.error));
    }
  } else {
    yield put(Actions.getExcludeAttendeeCountError(response.data.error));
  }
}

function* getExcludeCount(actions) {
  const response = yield call(getExcludeCountAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getExcludeCountSuccess(
          response.data.excludeCount,
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.getExcludeCountError(response.data.error));
    }
  } else {
    yield put(Actions.getExcludeCountError(response.data.error));
  }
}

function* listAttendeesNotAddedToCampaign(actions) {
  const response = yield call(ListAttendeesNotAddedToCampaignAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAttendeesNotAddedToCampaignSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(
        Actions.listAttendeesNotAddedToCampaignError(response.data.error),
      );
    }
  } else {
    yield put(Actions.listAttendeesNotAddedToCampaignError(response.data.error));
  }
}

function* sendTestEmail(actions) {
  const response = yield call(SendTestEmailAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.sendTestEmailSuccess(response.data.message));
    } else {
      yield put(Actions.sendTestEmailError(response.data.error));
    }
  } else {
    yield put(Actions.sendTestEmailError(response.data.error));
  }
}

function* searchAgenda(actions) {
  const response = yield call(SearchAgendaAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchAgendaSuccess(
          response.data.agenda,
          response.data.totalResults,
          response.data.message,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.searchAgendaError(response.data.message));
      removeStorage();
    } else {
      yield put(Actions.searchAgendaError(response.data.message));
    }
  } else {
    yield put(Actions.searchAgendaError(response.data.message));
  }
}

function* groupCheckRequest(actions) {
  const response = yield call(CheckGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.groupCheckSuccess(response.data.message));
    } else {
      yield put(Actions.groupCheckError(response.data.message));
    }
  } else {
    yield put(Actions.groupCheckError(response.data.message));
  }
}

function* listGamificationGrades(actions) {
  const response = yield call(ListGamificationGradesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listGamificationGradesSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(Actions.listGamificationGradesError(response.data.error));
    }
  } else {
    yield put(Actions.listGamificationGradesError(response.data.error));
  }
}
function* updateGamificationGrades(actions) {
  const response = yield call(UpdateGamificationGradesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.updateGamificationGradesSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(Actions.updateGamificationGradesError(response.data.error));
    }
  } else {
    yield put(Actions.updateGamificationGradesError(response.data.error));
  }
}

function* listGamificationLeaderBoards(actions) {
  const response = yield call(listGamificationLeaderBoardsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listGamificationLeaderBoardsSuccess(
          response.data.message,
          response.data.totalResults,
          response.data.leaderBoard,
          response.data.filterByAchievement,
        ),
      );
    } else {
      yield put(Actions.listGamificationLeaderBoardsError(response.data.error));
    }
  } else {
    yield put(Actions.listGamificationLeaderBoardsError(response.data.error));
  }
}

function* addPointsGamificationLeaderBoards(actions) {
  const response = yield call(
    addPointsGamificationLeaderBoardsAPI,
    actions.data,
  );
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.addPointsGamificationLeaderBoardsSuccess(response.data.message),
      );
    } else {
      yield put(
        Actions.addPointsGamificationLeaderBoardsError(response.data.error),
      );
    }
  } else {
    yield put(
      Actions.addPointsGamificationLeaderBoardsError(response.data.error),
    );
  }
}

function* getGamificationChallenges(actions) {
  const response = yield call(getGamificationChallengesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getGamificationChallengesSuccess(
          response.data.message,
          response.data.challenges,
          response.data.totalResults,
        ),
      );
    } else {
      yield put(Actions.getGamificationChallengesError(response.data.error));
    }
  } else {
    yield put(Actions.getGamificationChallengesError(response.data.error));
  }
}

function* configrationChallenges(actions) {
  const response = yield call(listConfigrationChallengesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.configrationChallengesSuccess(
          response.data.message,
          response.data.challengesList,
          response.data.totalConfigrationChallenges,
          response.data.filterByStatus,
        ),
      );
    } else {
      yield put(Actions.configrationChallengesError(response.data.error));
    }
  } else {
    yield put(Actions.configrationChallengesError(response.data.error));
  }
}

function* addChallenges(actions) {
  const response = yield call(AddChallengesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.addConfigrationChallengesSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.addConfigrationChallengesError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.addConfigrationChallengesError(response.data.error));
    }
  } else {
    yield put(Actions.addConfigrationChallengesError(response.data.error));
  }
}

function* updateChallenge(actions) {
  const response = yield call(UpdateChallengesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.updateConfigrationChallengesSuccess(
          response.data.message,
          response.data.challengesList,
          response.data.isStatusUpdated,
        ),
      );
    } else {
      yield put(Actions.updateConfigrationChallengesError(response.data.error));
    }
  } else {
    yield put(Actions.updateConfigrationChallengesError(response.data.error));
  }
}

function* deleteChallenge(actions) {
    const response = yield call(DeleteChallengesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.deleteConfigrationChallengesSuccess(response.data.message),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteConfigrationChallengesError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.deleteConfigrationChallengesError(response.data.error));
    }
  } else {
    yield put(Actions.deleteConfigrationChallengesError(response.data.error));
  }
}

function* changeChallengeStatus(actions) {
  const response = yield call(ChallengeStatusAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.challengeStatusSuccess(response.data.message));
    } else {
      yield put(Actions.challengeStatusError(response.data.error));
    }
  } else {
    yield put(Actions.challengeStatusError(response.data.error));
  }
}

function* getAttendeesChartData(actions) {
  const response = yield call(getAttendeesChartDataAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getAttendeesChartDataSuccess(
          response.data.message,
          response.data.data,
        ),
      );
    } else {
      yield put(Actions.getAttendeesChartDataError(response.data.error));
    }
  } else {
    yield put(Actions.getAttendeesChartDataError(response.data.error));
  }
}

function* getEventSummaryDetails(actions) {
  const response = yield call(getEventSummaryDetailsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getEventSummaryDetailsSuccess(
          response.data.message,
          response.data.summaryDetails,
        ),
      );
    } else {
      yield put(Actions.getEventSummaryDetailsError(response.data.error));
    }
  } else {
    yield put(Actions.getEventSummaryDetailsError(response.data.error));
  }
}

function* leaderboardEngagedUsers(actions) {
  const response = yield call(getleaderboardAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.leaderboardUserHistorySuccess(
          response.data.message,
          response.data.leaderboardUserHistory,
          response.data.totalResults,
        ),
      );
    } else {
      yield put(Actions.leaderboardUserHistoryError(response.data.error));
    }
  } else {
    yield put(Actions.leaderboardUserHistoryError(response.data.error));
  }
}

function* challengesEngagedUsers(actions) {
  const response = yield call(getChallengesEngagedUsersAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.challengesEngagedUsersSuccess(
          response.data.message,
          response.data.challengesEngagedUsers,
        ),
      );
    } else {
      yield put(Actions.challengesEngagedUsersError(response.data.error));
    }
  } else {
    yield put(Actions.challengesEngagedUsersError(response.data.error));
  }
}

function* deleteProject(actions) {
  const response = yield call(deleteProjectAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteProjectSuccess(response.data.message));
    } else {
      yield put(Actions.deleteProjectError(response.data.message));
    }
  } else {
    yield put(Actions.deleteProjectError(response.data.message));
  }
}

function* listWebhooks(actions) {
  const response = yield call(listWebhooksAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listWebhooksSuccess(
          response.data.list,
          response.data.message,
          response.data.totalResults,
        ),
      );
    } else {
      yield put(Actions.listWebhooksError(response.data.message));
    }
  } else {
    yield put(Actions.listWebhooksError(response.data.message));
  }
}

function* toggleWebhook(actions) {
  const response = yield call(toggleWebhookAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.toggleWebhookSuccess(
          response.data.message,
          response.data.webhookData,
          response.data.activeStatus,
        ),
      );
    } else {
      yield put(Actions.toggleWebhookError(response.data.message));
    }
  } else {
    yield put(Actions.toggleWebhookError(response.data.message));
  }
}

function* generateSecretKeyForWebhook(actions) {
  const response = yield call(generateSecretKeyForWebhookAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.generateSecretKeySuccess(
          response.data.message,
          response.data.secretKey,
        ),
      );
    } else {
      yield put(Actions.generateSecretKeyError(response.data.message));
    }
  } else {
    yield put(Actions.generateSecretKeyError(response.data.message));
  }
}

function* checkWebhookEventId(actions) {
  const response = yield call(checkWebhookEventIdAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.checkWebhookEventIdSuccess(
          response.data.message,
          response.data.secretKey,
        ),
      );
    } else {
      yield put(Actions.checkWebhookEventIdError(response.data.message));
    }
  } else {
    yield put(Actions.checkWebhookEventIdError(response.data.message));
  }
}

function* getMyProfileRank(actions) {
  const response = yield call(getMyRankAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.myProfileRankSuccess(
          response.data.message,
          response.data.topRank,
          response.data.myRank,
        ),
      );
    } else {
      yield put(Actions.myProfileRankError(response.data.error));
    }
  } else {
    yield put(Actions.myProfileRankError(response.data.error));
  }
}

function* getReportChallenges(actions) {
  const response = yield call(getReportChallengesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getReportChallengesSuccess(
          response.data.message,
          response.data.challenges,
          response.data.totalResults,
        ),
      );
    } else {
      yield put(Actions.getReportChallengesError(response.data.error));
    }
  } else {
    yield put(Actions.getReportChallengesError(response.data.error));
  }
}

function* createGroupOnly(actions) {
  const response = yield call(createGroupAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.createGroupSuccess(response.data.message));
    } else if (response.data.status === 403) {
    } else {
      yield put(Actions.createGroupError(response.data.error));
    }
  } else {
    yield put(Actions.createGroupError(response.data.error));
  }
}

function* replicateAgenda(actions) {
  const response = yield call(replicateAgendaAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.replicateAgendaSuccess(response.data.message));
    } else {
      yield put(Actions.replicateAgendaError(response.data.error));
    }
  } else {
    yield put(Actions.replicateAgendaError(response.data.error));
  }
}
function* replicateNotification(actions) {
  const response = yield call(replicateNotificationAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.replicateProjectNotificationSuccess(response.data.message),
      );
    } else {
      yield put(Actions.replicateProjectNotificationError(response.data.error));
    }
  } else {
    yield put(Actions.replicateProjectNotificationError(response.data.error));
  }
}

function* replicateProject(actions) {
  const response = yield call(replicateProjectAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.replicateProjectSuccess(response.data.message));
    } else {
      yield put(Actions.replicateProjectError(response.data.message));
    }
  } else {
    yield put(Actions.replicateProjectError(response.data.message));
  }
}

function* replicatePageRequest(actions) {
  const response = yield call(replicatePageAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.replicatePageSuccess(response.data.message));
    } else {
      yield put(Actions.replicatePageError(response.data.message));
    }
  } else {
    yield put(Actions.replicatePageError(response.data.message));
  }
}

function* listAccessCode(actions) {
  const response = yield call(listAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAccessCodeSuccess(
          response.data.message,
          response.data.accessCode,
          response.data.totalResults,
          response.data.filterByStatus,
          response.data.filterBySource,
        ),
      );
    } else {
      yield put(Actions.listAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.listAccessCodeError(response.data.error));
  }
}

function* exportAccesCode(actions) {
  const response = yield call(exportAccesCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.exportAccessCodeSuccess(
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.exportAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.exportAccessCodeError(response.data.error));
  }
}

function* addAccessCode(actions) {
  const response = yield call(addAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.addAccessCodeSuccess(response.data.message));
    } else {
      yield put(Actions.addAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.addAccessCodeError(response.data.error));
  }
}

function* editAccessCode(actions) {
  const response = yield call(editAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.editAccessCodeSuccess(response.data.message));
    } else {
      yield put(Actions.editAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.editAccessCodeError(response.data.error));
  }
}

function* deleteAccessCode(actions) {
  const response = yield call(deleteAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteAccessCodeSuccess(response.data.message));
    } else {
      yield put(Actions.deleteAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.deleteAccessCodeError(response.data.error));
  }
}

function* changeAccessCode(actions) {
  const response = yield call(changeAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.changeAccessCodeSuccess(response.data.message));
    } else {
      yield put(Actions.changeAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.changeAccessCodeError(response.data.error));
  }
}

function* importAccessCode(actions) {
  const response = yield call(ImportAccessCodeAPI, actions.data);

  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.importAccessCodeSuccess(
        response.data.message,
        response.data.uniqueAccesscodeList,
        response.data.importAccessCodeId
      ));
    } else if (response.data.status === 201) {
      yield put(
        Actions.importAccessCodeValidation(
          response.data.message,
          response.data.duplicateAccessCodeInCsv,
          response.data.invalidAccessCodeRejection,
          response.data.alreadyExistInDb,
          response.data.rejectedAccessCodes,
        ),
      );
    } else {
      yield put(Actions.importAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.importAccessCodeError(response.data.error));
  }
}

function* findIfAllowedFromSuperadmin(actions) {
  const response = yield call(FindIfAllowedFromSuperadminAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.findIfAllowedFromSuperadminSuccess(
          response.data.isAllowed,
          response.data.message,
          response.data.projectLimitExceeded,
          response.data.featuredAllowed,
          response.data.servicePlanDetails,
        ),
      );
    } else {
      yield put(Actions.findIfAllowedFromSuperadminError(response.data.error));
    }
  } else {
    yield put(Actions.findIfAllowedFromSuperadminError(response.data.error));
  }
}
function* findIfAllowedFromSuperadminOuter(actions) {
  const response = yield call(FindIfAllowedFromSuperadminOuterAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.findIfAllowedFromSuperadminOuterSuccess(
          response.data.isAllowed,
          response.data.message,
          response.data.projectLimitExceeded,
          response.data.featuredAllowed,
          response.data.servicePlanDetails,
        ),
      );
    } else {
      yield put(Actions.findIfAllowedFromSuperadminOuterError(response.data.error));
    }
  } else {
    yield put(Actions.findIfAllowedFromSuperadminOuterError(response.data.error));
  }
}

function* listMeeting(actions) {
  const response = yield call(listMeetingAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listMeetingSuccess(
          response.data.message,
          response.data.meetingList,
          response.data.totalResults,
          response.data.filterByStatus,
          response.data.notcheckedInCnt,
          response.data.checkedInCnt,
        ),
      );
    } else {
      yield put(Actions.listMeetingError(response.data.error));
    }
  } else {
    yield put(Actions.listMeetingError(response.data.error));
  }
}

function* assignAccessCode(actions) {
  const response = yield call(assignAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.assignAccessCodeSuccess(
          response.data.message,
          response.data?.getUnassignAccessCode,
          response.data?.totalAccessCode,
          response.data?.isAccessCodeAssign,
        ),
      );
    } else {
      yield put(Actions.assignAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.assignAccessCodeError(response.data.error));
  }
}

function* getUnusedAccessCode(actions) {
  const response = yield call(getUnusedAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.unUsedAccessCodeSuccess(
          response.data.message,
          response.data?.getUnassignAccessCode,
          response.data?.totalAccessCode,
        ),
      );
    } else {
      yield put(Actions.unUsedAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.unUsedAccessCodeError(response.data.error));
  }
}

function* getBillingData(actions) {
  const response = yield call(GetBillingDataAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getBillingDataSuccess(
          response.data.modules,
          response.data.features,
          response.data.message,
          response.data.servicePlanName,
          response.data.pricingPlanName,
          response.data.servicePlanDetails,
        ),
      );
    } else {
      yield put(Actions.getBillingDataError(response.data.error));
    }
  } else {
    yield put(Actions.getBillingDataError(response.data.error));
  }
}

function* getProjectsBillingData(actions) {
  const response = yield call(GetProjectsBillingDataAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getProjectsBillingDataSuccess(
          response.data.projectsData,
          response.data.totalProjects,
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.getProjectsBillingDataError(response.data.error));
    }
  } else {
    yield put(Actions.getProjectsBillingDataError(response.data.error));
  }
}

function* addMeeting(actions) {
  const response = yield call(addMeetingAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.addMeetingSuccess(response.data.message,response.data.meeting));
    } else {
      yield put(Actions.addMeetingError(response.data.error));
    }
  } else {
    yield put(Actions.addMeetingError(response.data.error));
  }
}

function* replicateMeeting(actions) {
  const response = yield call(replicateMeetingAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.replicateMeetingSuccess(response.data.message));
    } else {
      yield put(Actions.replicateMeetingError(response.data.error));
    }
  } else {
    yield put(Actions.replicateMeetingError(response.data.error));
  }
}

function* deleteMeeting(actions) {
  const response = yield call(DeleteMeetingAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteMeetingSuccess(response.data.message));
    } else if (response.data.status === 403) {
      yield put(Actions.deleteMeetingError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.deleteMeetingError(response.data.error));
    }
  } else {
    yield put(Actions.deleteMeetingError(response.data.error));
  }
}

function* updateMeeting(actions) {
  const response = yield call(UpdateMeetingAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.updateMeetingSuccess(response.data.message, response.data.updateMeetingData));
    } else {
      yield put(Actions.updateMeetingError(response.data.error));
    }
  } else {
    yield put(Actions.updateMeetingError(response.data.error));
  }
}

function* listAttendeesOfNotAddedToMeeting(actions) {
  const response = yield call(listAttendeesOfNotAddedToMeetingAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAttendeesNotAddedToMeetingSuccess(
          response.data.message,
          response.data.data,
          response.data.totalAttendees,
        ),
      );
    } else {
      yield put(
        Actions.listAttendeesNotAddedToMeetingError(response.data.error),
      );
    }
  } else {
    yield put(Actions.listAttendeesNotAddedToMeetingError(response.data.error));
  }
}
function* attendeeGroupsForMeeting(actions) {
  const response = yield call(attendeeGroupsForMeetingAPI, actions.data)
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.attendeeGroupsForMeetingSuccess(
          response.data.message,
          response.data.attendeesGroups,
          response.data.totalAttendeeGroups
        ),
      )
    } else {
      yield put(
        Actions.attendeeGroupsForMeetingError(response.data.error),
      )
    }
  } else {
    yield put(Actions.attendeeGroupsForMeetingError(response.data.error))
  }
}

function* audienceForMeeting(actions) {
  const response = yield call(audienceForMeetingAPI, actions.data)
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.audienceForMeetingSuccess(
          response.data.message,
          response.data.audienceData,
          response.data.totalAudience
        ),
      )
    } else {
      yield put(
        Actions.audienceForMeetingError(response.data.error),
      )
    }
  } else {
    yield put(Actions.audienceForMeetingError(response.data.error))
  }
}

function* searchForMeeting(actions) {
  const response = yield call(searchForMeetingAPI, actions.data)
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.searchForMeetingSuccess(
          response.data.message,
          response.data.mergeArray,
        ),
      )
    } else {
      yield put(
        Actions.searchForMeetingError(response.data.error),
      )
    }
  } else {
    yield put(Actions.searchForMeetingError(response.data.error))
  }
}

function* getAudienceContact(actions) {
  const response = yield call(getAudienceContactAPI, actions.data)
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getAudienceContactSuccess(
          response.data.message,
          response.data.contact,
        ),
      )
    } else {
      yield put(
        Actions.getAudienceContactError(response.data.error),
      )
    }
  } else {
    yield put(Actions.getAudienceContactError(response.data.error))
  }
}

function* getMeetingTemplate(actions) {
  const response = yield call(getMeetingTemplateAPI, actions.data)
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getMeetingTemplateSuccess(
          response.data.message,
          response.data.meetingTemplate,
          response.data.eventData,
        ),
      )
    } else {
      yield put(
        Actions.getMeetingTemplateError(response.data.error),
      )
    }
  } else {
    yield put(Actions.getMeetingTemplateError(response.data.error))
  }
}

function* checkMeetingTitle(actions) {
  const response = yield call(checkMeetingTitleAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.checkMeetingTitleSuccess(response.data.message));
    } else {
      yield put(Actions.checkMeetingTitleError(response.data.message));
    }
  } else {
    yield put(Actions.checkMeetingTitleError(response.data.message));
  }
}

function* checkDisabledAttendee(actions) {
  const response = yield call(checkDisabledAttendeeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.checkDisabledAttendeeSuccess(response.data.message));
    } else {
      yield put(Actions.checkDisabledAttendeeError(response.data.error));
    }
  } else {
    yield put(Actions.checkDisabledAttendeeError(response.data.error));
  }
}

function* replicateCampaign(actions) {
  const response = yield call(ReplicateCampaignAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.replicateCampaignSuccess(response.data.message));
    } else {
      yield put(Actions.replicateCampaignError(response.data.error));
    }
  } else {
    yield put(Actions.replicateCampaignError(response.data.error));
  }
}

function* getAccessCode(actions) {
  const response = yield call(getAccessCodeAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getAccessCodeSuccess(
          response.data.message,
          response.data?.accessCode,
          response.data?.remainingHours,
        ),
      );
    } else {
      yield put(Actions.getAccessCodeError(response.data.error));
    }
  } else {
    yield put(Actions.getAccessCodeError(response.data.error));
  }
}

function* assignAccessCodeProfile(actions) {
  const response = yield call(assignAccessCodeProfileAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.assignAccessCodeProfileSuccess(response.data.message));
    } else {
      yield put(Actions.assignAccessCodeProfileError(response.data.error));
    }
  } else {
    yield put(Actions.assignAccessCodeProfileError(response.data.error));
  }
}

function* getCustomFieldsProfile(actions) {
  const response = yield call(GetCustomFieldsProfileAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getCustomFieldsProfileSuccess(
          response.data.message,
          response.data?.customFields,
          response.data?.eventData,
          response.data?.pagePath,
          response.data?.challengeData
        ),
      );
    } else {
      yield put(Actions.getCustomFieldsProfileError(response.data.error));
    }
  } else {
    yield put(Actions.getCustomFieldsProfileError(response.data.error));
  }
}

function* getUserDetails(actions) {
  const response = yield call(GetUserDetailsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getUserDetailsSuccess(
          response.data?.data,
          response.data?.message
        ),
      );
    } else {
      yield put(Actions.getUserDetailsError(response.data.error));
    }
  } else {
    yield put(Actions.getUserDetailsError(response.data.error));
  }
}

function* rejectContactListRequest(actions) {
  const response = yield call(ListRejectsContactAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.rejectContactListSuccess(
          response.data?.rejectedList,
          response.data.message,
          response.data?.totalCount,
        ),
      );
    } else {
      yield put(Actions.rejectContactListError(response.data.error));
    }
  } else {
    yield put(Actions.rejectContactListError(response.data.error));
  }
}
function* googleWorkspaceGetWidget(actions) {
  const integrationAPI = new IntegrationAPI();
  const response = yield call(integrationAPI.getGoogleWorkspaceGetWidget, actions.data);
  if (response.status === 200) {
    yield put(
      Actions.googleWorkspaceGetWidgetSuccess(
        response.data?.integration,
        response.data.message,
      ),
    );
  } else {
    yield put(Actions.googleWorkspaceGetWidgetError(response.data.message));
  }
}
function* getGoogleWorkspaceLogs(actions) {
  const integrationAPI = new IntegrationAPI();
  const response = yield call(integrationAPI.getGoogleWorkspaceLogs, actions.data);
  if (response.status === 200) {
    yield put(
      Actions.googleWorkspaceGetLogsSuccess(
        response.data?.syncLogs,
        response.data.message,
        response.data.totalCount,
      ),
    );
  } else {
    yield put(Actions.googleWorkspaceGetLogsError(response.data.message));
  }
}

function* getGroupsForGoogleWorkspace(actions) {
  const response = yield call(GetGroupsForGoogleWorkspaceAPI, actions.params);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getGroupsForGoogleWorkspaceSuccess(
          response.data.data,
          response.data.message,
          response.data.totalNumberOfGroups,
        ),
      );
    } else {
      yield put(Actions.getGroupsForGoogleWorkspaceError(response.data?.error));
    }
  } else {
    yield put(Actions.getGroupsForGoogleWorkspaceError(response.data?.error));
  }
}
function* updateGoogleManagementList(actions) {
  const response = yield call(UpdateGoogleManagementListAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.updateGoogleManagementListSuccess(
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.updateGoogleManagementListError(response.data.message));
      createNotification('error', response.data.message);
    }
  } else {
    yield put(Actions.updateGoogleManagementListError(response.data.message));
    createNotification('error', response.data.message);
  }
}
function* updateGoogleWorkspaceCustomFields(actions) {
  const integrationAPI = new IntegrationAPI();
  const response = yield call(integrationAPI.updateGoogleWorkspaceCustomFields, actions.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.updateGoogleWorkspaceCustomFieldsSuccess(
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.updateGoogleWorkspaceCustomFieldsError(response.data.message));
    }
  } else {
    yield put(Actions.updateGoogleWorkspaceCustomFieldsError(response.data.message));
  }
}
function* updateGoogleWorkspaceSyncMode(actions) {
  const integrationAPI = new IntegrationAPI();
  const response = yield call(integrationAPI.updateGoogleWorkspaceSyncMode, actions.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.updateGoogleWorkspaceSyncModeSuccess(
          response.data.message,
          response.data.syncMode
        ),
      );
    } else {
      yield put(Actions.updateGoogleWorkspaceSyncModeError(response.data.message));
    }
  } else {
    yield put(Actions.updateGoogleWorkspaceSyncModeError(response.data.message));
  }
}

function* getSyncLogsHealthStatus(actions) {
  const integrationAPI = new IntegrationAPI();
  const response = yield call(integrationAPI.getSyncLogsHealthStatus, actions.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getSyncLogsHealthStatusSuccess(
          response.data.healthStatus,
          response.data.lastUpdatedAttendee
        ),
      );
    } else {
      yield put(Actions.getSyncLogsHealthStatusError(response.data.message));
    }
  } else {
    yield put(Actions.getSyncLogsHealthStatusError(response.data.message));
  }
}

function* updateGroupsFromGoogleWorkspace(actions) {
  const integrationAPI = new IntegrationAPI();
  const response = yield call(integrationAPI.updateGroupsFromGoogleWorkspace, actions.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.updateGroupsFromGoogleWorkspaceSuccess(
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.updateGroupsFromGoogleWorkspaceError(response.data.message));
    }
  } else {
    yield put(Actions.updateGroupsFromGoogleWorkspaceError(response.data.message));
  }
}

function* connectGoogleSignInRequest(actions) {
  const googleSignInAPI = new GoogleSignInAPI();
  const response = yield call(googleSignInAPI.connectGoogleSignIn, actions.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.connectGoogleSignInSuccess(
          response.data.url,
        ),
      );
    } else {
      yield put(Actions.connectGoogleSignInError(
        // response.data.message
      ));
    }
  } else {
    yield put(Actions.connectGoogleSignInError(
      // response.data.message
    ));
  }
}

function* checkWhetherGroupNameExistInIllumeet(actions) {
  const integrationAPI = new IntegrationAPI();
  const response = yield call(integrationAPI.checkWhetherGroupNameExistInIllumeet, actions.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.checkWhetherGroupNameExistInIllumeetSuccess(
          response.data.groupDoesExist,
          response.data.groupId,
        ),
      );
      if (response.data.groupDoesExist) {
        createNotification('error', 'Group name already exist');
      }
    } else {
      yield put(Actions.checkWhetherGroupNameExistInIllumeetError());
    }
  } else {
    yield put(Actions.checkWhetherGroupNameExistInIllumeetError());
  }
}

function* checkGoogleSignInValidToken(actions) {
  const response = yield call(checkGoogleSignInValidTokenAPI, actions.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.checkGoogleSignInValidTokenSuccess(
          response.data.isTokenValid,
        ),
      );
    } else {
      yield put(Actions.checkGoogleSignInValidTokenError(
        response.data.message
      ));
    }
  } else {
    yield put(Actions.checkGoogleSignInValidTokenError(
      response.data.message
    ));
  }
}

function* changeLanguageForUser(actions) {
  const response = yield call(changeLanguageForUserAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.changeLangForUserSuccess(response.data.lngCode));
      const Code = languageCode(response.data.lngCode)
      yield put(Actions.handleLang(Code));
    } 
    else {
      yield put(Actions.changeLangForUserError(response.data.message));
    }
  } else {
    yield put(Actions.updateLngCodeError(response.data.message));
  }
}

function* listAllMeetingsRequest(actions) {
  const response = yield call(ListAllMeetingsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAllMeetingsSuccess(
          response.data.message,
          response.data.meetings,
        ),
      );
    } else {
      yield put(Actions.listAllMeetingsError(response.data.error));
    }
  } else {
    yield put(Actions.listAllMeetingsError(response.data.error));
  }
}

function* addAudienceCustomFields(actions) {
  const response = yield call(addAudienceCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.addAudienceCustomFieldsSuccess(response.data.message));
    } else {
      yield put(Actions.addAudienceCustomFieldsError(response.data.error));
    }
  } else {
    yield put(Actions.addCustomFieldsError(response.data.error));
  }
}

function* listAudienceCustomFields(actions) {
  const response = yield call(listAudienceCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    yield put(
      Actions.listAudienceCustomFieldsSuccess(
        response.data.customFields,
        response.data.message,
      ),
    );
  } else {
    yield put(Actions.listAudienceCustomFieldsError(response.data.message));
  }
}

function* deleteAudienceCustomFields(actions) {
  const response = yield call(deleteAudienceCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteAudienceCustomFieldsSuccess(response.data.message));
    } else {
      yield put(Actions.deleteAudienceCustomFieldsError(response.data.error));
    }
  } else {
    yield put(Actions.deleteAudienceCustomFieldsError(response.data.error));
  }
}

function* updateAudienceCustomFields(actions) {
  const response = yield call(updateAudienceCustomFieldsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.deleteAudienceCustomFieldsSuccess(
          response.data.message,
          response.data.customField,
        ),
      );
    } else {
      yield put(Actions.updateAudienceCustomFieldsError(response.data.error));
    }
  } else {
    yield put(Actions.updateAudienceCustomFieldsError(response.data.error));
  }
}

function* assignManager(actions) {
  const response = yield call(assignManagerAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.assignManagerSuccess(
          // response.data?.data,
          response.data?.message
        ),
      );
    } else {
      yield put(Actions.assignManagerError(response.data.error));
    }
  } else {
    yield put(Actions.assignManagerError(response.data.error));
  }
}

function* allUserList(actions) {
  const response = yield call(allUserListAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.allUserListSuccess(
          response.data?.allUserList
        ),
      );
    } else {
      yield put(Actions.allUserListError(response.data.error));
    }
  } else {
    yield put(Actions.allUserListError(response.data.error));
  }
}

function* listAllUser(actions) {
  const response = yield call(listAllUsersAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.listAllUserSuccess(
          response.data?.listAllUser
        ),
      );
    } else {
      yield put(Actions.listAllUserError(response.data.error));
    }
  } else {
    yield put(Actions.listAllUserError(response.data.error));
  }
}

function* getUsersByAcceptSt(actions) {
  const response = yield call(getUsersByAcceptStAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getUsersByAcceptStSuccess(
          response.data?.users
        ),
      );
    } else {
      yield put(Actions.getUsersByAcceptStError(response.data.message));
    }
  } else {
    yield put(Actions.getUsersByAcceptStError(response.data.message));
  }
}

function* getActiveUsersForCompany(actions) {
  const response = yield call(getActiveUsersForCompanyAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getActiveUsersForCompanySuccess(
          response.data?.users
        ),
      );
    } else {
      yield put(Actions.getActiveUsersForCompanyError(response.data.message));
    }
  } else {
    yield put(Actions.getActiveUsersForCompanyError(response.data.message));
  }
}

function* updateSlots(actions) {
  const response = yield call(updateSlotsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.updateSlotsSuccess(
          response.data?.message
        ),
      );
    } else {
      yield put(Actions.updateSlotsError(response.data.error));
    }
  } else {
    yield put(Actions.updateSlotsError(response.data.error));
  }
}

function* getSlots(actions) {
  const response = yield call(getSlotsAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getSlotsSuccess(
          response.data?.message,
          response.data?.getSlots
        ),
      );
    } else {
      yield put(Actions.getSlotsError(response.data.error));
    }
  } else {
    yield put(Actions.getSlotsError(response.data.error));
  }
}

function* createFolderForFiles(actions) {
  const response = yield call(createFolderForFilesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.createFolderSuccess(response.data.message,response.data.alreadyExist));
    } 
    else {
      yield put(Actions.createFolderError(response.data.message));
    }
  } else {
    yield put(Actions.createFolderError(response.data.message));
  }
}

function* renameFolder(actions) {
  const response = yield call(renameFolderAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.renameFolderSuccess(response.data.message));
    } 
    else {
      yield put(Actions.renameFolderError(response.data.message));
    }
  } else {
    yield put(Actions.renameFolderError(response.data.message));
  }
}

function* deleteFolderById(actions) {
  const response = yield call(deleteFolderByIdAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.deleteFolderSuccess(response.data.message));
    } 
    else {
      yield put(Actions.deleteFolderError(response.data.message));
    }
  } else {
    yield put(Actions.deleteFolderError(response.data.message));
  }
}

function* addImages(actions) {
  const response = yield call(addImagesAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.addImagesSuccess(response.data.message));
    } 
    else {
      yield put(Actions.addImagesError(response.data.message));
    }
  } else {
    yield put(Actions.addImagesError(response.data.message));
  }
}

function* addDocuments(actions) {
  const response = yield call(addDocumentAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.addDocumentSuccess(response.data.message));
    } 
    else {
      yield put(Actions.addDocumentError(response.data.message));
    }
  } else {
    yield put(Actions.addDocumentError(response.data.message));
  }
}

function* uploadVideo(actions) {
  const response = yield call(uploadVideoAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.uploadVideoSuccess(response.data.message));
    } 
    else {
      yield put(Actions.uploadVideoError(response.data.message));
    }
  } else {
    yield put(Actions.uploadVideoError(response.data.message));
  }
}

function* getFoldersList(actions) {
  const response = yield call(getFoldersListAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.getFolderListSuccess(response.data.message,response.data?.data?.folders,response.data?.data?.files,response.data?.data?.breadCrumbs,response.data?.data?.foldersCount,response.data?.data?.filesCount));
    } 
    else {
      yield put(Actions.getFolderListError(response.data.message));
    }
  } else {
    yield put(Actions.getFolderListError(response.data.message));
  }
}

function* getFileToDownload(actions) {
  const response = yield call(getFileToDownloadAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(Actions.downloadFileSuccess(response.data.message,response.data.url));
    } 
    else {
      yield put(Actions.downloadFileError(response.data.message));
    }
  } else {
    yield put(Actions.downloadFileError(response.data.message));
  }
}
function* getApiKey(actions) {
  const response = yield call(getApiKeyAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.getApiKeySuccess(
          response.data?.message,
          response.data?.data
        )
      );
    } else {
      yield put(Actions.getApiKeyError(response.data.error));
    }
  } else {
    yield put(Actions.getApiKeyError(response.data.error));
  }
}

function* generateApiKey(actions) {
  const response = yield call(generateApiKeyAPI, actions.data);
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.generateApiKeySuccess(
          response.data?.message,
          response.data?.data
        )
      );
    } else {
      yield put(Actions.generateApiKeyError(response.data.error));
    }
  } else {
    yield put(Actions.generateApiKeyError(response.data.error));
  }
}


export default function* rootSaga() {
  yield takeLatest('GET_SLOTS_REQUEST', getSlots);
  yield takeLatest('UPDATE_SLOTS_REQUEST', updateSlots);
  yield takeLatest('LIST_ALL_USER_REQUEST', listAllUser);
  yield takeLatest('ALL_USER_LIST_REQUEST', allUserList);
  yield takeLatest('ASSIGN_MANAGER_REQUEST', assignManager);
  yield takeLatest('UPDATE_AUDIENCE_CUSTOM_FIELDS_REQUEST', updateAudienceCustomFields);
  yield takeLatest('DELETE_AUDIENCE_CUSTOM_FIELDS_REQUEST', deleteAudienceCustomFields);
  yield takeLatest('LIST_AUDIENCE_CUSTOM_FIELDS_REQUEST', listAudienceCustomFields);
  yield takeLatest('ADD_AUDIENCE_CUSTOM_FIELDS_REQUEST', addAudienceCustomFields);
  yield takeLatest(
    'ATTENDEE_GROUPS_FOR_MEETING_REQUEST',
    attendeeGroupsForMeeting,
  )
  yield takeLatest('AUDIENCE_FOR_MEETING_REQUEST',audienceForMeeting)
  yield takeLatest('SEARCH_FOR_MEETING_REQUEST',searchForMeeting)
  yield takeLatest('GET_AUDIENCE_CONTACT_REQUEST',getAudienceContact)
  yield takeLatest('GET_MEETING_TEMPLATE_REQUEST',getMeetingTemplate)
  yield takeLatest('CHECK_MEETING_TITLE_REQUEST',checkMeetingTitle)
  yield takeLatest('CHECK_DISABLED_ATTENDEE_REQUEST',checkDisabledAttendee)

  yield takeLatest('GET_EXCLUDE_COUNT_REQUEST', getExcludeCount);
  yield takeLatest('GET_EXCLUDE_ATTENDEE_COUNT_REQUEST', getExcludeAttendeeCount);
  yield takeLatest('GET_CUSTOM_FIELDS_PROFILE_REQUEST', getCustomFieldsProfile);
  yield takeLatest(
    'ASSIGN_ACCESS_CODE_PROFILE_REQUEST',
    assignAccessCodeProfile,
  );
  yield takeLatest('REJECT_CONTACT_LIST_REQUEST', rejectContactListRequest);
  yield takeLatest('GET_ACCESS_CODE_REQUEST', getAccessCode);
  yield takeLatest('UN_USED_ACCESS_CODE_REQUEST', getUnusedAccessCode);
  yield takeLatest('ASSIGN_ACCESS_CODE_REQUEST', assignAccessCode);
  yield takeLatest('REPLICATE_MEETING_REQUEST', replicateMeeting);
  yield takeLatest(
    'LIST_ATTENDEES_NOT_ADDED_TO_MEETING_REQUEST',
    listAttendeesOfNotAddedToMeeting,
  );
  yield takeLatest('UPDATE_MEETING_REQUEST', updateMeeting);
  yield takeLatest('DELETE_MEETING_REQUEST', deleteMeeting);
  yield takeLatest('ADD_MEETING_REQUEST', addMeeting);
  yield takeLatest('LIST_MEETING_REQUEST', listMeeting);
  yield takeLatest('IMPORT_ACCESS_CODE_REQUEST', importAccessCode);
  yield takeLatest('CHANGE_ACCESS_CODE_REQUEST', changeAccessCode);
  yield takeLatest('DELETE_ACCESS_CODE_REQUEST', deleteAccessCode);
  yield takeLatest('EDIT_ACCESS_CODE_REQUEST', editAccessCode);
  yield takeLatest('ADD_ACCESS_CODE_REQUEST', addAccessCode);
  yield takeLatest('LIST_ACCESS_CODE_REQUEST', listAccessCode);
  yield takeLatest('EXPORT_ACCESS_CODE_REQUEST', exportAccesCode);
  yield takeLatest('CREATE_GROUP_REQUEST', createGroupOnly);
  yield takeLatest('GET_REPORT_CHALLENGES_REQUEST', getReportChallenges);
  yield takeLatest('MY_PROFILE_RANK_REQUEST', getMyProfileRank);
  yield takeLatest('CHALLENGES_ENGAGED_USERS_REQUEST', challengesEngagedUsers);
  yield takeLatest('LEADERBOARD_USER_HISTORY_REQUEST', leaderboardEngagedUsers);
  yield takeLatest('CHECK_WEBHOOK_EVENT_ID_REQUEST', checkWebhookEventId);
  yield takeLatest('GENERATE_SECRET_KEY_REQUEST', generateSecretKeyForWebhook);
  yield takeLatest('TOGGLE_WEBHOOK_REQUEST', toggleWebhook);
  yield takeLatest('LIST_WEBHOOKS_REQUEST', listWebhooks);
  yield takeLatest('DELETE_PROJECT_REQUEST', deleteProject);
  yield takeLatest('REPLICATE_PROJECT_REQUEST', replicateProject);
  yield takeLatest('REPLICATE_PAGE_REQUEST', replicatePageRequest);
  yield takeLatest('CHALLENGE_STATUS_REQUEST', changeChallengeStatus);
  yield takeLatest('DELETE_CONFIGRATION_CHALLENGES_REQUEST', deleteChallenge);
  yield takeLatest(
    'ADD_POINTS_GAMIFICATION_LEADER_BOARDS_REQUEST',
    addPointsGamificationLeaderBoards,
  );
  yield takeLatest('UPDATE_CONFIGRATION_CHALLENGES_REQUEST', updateChallenge);
  yield takeLatest('ADD_CONFIGRATION_CHALLENGES_REQUEST', addChallenges);
  yield takeLatest('CONFIGRATION_CHALLENGES_REQUEST', configrationChallenges);
  yield takeLatest(
    'GET_GAMIFICATION_CHALLENGES_REQUEST',
    getGamificationChallenges,
  );
  yield takeLatest(
    'LIST_GAMIFICATION_LEADER_BOARDS_REQUEST',
    listGamificationLeaderBoards,
  );
  yield takeLatest(
    'UPDATE_GAMIFICATION_GRADES_REQUEST',
    updateGamificationGrades,
  );
  yield takeLatest('LIST_GAMIFICATION_GRADES_REQUEST', listGamificationGrades);
  yield takeLatest('SEND_TEST_EMAIL_REQUEST', sendTestEmail);
  yield takeLatest(
    'LIST_ATTENDEES_NOT_ADDED_TO_CAMPAIGN_REQUEST',
    listAttendeesNotAddedToCampaign,
  );
  yield takeLatest('LIST_AUDIENCE_GROUPS_REQUEST', listCampaignAudienceGroups);
  yield takeLatest(
    'LIST_ATTENDEES_OF_CAMPAIGNS_REQUEST',
    listAttendeesOfCampaigns,
  );
  yield takeLatest('DELETE_CAMPAIGN_REQUEST', deleteCampaign);
  yield takeLatest('GET_CAMPAIGN_REQUEST', getCampaign);
  yield takeLatest('UPDATE_CAMPAIGN_REQUEST', updateCampaign);
  yield takeLatest('CREATE_CAMPAIGN_REQUEST', createCampaign);
  yield takeLatest('LIST_CAMPAIGN_TEMPLATES_REQUEST', listCampaignTemplates);
  yield takeLatest('CHECK_CAMPAIGN_REQUEST', checkCampaignRequest);
  yield takeLatest('LIST_CAMPAIGNS_REQUEST', listCampaignsRequest);
  yield takeLatest('LIST_ALL_CAMPAIGNS_REQUEST', listAllCampaignsRequest);
  yield takeLatest(
    'LIST_TRANSACTIONAL_EMAIL_TEMPLATES_REQUEST',
    listTransactionalEmailTemplates,
  );
  yield takeLatest(
    'LIST_ALL_TRANSACTIONAL_EMAIL_TEMPLATES_REQUEST',
    listAllTransactionalEmailTemplates,
  );

  yield takeLatest('LIST_BADGES_TEMPLATE_REQUEST', listBadgesTemplate);
  yield takeLatest(
    'LIST_ALL_TRANSACTIONAL_EMAIL_TEMPLATES_REQUEST',
    listAllTransactionalEmailTemplates,
  );
  yield takeLatest('ACTION_CHECKIN_REQUEST', checkinAttendeeStatus);
  yield takeLatest('ACTION_ASSIGN_REQUEST', actionAssignGroups);
  yield takeLatest('EDIT_ATTENDEES_GROUP_NAME_REQUEST', editAttendeesGroupName);
  yield takeLatest('SEARCH_ATTENDEES_GROUPS_REQUEST', searchAttendeesGroups);
  yield takeLatest(
    'SEARCH_ATTENDEES_OF_UNASSIGN_GROUP_REQUEST',
    searchAttendeesOfUnassignGroup,
  );
  yield takeLatest('SEARCH_ATTENDEES_OF_GROUP_REQUEST', searchAttendeesOfGroup);
  yield takeLatest('ASSIGN_ATTENDEES_REQUEST', assignAttendeesFromGroup);
  yield takeLatest('UNASSIGN_ATTENDEES_REQUEST', unassignAttendeesFromGroup);
  yield takeLatest('LIST_ASSIGNED_ATTENDEES_REQUEST', listAssignedAttendees);
  yield takeLatest('LIST_UNASSIGNED_ATTENDEES_REQUEST', listUnassignedAttendees);
  yield takeLatest('SORT_ATTENDEES_REQUEST', sortAttendees);
  yield takeLatest('DELETE_ATTENDEES_GROUPS_REQUEST', deleteAttendeesGroups);
  yield takeLatest('ADD_ATTENDEES_GROUPS_REQUEST', addAttendeesGroups);
  yield takeLatest('LIST_ATTENDEES_GROUPS_REQUEST', listAttendeesGroups);
  yield takeLatest('ENABLED_STATUS_REQUEST', enableAttendeeStatus);
  yield takeLatest('EDIT_ATTENDEE_REQUEST', editAttendee);
  yield takeLatest(
    'CHECKED_IN_HISTORY_ATTENDEE_REQUEST',
    checkedInHistoryAttendee,
  );
  yield takeLatest('DELETE_ATTENDEES_REQUEST', deleteAttendee);
  yield takeLatest('ADD_ATTENDEES_REQUEST', addAttendees);
  yield takeLatest('LIST_ATTENDEES_REQUEST', listAttendees);
  yield takeLatest('SEARCH_ATTENDEES_REQUEST', searchAttendees);
  yield takeLatest('SORT_FILTER_ATTENDEES_REQUEST', sortFilterAttendees);
  yield takeLatest('UPDATE_CUSTOM_FIELD_REQUEST', updateCustomField);
  yield takeLatest('DELETE_CUSTOM_FIELDS_REQUEST', deleteCustomFields);
  yield takeLatest('ADD_CUSTOM_FIELDS_REQUEST', addCustomFields);
  yield takeLatest('CUSTOM_FIELDS_REQUEST', customFieldsList);
  yield takeLatest(
    'SEARCH_PROJECT_NOTIFICATION_REQUEST',
    searchProjectNotification,
  );
  yield takeLatest(
    'UPDATE_PROJECT_NOTIFICATION_REQUEST',
    updateProjectNotification,
  );
  yield takeLatest(
    'DELETE_PROJECT_NOTIFICATION_REQUEST',
    deleteProjectNotification,
  );
  yield takeLatest('PROJECT_NOTIFICATION_REQUEST', createProjectNotification);
  yield takeLatest(
    'LIST_PROJECT_NOTIFICATIONS_REQUEST',
    listProjectNotifications,
  );
  yield takeLatest('LOGIN_REQUEST', login);
  yield takeLatest('AUTO_LOGIN_REQUEST', autoLogin);
  yield takeLatest('ADMIN_AUTO_LOGIN_REQUEST', adminAutoLogin);
  yield takeLatest('PROFILE_REQUEST', updateProfile);
  yield takeLatest('USER_REGISTER_REQUEST', userRegister);
  yield takeLatest('EMAIL_VERIFY_REQUEST', emailVerifyEmail);
  yield takeLatest('EMAIL_VERIFIED_REQUEST', emailVerifiedEmail);
  yield takeLatest('DELETE_ACCOUNT_REQUEST', deleteAccount);
  yield takeLatest('FORGOT_PASSWORD_REQUEST', forgotPassword);
  yield takeLatest('RESET_PASSWORD_REQUEST', resetPassword);
  yield takeLatest('EVENTS_REQUEST', createEvent);
  yield takeLatest('LIST_EVENTS_REQUEST', listEvent);
  yield takeLatest('LIST_SINGLE_EVENT_REQUEST', listSingleEvent);
  yield takeLatest('GET_PROJECT_REQUEST', getProjectRequest);
  yield takeLatest('CONTACT_REQUEST', createContact);
  yield takeLatest('LIST_CONTACT_REQUEST', listContact);
  yield takeLatest('LIST_ALL_CONTACT_REQUEST', listAllContact);
  yield takeLatest('GROUP_CONTACT_REQUEST', createGroupContact);
  yield takeLatest('IMPORT_CONTACT_REQUEST', importContact);
  yield takeLatest('UPDATE_CONTACT_REQUEST', updateContact);
  yield takeLatest('DELETE_CONTACT_REQUEST', deleteContact);
  yield takeLatest('DELETE_MANY_CONTACT_REQUEST', deleteManyContact);
  yield takeLatest('ASSIGN_GROUP_REQUEST', assignGroup);
  yield takeLatest('SEARCH_CONTACT_REQUEST', searchContact);
  yield takeLatest('CSV_REQUEST', csvContact);
  yield takeLatest('XSL_REQUEST', xslContact);
  yield takeLatest('GROUP_REQUEST', listGroup);
  yield takeLatest('UPDATE_GROUP_REQUEST', updateGroup);
  yield takeLatest('DELETE_GROUP_REQUEST', deleteGroup);
  yield takeLatest('SEARCH_GROUP_REQUEST', searchGroup);
  yield takeLatest('MEMBER_REQUEST', addMember);
  yield takeLatest('SEARCH_MEMBER_REQUEST', searchMember);
  yield takeLatest('LIST_MEMBER_REQUEST', listMember);
  yield takeLatest('LIST_PROJECT_USERS_REQUEST', listProjectUsers);
  yield takeLatest('ADD_PROJECT_USERS_REQUEST', addProjectUsers);
  yield takeLatest('GET_USERS_LIST_REQUEST', getUsersList);
  yield takeLatest('EDIT_PROJECT_NOTIFICATION_REQUEST', editProjectNotification);
  yield takeLatest('UPDATE_MEMBER_REQUEST', updateMember);
  yield takeLatest('DELETE_MEMBER_REQUEST', deleteMember);
  yield takeLatest('INVITATION_REQUEST', invitationMember);
  yield takeLatest('EVENT_EMAIL_REQUEST', eventEmail);
  yield takeLatest('UPDATE_EVENTS_REQUEST', updateEvent);
  yield takeLatest('UPDATE_PROJECT_REQUEST', updateProjectRequest);
  yield takeLatest('SEARCH_EVENTS_REQUEST', searchEvent);
  yield takeLatest('CSV_EVENTS_REQUEST', csvEvent);
  yield takeLatest('EMAIL_REPORTS_REQUEST', emailReports);
  yield takeLatest(
    'EMAIL_REGISTRATION_DATA_REPORTS_REQUEST',
    emailRegistrationReports,
  );
  yield takeLatest('EMAIL_DATA_REPORTS_REQUEST', emailDataReports);
  yield takeLatest('LIST_NOTI_REQUEST', listNoti);
  yield takeLatest('UPDATE_NOTI_REQUEST', updateNoti);
  yield takeLatest('CHECK_EVENT_DOMAIN_REQUEST', checkEventDomain);
  yield takeLatest('PROUTE_REQUEST', proute);
  yield takeLatest('SEARCH_USER_REQUEST', searchUser);
  yield takeLatest('DELETE_USER_REQUEST', deleteUser);
  yield takeLatest('INVITE_USER_REQUEST', inviteUser);
  yield takeLatest('EDIT_USER_PERMISSION_REQUEST', editUserPermission);
  yield takeLatest('CHANGE_USER_ROLE_REQUEST', changeUserRole);
  yield takeLatest('CHANGE_USER_STATUS_REQUEST', changeUserStatus);
  yield takeLatest('GET_ROLES_REQUEST', getRoles);
  yield takeLatest('PERMISSION_REQUEST', getPermissionLists);
  yield takeLatest('CREATE_ROLE_REQUEST', createRole);
  yield takeLatest('EDIT_ROLE_REQUEST', editRole);
  yield takeLatest('DELETE_ROLE_REQUEST', deleteRole);
  yield takeLatest('LIST_AGENDA_REQUEST', listAgenda);

  yield takeLatest('IMPORT_ATTENDEES_REQUEST', importAttendees);
  yield takeLatest('DOWNLOAD_CSV_REQUEST', downloadCsv);
  yield takeLatest('DELETE_AGENDA_REQUEST', deleteAgenda);
  yield takeLatest('EXPORT_ATTENDEES_REQUEST', exportAttendees);
  yield takeLatest('EXPORT_CHECK_IN_HISTORY_REQUEST', exportCheckInHistory);
  yield takeLatest('ADD_AGENDA_REQUEST', addAgenda);
  yield takeLatest('EDIT_AGENDA_REQUEST', editAgenda);
  yield takeLatest('SEARCH_AGENDA_REQUEST', searchAgenda);

  yield takeLatest('GET_REPORT_REQUEST', getReport);
  yield takeLatest('GROUP_CHECK_REQUEST', groupCheckRequest);
  yield takeLatest('GET_PUBLISHED_EVENTS_REQUEST', getPublishedEvents);

  yield takeLatest('GET_ATTENDEES_CHART_DATA_REQUEST', getAttendeesChartData);
  yield takeLatest('GET_EVENT_SUMMARY_DETAILS_REQUEST', getEventSummaryDetails);
  yield takeLatest('REPLICATE_AGENDA_REQUEST', replicateAgenda);
  yield takeLatest(
    'REPLICATE_PROJECT_NOTIFICATION_REQUEST',
    replicateNotification,
  );
  yield takeLatest(
    'FIND_IF_ALLOWED_FROM_SUPERADMIN_REQUEST',
    findIfAllowedFromSuperadmin,
  );
  yield takeLatest(
    'FIND_IF_ALLOWED_FROM_SUPERADMIN_OUTER_REQUEST',
    findIfAllowedFromSuperadminOuter,
  );
  yield takeLatest('GET_BILLING_DATA_REQUEST', getBillingData);
  yield takeLatest('GET_PROJECTS_BILLING_DATA_REQUEST', getProjectsBillingData);
  //#region  Integrations
  yield takeLatest('TAWK_TO_GET_PROPERTIES', tawkToSaga.getProperties);
  yield takeLatest('TAWK_TO_CREATE_PROPERTY', tawkToSaga.createProperty);
  yield takeLatest('TAWK_TO_GET_WIDGET', tawkToSaga.getWidget);
  yield takeLatest('TAWK_TO_UPDATE_WIDGET', tawkToSaga.updateWidget);

  yield takeLatest('GET_APP_INTEGRATIONS', integrationSaga.getAppIntegrations);
  yield takeLatest('CONNECT_GOOGLE_WORKSPACE', integrationSaga.connectGoogleWorkspace);
  //#endregion

  yield takeLatest(
    'CREATE_VANITY_DOMAIN_REQUEST',
    vanityDomainSaga.createVanityDomain,
  );
  yield takeLatest(
    'REMOVE_VANITY_DOMAIN_REQUEST',
    vanityDomainSaga.removeVanityDomain,
  );

  yield takeLatest('REPLICATE_CAMPAIGN_REQUEST', replicateCampaign);

  yield takeLatest('GET_USER_DETAILS_REQUEST', getUserDetails);
  yield takeLatest('GOOGLE_WORKSPACE_GET_WIDGET_REQUEST', googleWorkspaceGetWidget);
  yield takeLatest('GOOGLE_WORKSPACE_GET_LOGS_REQUEST', getGoogleWorkspaceLogs);
  yield takeLatest('GET_GROUPS_FOR_GOOGLE_WORKSPACE_REQUEST', getGroupsForGoogleWorkspace);
  yield takeLatest('UPDATE_GOOGLE_MANAGEMENT_LIST_REQUEST', updateGoogleManagementList);
  yield takeLatest('UPDATE_GOOGLE_WORKSPACE_CUSTOM_FIELDS_REQUEST', updateGoogleWorkspaceCustomFields);
  yield takeLatest('GET_ORG_UNITS_MANUALLY', integrationSaga.getOrgUnitsManually);
  yield takeLatest('GET_GOOGLE_ORG_UNITS', integrationSaga.getGoogleOrgUnits);
  yield takeLatest("SAVE_GOOGLE_WORKSPACE_INFO", integrationSaga.saveGoogleWorksapceInfo);
  yield takeLatest('UPDATE_GOOGLE_WORKSPACE_SYNC_MODE_REQUEST', updateGoogleWorkspaceSyncMode);
  yield takeLatest('GET_SYNC_LOGS_HEALTH_STATUS_REQUEST', getSyncLogsHealthStatus);
  yield takeLatest('UPDATE_GROUPS_FROM_GOOGLE_WORKSPACE_REQUEST', updateGroupsFromGoogleWorkspace);
  yield takeLatest('SYNC_USERS', integrationSaga.syncUsers);
  yield takeLatest('GET_GOOGLE_USERS', integrationSaga.getGoogleUsers);
  yield takeLatest('AUTHORIZE_GOOGLE_WORKSPACE', integrationSaga.authorizeGoogleWorkspace);
  yield takeLatest('CONNECT_GOOGLE_SIGN_IN_REQUEST', connectGoogleSignInRequest);
  yield takeLatest('CHECK_WHETHER_GROUP_NAME_EXIST_IN_ILLUMEET_REQUEST', checkWhetherGroupNameExistInIllumeet);
  yield takeLatest('CHECK_GOOGLE_SIGN_IN_VALID_TOKEN_REQUEST', checkGoogleSignInValidToken);

  yield takeLatest('CHANGE_LANG_FOR_USER_REQUEST', changeLanguageForUser);
  yield takeLatest('LIST_ALL_MEETINGS_REQUEST', listAllMeetingsRequest);
  yield takeLatest('GET_AI_AGENT_GENERAL_DATA_REQUEST', aiChatbotSaga.getAiAgentGeneralData);
  yield takeLatest('CREATE_OR_UPDATE_AI_AGENT_GENERAL_DATA_REQUEST', aiChatbotSaga.createOrUpdateAiAgentGeneralData);
  yield takeLatest('LIST_ALL_CHATBOTS_REQUEST', aiChatbotSaga.listChatbots);
  yield takeLatest('TRAIN_AGENT_REQUEST', aiChatbotSaga.trainAgent);
  yield takeLatest('DELETE_CHATBOT_REQUEST', aiChatbotSaga.deleteChatbot);
  yield takeLatest('ENABLE_CHATBOT_REQUEST', aiChatbotSaga.enableChatbot);
  yield takeLatest('SET_CHATBOT_AS_DEFAULT_REQUEST', aiChatbotSaga.setChatbotAsDefault);
  yield takeLatest('SAVE_SETTINGS_FOR_AI_CHATBOT_REQUEST', aiChatbotSaga.saveSettingsForAiChatbot);
  yield takeLatest('REPLICATE_CHAT_WIDGET_REQUEST', aiChatbotSaga.replicateChatWidget);
  yield takeLatest('ENABLE_DISABLE_APPLICATION_INTEGRATION_REQUEST', applicationsSaga.enableDisableApplicationIntegrationRequest);
  yield takeLatest('CHECK_APPLICATION_INTEGRATION_ENABLED_REQUEST', applicationsSaga.checkApplicationIntegrationEnabled);
  
  yield takeLatest('FETCH_ALL_OPPORTUNITY_ACCOUNTS_REQUEST', opportunityAccountsSaga.fetchAllOpportunityAccounts);
  yield takeLatest('FETCH_OPPORTUNITY_ACCOUNT_REQUEST', opportunityAccountsSaga.fetchOpportunityAccount);
  yield takeLatest('CREATE_OPPORTUNITY_ACCOUNT_REQUEST', opportunityAccountsSaga.createOpportunityAccount);
  yield takeLatest('UPDATE_OPPORTUNITY_ACCOUNT_REQUEST', opportunityAccountsSaga.updateOpportunityAccount);
  yield takeLatest('DELETE_OPPORTUNITY_ACCOUNT_REQUEST', opportunityAccountsSaga.deleteOpportunityAccount);
  yield takeLatest('CREATE_OPPORTUNITY_CUSTOM_FIELD_REQUEST', opportunityCustomFieldsSaga.createOpportunityCustomField);
  yield takeLatest('DELETE_OPPORTUNITY_CUSTOM_FIELD_REQUEST', opportunityCustomFieldsSaga.deleteOpportunityCustomField);
  yield takeLatest('UPDATE_OPPORTUNITY_CUSTOM_FIELD_REQUEST', opportunityCustomFieldsSaga.updateOpportunityCustomField);
  yield takeLatest('FETCH_ALL_OPPORTUNITY_CUSTOM_FIELD_REQUEST', opportunityCustomFieldsSaga.fetchAllOpportunityCustomField);

  yield takeLatest('CREATE_OPPORTUNITY_STATE_REQUEST', opportunityStatesSaga.createOpportunityState);
  yield takeLatest('DELETE_OPPORTUNITY_STATE_REQUEST', opportunityStatesSaga.deleteOpportunityState);
  yield takeLatest('UPDATE_OPPORTUNITY_STATE_REQUEST', opportunityStatesSaga.updateOpportunityState);
  yield takeLatest('FETCH_ALL_OPPORTUNITY_STATE_REQUEST', opportunityStatesSaga.fetchAllOpportunityStates);
  yield takeLatest('UPDATE_OPPORTUNITY_STATES_ORDER_REQUEST', opportunityStatesSaga.updateOpportunityStatesOrder);

  yield takeLatest('CREATE_COMMENT_AND_ATTACHMENT_REQUEST', opportunityCommonSaga.createCommentAndAttachment);
  yield takeLatest('UPLOAD_ATTACHMENT_TO_CLOUD_REQUEST', opportunityCommonSaga.uploadAttachmentToCloud);
  yield takeLatest('DELETE_COMMENT_AND_ATTACHMENT_REQUEST', opportunityCommonSaga.deleteCommentAndAttachment);
  yield takeLatest('UPDATE_COMMENT_AND_ATTACHMENT_REQUEST', opportunityCommonSaga.updateCommentAndAttachment);
  yield takeLatest('FETCH_COMMENT_AND_ATTACHMENT_REQUEST', opportunityCommonSaga.fetchCommentAndAttachment);
  yield takeLatest('REMOVE_ATTACHMENT_REQUEST', opportunityCommonSaga.removeAttachment);

  yield takeLatest('FETCH_ALL_OPPORTUNITY_OPPORTUNITIES_REQUEST', opportunityOpportunitiesSaga.fetchAllOpportunityOpportunities);
  yield takeLatest('FETCH_OPPORTUNITY_OPPORTUNITY_REQUEST', opportunityOpportunitiesSaga.fetchOpportunityOpportunity);
  yield takeLatest('CREATE_OPPORTUNITY_OPPORTUNITY_REQUEST', opportunityOpportunitiesSaga.createOpportunityOpportunity);
  yield takeLatest('UPDATE_OPPORTUNITY_OPPORTUNITY_REQUEST', opportunityOpportunitiesSaga.updateOpportunityOpportunity);
  yield takeLatest('DELETE_OPPORTUNITY_OPPORTUNITY_REQUEST', opportunityOpportunitiesSaga.deleteOpportunityOpportunity);
  yield takeLatest('UPDATE_MULTIPLE_OPPORTUNITY_OPPORTUNITIES_REQUEST', opportunityOpportunitiesSaga.updateMultipleOpportunityOpportunities);
  yield takeLatest('GET_USERS_BY_ACCEPT_ST_REQUEST', getUsersByAcceptSt);
  yield takeLatest('GET_ACTIVE_USERS_FOR_COMPANY_REQUEST', getActiveUsersForCompany);
  yield takeLatest('CREATE_FOLDER_REQUEST', createFolderForFiles);
  yield takeLatest('ADD_IMAGES_REQUEST', addImages);
  yield takeLatest('ADD_DOCUMENT_REQUEST', addDocuments);
  yield takeLatest('UPLOAD_VIDEO_REQUEST',uploadVideo);
  yield takeLatest('GET_FOLDER_LIST_REQUEST', getFoldersList);
  yield takeLatest('DOWNLOAD_FILE_REQUEST', getFileToDownload);
  yield takeLatest('RENAME_FOLDER_REQUEST', renameFolder);
  yield takeLatest('DELETE_FOLDER_REQUEST', deleteFolderById);
  yield takeLatest('FETCH_ALL_OPPORTUNITY_CONTACTS_REQUEST', opportunityContactsSaga.fetchAllOpportunityContacts);
  yield takeLatest('FETCH_OPPORTUNITY_CONTACT_REQUEST', opportunityContactsSaga.fetchOpportunityContact);
  yield takeLatest('CREATE_OPPORTUNITY_CONTACT_REQUEST', opportunityContactsSaga.createOpportunityContact);
  yield takeLatest('UPDATE_OPPORTUNITY_CONTACT_REQUEST', opportunityContactsSaga.updateOpportunityContact);
  yield takeLatest('DELETE_OPPORTUNITY_CONTACT_REQUEST', opportunityContactsSaga.deleteOpportunityContact);
  yield takeLatest('UPDATE_MULTIPLE_OPPORTUNITY_CONTACTS_REQUEST', opportunityContactsSaga.updateMultipleOpportunityContacts);
  yield takeLatest('GET_API_KEY_REQUEST', getApiKey);
  yield takeLatest('GENERATE_API_KEY_REQUEST', generateApiKey);
  yield takeLatest('LIST_TRANSACTION_EMAIL_REQUEST', opportunityOpportunitiesSaga.listTransactionEmail);
  yield takeLatest('UPDATE_TRANSACTION_EMAIL_REQUEST', opportunityOpportunitiesSaga.updateTransactionEmail);
  yield takeLatest('FETCH_ALL_OPPORTUNITY_AUDIENCE_REQUEST', opportunityAudienceSaga.fetchAllOpportunityAudience);
  yield takeLatest('DELETE_OPPORTUNITY_AUDIENCE_REQUEST', opportunityAudienceSaga.deleteOpportunityAudience);
  yield takeLatest('EDIT_OPPORTUNITY_AUDIENCE_REQUEST', opportunityAudienceSaga.editOpportunityAudience);
  yield takeLatest('CREATE_OPPORTUNITY_AUDIENCE_REQUEST', opportunityAudienceSaga.createOpportunityAudience);
  yield takeLatest('ASSIGN_OR_UNASSIGN_OPPORTUNITY_CONTACTS_REQUEST', opportunityContactsSaga.assignOrUnassignOpportunityContacts);
  yield takeLatest('ASSIGN_CONTACT_LIST_REQUEST', opportunityAudienceSaga.assignContactList);
  yield takeLatest('GET_ALL_OPPORTUNITY_GROUPS_REQUEST', opportunityAudienceSaga.getAllOpportunityGroups);
  yield takeLatest('GET_SMART_GROUPS_CONTACTS_REQUEST', opportunityContactsSaga.getSmartGroupsContacts);
  yield takeLatest('ADD_UPDATE_INCLUDE_SUB_GROUP_CONTACT_REQUEST', opportunityContactsSaga.addUpdateIncludeSubGroupContact);
}
