import BaseAPI from '../baseAPI';


class OpportunityContactsAPI extends BaseAPI {
    fetchAllOpportunityContacts = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/fetchAllContacts', {
            method: 'POST',
            params: queryParams,
            data: bodyParams
        });
    };

    fetchOpportunityContact = async (params: object,) => {
        return this.API('opportunityManagement/contacts/fetchById', {
            method: 'POST',
            params,
        });
    };

    createOpportunityContact = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        const res = await this.API('opportunityManagement/contacts/createContact', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
        return res
    };
    
    createContactForAccountTab = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        const res = await this.API('opportunityManagement/contacts/createContactForAccountTab', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
        return res
    };

    updateOpportunityContact = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/updateContact', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };
    
    updateContactForAccountTab = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/updateContactForAccountTab', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };

    deleteOpportunityContact = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/delete', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };
    updateMultipleOpportunityContacts = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/updateMultiple', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };
    
    fetchArrayOfAccountAndContactDataType = async (params: object) => {
        return this.API('opportunityManagement/contacts/fetchArrayOfAccountAndContactDataType', {
            method: 'GET',
            params,
        });
    };
    
    updateArrayOfAccountAndContactDataType = async (
        bodyParams: any
    ) => {
        return this.API('opportunityManagement/contacts/updateArrayOfAccountAndContactDataType', {
            method: 'POST',
            data: bodyParams,
        });
    };

    export = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/export', {
            method: 'POST',
            params: queryParams,
            data: bodyParams
        });
    };

    downloadSampleCSVFile = async () => {
        return this.API('opportunityManagement/contacts/downloadSampleCSVFile', {
            method: 'POST',
        });
    };

    importCsvDataAfterValidationIsDone = async (queryParams: {
        skipExisting: boolean,
        keyField: string
    }) => {
        return this.API('opportunityManagement/contacts/importCsvDataAfterValidationIsDone', {
            method: 'POST',
            params: queryParams
        });
    }
   
    checkValidationForCsvImportFile = async (data: FormData, queryParams : {
        keyField: string
      }) => {
        return this.API('opportunityManagement/contacts/checkValidationForCsvImportFile', {
            method: 'POST',
            data: data,
            params: queryParams
        });
    }
    
    assignOrUnassignOpportunityContacts = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/assignOrUnassignContacts', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };

    checkIfNameAlreadyExists = async (data: object) => {
        return this.API('/opportunityManagement/contacts/checkIfNameAlreadyExists', {
            method: 'POST',
            data: data
        });
    }

    getSmartGroupsContacts = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/contacts/getSmartGroupsContacts', {
            method: 'POST',
            params: queryParams,
            data: bodyParams
        });
    };

    addUpdateIncludeSubGroupContact = async (
        bodyParams: any
    ) => {
        return this.API('audience/addUpdateIncludeSubGroupContact', {
            method: 'POST',
            data: bodyParams,
        });
    };
}



export default OpportunityContactsAPI;