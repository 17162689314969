import React, { createContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface OpportunityAccountsContextProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  pageNo: number;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  sortOrder: 'asc' | 'desc';
  setSortOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  filterByCustomField: string[];
  setFilterByCustomField: React.Dispatch<React.SetStateAction<string[]>>;
  filterByStatus: string[];
  setFilterByStatus: React.Dispatch<React.SetStateAction<string[]>>;
  filterByLastImported: boolean;
  setFilterByLastImported: React.Dispatch<React.SetStateAction<boolean>>;
  filterBySource: string[];
  setFilterBySource: React.Dispatch<React.SetStateAction<string[]>>;
}

// Create the context
export const OpportunityAccountsContext = createContext<OpportunityAccountsContextProps | undefined>(undefined);

// Create the provider component
export const OpportunityAccountsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const [filterByCustomField, setFilterByCustomField] = useState<string[]>([]);
  const [filterByStatus, setFilterByStatus] = useState<string[]>([]);
  const [filterByLastImported, setFilterByLastImported] = useState<boolean>(false);
  const [filterBySource, setFilterBySource] = useState<string[]>([]);

  return (
    <OpportunityAccountsContext.Provider
      value={{
        searchText,
        setSearchText,
        pageNo,
        setPageNo,
        limit,
        setLimit,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        filterByCustomField,
        setFilterByCustomField,
        filterByStatus,
        setFilterByStatus,
        filterByLastImported,
        setFilterByLastImported,
        filterBySource,
        setFilterBySource,
      }}
    >
      {children}
    </OpportunityAccountsContext.Provider>
  );
};
