import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../theme/theme';
import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { AudienceChild, AudienceState } from 'Redux/Reducers/opportunity/audience.reducer';
import { FetchAudienceQueryParams, AudienceValidationError } from './ContactGroup';
import ImportIcon from '../../../assets/images/icons/importIcon.svg';
import CopyIcon from '../../../assets/images/icons/copyIcon.svg';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

type Props = {
  openModal: boolean;
  editData?: AudienceChild;
  closeModalFunc: () => void;
  queryParams: FetchAudienceQueryParams;
  parentId?:string
};

const AddOrEditAudience: React.FC<Props> = ({
  queryParams,
  openModal,
  editData,
  closeModalFunc,
  parentId
}) => {
  const {
    createOpportunityAudienceSuccess, editOpportunityAudienceSuccess
  } = useSelector((state: any) => state.OpportunityAudience) as AudienceState;
  const [audienceName, setAudienceName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [errors, setErrors] = useState<AudienceValidationError>({});

  const dispatch = useDispatch();
  useEffect(() => {
    if (editData && Object.keys(editData).length > 0) {
      setIsEdit(true);
      setAudienceName(editData.audienceName);
      setDescription(editData?.description ? editData.description :'')
    } else {
      setIsEdit(false);
      setAudienceName('');
    }
  }, [editData]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAudienceName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    if (createOpportunityAudienceSuccess) {
      setAudienceName('');
      setIsEdit(false);
      setErrors({});
      closeModalFunc(); // Close modal after form submission
      setDescription('')
    }

  }, [createOpportunityAudienceSuccess]);

  useEffect(() => {
    if (editOpportunityAudienceSuccess) {
        closeModalFunc(); // Close modal after form submission
        setDescription('')
    }
}, [editOpportunityAudienceSuccess]);

  const createAudience = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isValidValue = isValidAudienc();

    if (isValidValue) {
      if (isEdit && editData?.id) {
        let data = {
          bodyParams: {
            audienceName: audienceName.trim(),
            description :description,
            audienceId: editData.id,
          },
          queryParams: queryParams,
        };
        dispatch(Actions.editOpportunityAudienceRequest(data));
      } else {
        let data = {
          bodyParams: {
            parentId:parentId ? parentId : null,
            audienceName: audienceName.trim(),
            description:description
          },
          queryParams: queryParams,
        };
        dispatch(Actions.createOpportunityAudienceRequest(data));
      }
    }
  };

  const closeModal = () => {
    closeModalFunc(); // Close modal without handling form submission
    setAudienceName(''); // Reset audienceName state
    setErrors({});
    setDescription('')
  };

  const isValidAudienc = () => {
    let error: AudienceValidationError = {};
    let isValid = true;

    if (audienceName === '' || audienceName.trim().length === 0) {
      isValid = false;
      error['audienceName'] = "Please enter audience name";
    }
    setErrors(error);
    return isValid;
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={openModal}
        onClose={closeModal}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component='form' onSubmit={createAudience} container className='modal-wrapper'>
          <Grid item xs={12} pb={4}>
            <Box component="p" mb={1} className='subtitle'>
              {isEdit ? 'Update Contact Group' : 'Add Contact Group'}
            </Box>
            <Box className='small-subtitle'>
              {!isEdit
                ? 'Assign a name to your contact group for better organization and targeting. Keep it concise and descriptive for future reference.'
                : 'Update the contact group name for improved clarity and targeting. Please keep it concise and descriptive.'}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" className='inputField' pb={4}>
              <label>
                Contact Group Name
                <span className='required'>*</span>
              </label>
              <input
                type="text"
                className="form-control small"
                name="audienceName"
                value={audienceName}
                onChange={handleNameChange}
                maxLength={30}
              />
              <span className="error_mesage required">
                {errors.audienceName}
              </span>
              {/* Optional: Display error message if needed */}
            </Box>
            <Box component="div" className="inputField" pb={4}>
              <label>Description</label>
              <textarea
                rows={4}
                className="form-control"
                value={description}
                name="description"
                onChange={handleDescriptionChange}
                placeholder="Please enter the description"
              ></textarea>
            </Box>  
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end">
              <Button type="submit" variant="contained" color="primary" disableElevation>
                SAVE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
};
export default AddOrEditAudience;
