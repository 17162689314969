import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import React, { ChangeEvent } from 'react';
import { Settings } from 'Redux/Reducers/aiChatbot.reducer';

import { TempratureType } from '../../SettingsDashboard';

type ModelProps = {
  state: Settings;
  handleChange: (
    tab: 'model' | 'opportunity' | 'chatInterface' | 'security',
    toChange: string,
    newVal: string | TempratureType,
    toChange2?: string,
  ) => void;
};
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};
export default function Model({ state, handleChange }: ModelProps) {
  const { model } = state;
  const handleAgeSliderChange = (
    event: ChangeEvent<{}>,
    value: number | number[],
  ) => {
    handleChange('model', 'temperature', value);
  };
  return (
    <>
      {/* <Box className="tab-panel" mb={2}>
        <Box className="tab-content">
          <Box className="tab-title">Training</Box>
          <Box>
            <Box className="paragraph bold" mb={2.5}>
              Last Trained Date
            </Box>
            <Box className="paragraph" sx={{ color: '#000' }}>
              <span>
                {model.lastTrainedAt
                  ? moment(model.lastTrainedAt).format('MMM, Do YYYY, h:mm A')
                  : '--'}
              </span>
            </Box>
          </Box>
        </Box>
      </Box> */}

      <Box className="tab-panel">
        <Box className="tab-content">
          <Stack
            className="tab-title"
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Box>Model</Box>
          </Stack>
          <FormControl size="small" className="inputField">
            <label>Select Model</label>
            <Select
              name="selectType"
              className="form-control select small"
              style={{ padding: '0px', marginBottom: '8px' }}
              value={model.model || ''}
              onChange={(e) => handleChange('model', 'model', e.target.value)}
              MenuProps={MenuProps}
              displayEmpty
            >
              <MenuItem value="gpt-4">GPT 4</MenuItem>
              <MenuItem value="gpt-4o">GPT 4o</MenuItem>
              <MenuItem value="mistral">Mistral</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <Box component="div" className="inputField" mb={2}>
              <label>Base Prompt (System message)</label>
              <textarea
                className="form-control small-text"
                placeholder="I want you to act as a support agent. Your name is 'AI Assistant'. "
                value={model.basePrompt}
                rows={4}
                onChange={(e) =>
                  handleChange('model', 'basePrompt', e.target.value)
                }
              />
              <Box mt={2} className="small-text">
                The base prompt allows you to customize your Chat Widget
                personality and style. Please make sure to experiment with the
                base prompt by making it very specific to your data and use
                case.
              </Box>
            </Box>

            <Box component="div" className="inputField" mb={2}>
              <label>Exit Strategy Prompt</label>
              <textarea
                className="form-control small-text"
                placeholder="I want to you to provide this information to the user in case a query is too specific or out of your knowledge base. 
For more details, you can reach out at 
Email: abc@gmail.com
Phone: 9090909090
Website:https://abc.xyz.com"
                value={model.fallbackSatrategy}
                rows={6}
                onChange={(e) =>
                  handleChange('model', 'fallbackSatrategy', e.target.value)
                }
              />
            </Box>
            <Box component="div" className="inputField">
              <label>Send Transcript Emails To</label>
              <input
                type="email"
                className="form-control small"
                placeholder="Please enter the email"
                onChange={(e) => handleChange('model', 'adminEmail', e.target.value)}
                value={model.adminEmail}
              />
              {/* <span className="error_mesage required">{errors.email}</span> */}
            </Box>
            <Box component="div" className="inputField">
              <label>Temperature</label>
              {/* <span className="small-text">0</span> */}
              {/* <Box px={3}>
                <Slider
                  step={33.33}
                  min={33}
                  max={100}
                  valueLabelDisplay="off"
                  onChange={handleAgeSliderChange}
                  aria-labelledby="track-false-slider"
                  value={model.temperature}
                  marks={marks}
                />
              </Box> */}
              <Box>
                <FormControl fullWidth component="fieldset">
                  <RadioGroup
                    // @ts-ignore
                    onChange={handleAgeSliderChange}
                    value={`${model.temperature}`}
                    aria-label="temprature"
                    name="temprature"
                    row
                  >
                    <FormControlLabel
                      value={'33'}
                      // checked={model.temperature === 33}
                      control={<Radio color="primary" />}
                      label="Reserved"
                    />
                    <FormControlLabel
                      value={'66'}
                      control={<Radio color="primary" />}
                      // checked={model.temperature === 66}
                      label="Balanced"
                    />
                    <FormControlLabel
                      value={'99'}
                      control={<Radio color="primary" />}
                      // checked={model.temperature === 99}
                      label="Creative"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {/* <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box className="small-text">Reserved</Box>
                <Box className="small-text">Creative</Box>
              </Stack> */}
            </Box>
            {/* <Box>
              <Stack spacing={3} ml={-1.5} mb={1}> */}
            {/* @ts-ignore */}
            {/* <Grid container alignItems="center" mb={3}>
                  <Grid item>
                    <CustomSwitch
                      checked={model.examplesLimit?.active || false}
                      onChange={(e: any) =>
                        handleChange('model', 'examplesLimit', e.target.checked, 'active')
                      }
                      name="isVanityDomain"
                      color="primary"
                    />
                  </Grid>

                  <Grid item>
                    <Stack direction={'row'}>
                      <p className="paragraph secondary-text">
                        <strong>
                          Include Examples in prompt */}
            {/* {this.state.editPageRequest === true
                            ? this.props.events.languageCodeForRedux?.formfields
                                ?.overview?.enableVan
                            : englishData.engData.formfields.overview.enableVan} */}
            {/* </strong>
                      </p>
                    </Stack>
                  </Grid>
                  <Box component="div" className="inputField" mb={2}>
                    <label>Total</label>
                    <input
                      className="form-control"
                      value={model.examplesLimit?.total}
                      type="number"
                      onChange={(e) =>
                        handleChange('model', 'examplesLimit', e.target.value, 'total')
                      }
                    />
                  </Box>
                  <Box component="div" className="inputField" mb={2}>
                    <label>Positive</label>
                    <input
                      className="form-control"
                      value={model.examplesLimit?.positive}
                      type="number"
                      onChange={(e) =>
                        handleChange('model', 'examplesLimit', e.target.value, 'positive')
                      }
                    />
                  </Box>
                  <Box component="div" className="inputField" mb={2}>
                    <label>Negative</label>
                    <input
                      className="form-control"
                      value={model.examplesLimit?.negative}
                      type="number"
                      onChange={(e) =>
                        handleChange('model', 'examplesLimit', e.target.value, 'negative')
                      }
                    />
                  </Box>
                </Grid>
              </Stack>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}
