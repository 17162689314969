import React, { useEffect } from 'react';
import { theme } from '../../theme/theme';
import {
  Box,
  Popper,
  Fade,
  ClickAwayListener,
  MenuList,
  MenuItem,
  OutlinedInput,
  Button,
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useParams } from 'react-router';
import { Stack } from '@mui/material';

const CustomSearchableMultiSelectedDropdownWithLoadMoreOPM = ({
  options,
  handleChange,
  handleSearchListInSearchableDropdown,
  dropdownFor,
  listOfSelectedIds,
  handleAllCheckbox,
  handleClearAll,
  handlePageChange,
  disabled
}) => {

  const params = useParams();
  const anchorRef = React.useRef(null);
  const checkedRef = React.useRef(null);

  const [searchDropdown, setSearchDropdown] = React.useState(false);
  const [searchEnabled, setSearchEnabled] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [isClearAllChecked, setIsClearAllChecked] = React.useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  useEffect(() => {
    handleSearchListInSearchableDropdown(searchText);
  }, [searchText]);

  const handleSearchDropdown = (event) => {
    setSearchDropdown(true);
  };

  const handleSearchDropdownClose = (event) => {
    setSearchText('');
    setSearchDropdown(false);
    setSearchEnabled(false);
  };
  const handleClearSearchDropdown = () => {
    !searchText && setSearchDropdown(false);
    setSearchEnabled(false);
    setSearchText('');
    // handleChange({});
  };

  const handleSearchEnabled = (e) => {
    setSearchDropdown(true);
    setSearchEnabled(true);
    setSearchText(e.target.value);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      !searchText && setSearchDropdown(false);
    }
  }

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  return (
    <>
      <Box
        className='multiselect-input'
      >
        <OutlinedInput
          sx={{
            marginTop: '2px',
            padding: '3px 0px',
          }}
          notched={false}
          size="medium"
          fullWidth
          placeholder={
            !options?.length
              ? 'There are no records to display'
              : listOfSelectedIds?.length
                ? `${listOfSelectedIds?.length} ${listOfSelectedIds?.length === 1 ? `${dropdownFor === "users" ? "User" : "Project"}` : `${dropdownFor === "users" ? "Users" : "Projects"}`
                } Selected`
                : `Search ${dropdownFor === "users" ? "User" : "Project"}`
          }
          name=""
          value={searchEnabled ? searchText : (isActive ? searchText : '')}
          className={`${listOfSelectedIds?.length ? 'multiselect-input' : ''}`}
          ref={anchorRef}
          aria-controls={searchDropdown ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleSearchDropdown}
          onChange={handleSearchEnabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputProps={{
            maxLength: 63,
          }}
          // disabled={!options.length}
          endAdornment={
            <InputAdornment position="end">
              {searchText && (
                <IconButton
                  aria-label="clear search"
                  onClick={handleClearSearchDropdown}
                  edge="end"
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}

              <ArrowDropDownIcon />
            </InputAdornment>
          }
        />
        <Popper
          open={searchDropdown}
          style={{ zIndex: 1099, width: '100%', minWidth: 300, }}
          placement="bottom-start"
          transition
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Box
                sx={{
                  maxHeight: '40vh',
                  overflow: 'scroll',
                  backgroundColor: theme.palette.common.white,
                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              >
                <ClickAwayListener onClickAway={handleSearchDropdownClose}>
                  <Box id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuList>
                      {dropdownFor === "projects" && <MenuItem
                        value={'selectAll'}
                        className="search-item"
                        onClick={() => {
                          setSearchEnabled(false);
                        }}
                        disabled={false}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{
                                padding: 0,
                                marginRight: '8px',
                              }}
                              color="primary"
                            />
                          }
                          onChange={(e, checked) => {
                            setIsSelectAllChecked(prev => !prev);
                            setIsClearAllChecked(false);
                            handleAllCheckbox(checked);
                          }}
                          label={'Select All'}
                          value={'Select All'}
                          style={{
                            padding: 0,
                            margin: 0,
                          }}
                          ref={checkedRef}
                          checked={isSelectAllChecked}
                        />
                      </MenuItem>}
                      {dropdownFor === "projectsChatWidgetList" && 
                      Array.isArray(options) &&
                        options.length > 0 &&
                      <MenuItem
                        value={'clearAll'}
                        className="search-item"
                        onClick={() => {
                          setSearchEnabled(false);
                        }}
                        disabled={false}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{
                                padding: 0,
                                marginRight: '8px',
                              }}
                              color="primary"
                            />
                          }
                          onChange={(e, checked) => {
                            setIsClearAllChecked(prev => !prev);
                            setIsSelectAllChecked(false);
                            handleClearAll(checked);
                          }}
                          label={'Clear All'}
                          value={'clearAll'}
                          style={{
                            padding: 0,
                            margin: 0,
                          }}
                          ref={checkedRef}
                          checked={isClearAllChecked}
                        />
                      </MenuItem>
                      
                      }
                      {Array.isArray(options) &&
                        options.length > 0 ?
                        (
                          options?.map((option) => {
                            const isSelected = listOfSelectedIds.some(
                              (selected) => selected._id === option._id
                            );
                          return (
                            <MenuItem
                              value={option._id}
                              className="search-item"
                              key={option._id}
                              onClick={() => {
                                setSearchEnabled(false);
                              }}
                              disabled={
                                dropdownFor === 'projects'
                                  ? option.assignedToChatbot
                                    ? option.assignedToChatbot === params?.id
                                      ? false
                                      : true
                                    : false
                                  : false
                              }
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{
                                      padding: 0,
                                      marginRight: '8px',
                                    }}
                                    color="primary"
                                  />
                                }
                                label={
                                  ((dropdownFor === 'projects' ||
                                    dropdownFor === 'projectsChatWidgetList') &&
                                  option.eventName) || option.email
                                }
                                value={option._id}
                                onChange={(e, checked) => {
                                  setIsClearAllChecked(false);
                                  setIsSelectAllChecked(false);
                                  handleChange(option , checked);
                                }}
                                style={{
                                  padding: 0,
                                  margin: 0,
                                }}
                                checked={isSelected}
                              />
                            </MenuItem>
                         );
                        })
                      ) : (

                        <MenuItem className="" style={{ justifyContent: 'center' }}  >
                          <Box className='long-text'  >
                            No records
                          </Box>
                        </MenuItem>
                      )}
                    </MenuList>
                    {dropdownFor !== 'users' && Array.isArray(options) &&
                      options.length > 0 &&
                      <Stack justifyContent={"center"} alignItems={"center"} pb={1} >
                        <Button variant="outlined" size="small" onClick={() => {
                          handlePageChange();
                          setIsSelectAllChecked(false);
                        }} disabled={disabled}>Load More</Button>
                      </Stack>
                    }

                  </Box>
                </ClickAwayListener>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default CustomSearchableMultiSelectedDropdownWithLoadMoreOPM;
