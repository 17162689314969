import Box from '@mui/material/Box';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import CustomSwitch from 'Components/customSwitch';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { CustomFields } from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import { TempratureType } from '../../SettingsDashboard';
import { OpportunityType } from 'Redux/Reducers/aiChatbot.reducer';
import Loader from 'Components/Loader/loader';
import MoreVertIcon from '@material-ui/icons/MoreVert';

type CustomFieldsFrontEnd = {
  fieldId: string;
  fieldName: string;
  required: boolean;
  fieldType: string;
  isPrimaryContact?: boolean;
  fields?: CustomFieldsFrontEnd[];
  checked: boolean;
  fieldKey?: string;
};

export type CustomFieldsList = CustomFieldsFrontEnd[];

type CustomFieldsDB = {
  fieldId: string;
  fieldName: string;
  required: boolean;
  fieldType: string;
  isPrimaryContact?: boolean;
  fields?: CustomFieldsDB[];
  checked?: boolean;
  fieldKey?: string;
};

type CustomFieldsArray = CustomFieldsDB[];

type OpportunityProps = {
  save?: (data: any) => void;
  handleChange: (
    tab: 'model' | 'opportunity' | 'chatInterface' | 'security',
    toChange: string,
    newVal: string | TempratureType | CustomFieldsList,
  ) => void;
  customFieldsArray: CustomFieldsArray;
  collectOpportunityData?: boolean;
};
function Opportunity({
  handleChange,
  customFieldsArray,
  collectOpportunityData,
}: OpportunityProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [enableOpportunityData, setEnableOpportunintyData] = useState(false);
  const [customFieldsList, setCustomFieldsList] = useState<CustomFieldsList>(
    [],
  );

  useEffect(() => {
    setEnableOpportunintyData(Boolean(collectOpportunityData));
  }, [collectOpportunityData]);
  useEffect(() => {
    fetchAllOpportunityCustomField();
  }, []);

  const fetchAllOpportunityCustomField = async () => {
    const opportunityAccountsCustomFieldsAPI =
      new OpportunityAccountsCustomFieldsAPI();

    let customFieldsFromAccounts: CustomFields = [];
    let customFieldsFromContacts: CustomFields = [];
    let customFieldsFromOpportunity: CustomFields = [];

    let arr = ['opportunity', 'contacts', 'accounts'];

    try {
      setIsLoading(true);
      const results = await Promise.all(
        arr.map(async (el) => {
          const data = { customFieldType: el };
          const res =
            await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
              data,
            );
          return { el, customFields: res.data.customFields };
        }),
      );
      setIsLoading(false);

      results.forEach(({ el, customFields }) => {
        if (el === 'accounts') {
          customFieldsFromAccounts = customFields;
        } else if (el === 'contacts') {
          customFieldsFromContacts = customFields;
        } else if (el === 'opportunity') {
          customFieldsFromOpportunity = customFields;
        }
      });
    } catch (error) {
      console.log(error);
    }

    settingCustomFieldsIntoDesiredFormat(
      customFieldsFromAccounts,
      customFieldsFromContacts,
      customFieldsFromOpportunity,
    );
  };

  const settingCustomFieldsIntoDesiredFormat = (
    customFieldsFromAccounts: CustomFields,
    customFieldsFromContacts: CustomFields,
    customFieldsFromOpportunity: CustomFields,
  ) => {
    let arr = customFieldsFromOpportunity.map((cf) => {
      let obj: CustomFieldsFrontEnd = {
        fieldId: cf._id as string,
        fieldName: cf.fieldData.fieldName,
        required: cf.isMandatory,
        fieldType: cf.fieldData.fieldType,
        checked: cf.isMandatory,
      };

      if (cf.fieldData.fieldType === 'contact') {
        obj.fields = [
          {
            fieldId: '',
            fieldName: 'First Name',
            required: true,
            fieldType: 'text',
            checked: true,
            fieldKey: 'firstName',
          },
          {
            fieldId: '',
            fieldName: 'Middle Name',
            required: false,
            fieldType: 'text',
            checked: false,
            fieldKey: 'middleName',
          },
          {
            fieldId: '',
            fieldName: 'Last Name',
            required: false,
            fieldType: 'text',
            checked: false,
            fieldKey: 'lastName',
          },
          {
            fieldId: '',
            fieldName: 'Email',
            required: false,
            fieldType: 'email',
            checked: false,
            fieldKey: 'email',
          },
        ];

        customFieldsFromContacts.forEach((cfFromContacts) => {
          obj.fields?.push({
            fieldId: cfFromContacts._id as string,
            fieldName: cfFromContacts.fieldData.fieldName,
            required: cfFromContacts.isMandatory,
            fieldType: cfFromContacts.fieldData.fieldType,
            checked: cfFromContacts.isMandatory,
            //@ts-ignore
            fieldKey: cfFromContacts._id
              ? null
              : cfFromContacts.fieldData.fieldName?.replaceAll(' ', ''),
          });
        });
      }
      if (cf.fieldData.fieldType === 'account') {
        obj.fields = [
          {
            fieldId: '',
            fieldName: 'Account Name',
            required: true,
            fieldType: 'text',
            checked: true,
            fieldKey: 'name',
          },
        ];

        customFieldsFromAccounts.map((cfFromAccounts) => {
          obj.fields?.push({
            fieldId: cfFromAccounts._id as string,
            fieldName: cfFromAccounts.fieldData.fieldName,
            required: cfFromAccounts.isMandatory,
            fieldType: cfFromAccounts.fieldData.fieldType,
            checked: cfFromAccounts.isMandatory,
            //@ts-ignore
            fieldKey: cfFromAccounts._id
              ? null
              : cfFromAccounts.fieldData.fieldName?.replaceAll(' ', ''),
          });
        });
      }
      return obj;
    });
    updateCheckedProperties(customFieldsArray, [...customFieldsList, ...arr]);
    handleChange('opportunity', 'customFieldsArray', [
      ...customFieldsList,
      ...arr,
    ]);
  };

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    i: number,
  ) => {
    const prev = [...customFieldsList];
    if (e.target.checked) {
      prev[i].checked = true;
    } else {
      prev[i].checked = false;
    }
    setCustomFieldsList(prev);

    handleChange('opportunity', 'customFieldsArray', prev);
  };
  const handlePrimaryContactStatus = (value: boolean, i: number) => {
    const prev = [...customFieldsList];
    prev.forEach((cf) => (cf.isPrimaryContact = false));
    prev[i]['isPrimaryContact'] = value;
    prev[i].required = value;
    if (value === true) {
      prev[i].checked = true;
    }
    setCustomFieldsList(prev);
    handleChange('opportunity', 'customFieldsArray', prev);
  };

  const handleCheckboxChangeForInnerCf = (
    e: ChangeEvent<HTMLInputElement>,
    i: number,
    j: number,
  ) => {
    const prev = [...customFieldsList];
    if (e.target.checked) {
      // @ts-ignore
      prev[i].fields[j].checked = true;
    } else {
      // @ts-ignore
      prev[i].fields[j].checked = false;
    }
    setCustomFieldsList(prev);

    handleChange('opportunity', 'customFieldsArray', prev);
  };

  const handleToggle = (value: boolean) => {
    setEnableOpportunintyData(value);
    handleChange('opportunity', 'collectOpportunityData', value);
  };

  function updateCheckedProperties(
    inTheDb: CustomFieldsArray,
    toListInFrontEnd: CustomFieldsList,
  ) {
    // Helper function to update the checked property in the fields array
    function updateFields(
      dbFields: CustomFieldsArray,
      frontEndFields: CustomFieldsList,
      dbItemChecked: Boolean | undefined,
    ) {
      dbFields.forEach((dbField) => {
        frontEndFields.forEach((frontEndField) => {
          if (
            (dbField.fieldId && dbField.fieldId === frontEndField.fieldId) ||
            (!dbField.fieldId && dbField.fieldName === frontEndField.fieldName)
          ) {
            if (frontEndField.required === false) {
              if (dbItemChecked && dbField.checked) {
                frontEndField.checked = true;
              } else {
                frontEndField.checked = false;
              }
            } else {
              if (dbItemChecked && dbField.checked) {
                frontEndField.checked = true;
              }
            }
          }
          // if (dbField.fields && frontEndField.fields) {
          //   updateFields(dbField.fields, frontEndField.fields);
          // }
        });
      });
    }

    // Main function to update the checked property
    inTheDb.forEach((dbItem) => {
      // console.log('D B I', dbItem);
      toListInFrontEnd.forEach((frontEndItem) => {
        if (
          (dbItem.fieldId && dbItem.fieldId === frontEndItem.fieldId) ||
          (!dbItem.fieldId && dbItem.fieldName === frontEndItem.fieldName)
        ) {
          frontEndItem['isPrimaryContact'] = dbItem.isPrimaryContact;
          frontEndItem.required = dbItem.required;
          if (dbItem.checked) {
            frontEndItem.checked = true;
          }
          if (dbItem.fields && frontEndItem.fields) {
            updateFields(dbItem.fields, frontEndItem.fields, dbItem.checked);
          }
        }
      });
    });
    setCustomFieldsList(toListInFrontEnd);
  }

  const [expandedRows, setExpandedRows] = useState({});

  const handleExpandClick = (index: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific row's expanded state
    }));
  };

  // Test the function

  return (
    <Box className="tab-panel">
      <Box className="tab-content">
        <Box>
          {/* <Box component="div" className="inputField" mb={2}>
            <label>Title</label>
            <input
              type="text"
              className="form-control small"
              placeholder="Let us know how to contact"
            />
          </Box> */}
          <Box mt={2} className="inputField">
            <label>Choose Custom Fields</label>

            <Box className="small-subtitle">
              Choose the minimum number of fields you'd like to configure. This
              will streamline the interaction, ensuring the AI Agent asks fewer
              questions, thus improving your experience.
            </Box>

            <label>
              <Box component={'span'}>Collect opportunity data</Box>

              <CustomSwitch
                name="toggleOpportunityData"
                checked={collectOpportunityData}
                color="primary"
                onChange={(e: any) => handleToggle(e.target.checked)}
              />
            </label>
          </Box>
        </Box>

        {/* ---accordion table--- */}
        <Box mt={2}>
          <TableContainer
            style={{
              maxHeight: 'calc(100vh - 505px)',
              minHeight: '400px',
              overflowY: 'auto',
            }}
          >
            <Table className="">
              {Array.isArray(customFieldsList) &&
                customFieldsList.length > 0 &&
                customFieldsList.map((cf, i) => {
                  return cf.fieldType === 'contact' ||
                    cf.fieldType === 'account' ? (
                    <TableBody key={cf.fieldId}>
                      <TableRow>
                        <TableCell
                          className="checkbox"
                          style={{
                            minWidth: '30px',
                            maxWidth: '30px',
                            width: '30px',
                          }}
                        >
                          <Checkbox
                            style={{
                              padding: 0,
                              marginRight: '8px',
                            }}
                            color="primary"
                            disabled={cf.required}
                            checked={cf.checked ? true : false}
                            onChange={(e) => handleCheckboxChange(e, i)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {cf.fieldName}
                          <Box component={'span'} ml={1}>
                            <IconButton
                              size="small"
                              aria-label="expand row"
                              onClick={() => handleExpandClick(i)}
                            >
                              {expandedRows[i] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Box
                            display={'flex'}
                            justifyContent={'end'}
                            alignItems={'center'}
                          >
                            {cf.isPrimaryContact ? (
                              <Box
                                component={'span'}
                                mr={3}
                                className="table-status success"
                              >
                                Primary
                              </Box>
                            ) : null}

                            {cf.fieldType === 'contact' ? (
                              <Box className="options">
                                <MoreVertIcon
                                  style={{ fontSize: '18px' }}
                                  className="options-button"
                                />
                                <div className="options-list">
                                  {!cf.isPrimaryContact ? (
                                    <div
                                      onClick={() =>
                                        handlePrimaryContactStatus(true, i)
                                      }
                                    >
                                      Set as Primary
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        handlePrimaryContactStatus(false, i)
                                      }
                                    >
                                      Remove as Primary
                                    </div>
                                  )}
                                </div>
                              </Box>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={3}>
                          {cf.checked && (
                            <Collapse
                              in={expandedRows[i]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box ml={5}>
                                <Table
                                  className=" inner-table"
                                  aria-label="purchases"
                                >
                                  <TableBody>
                                    {Array.isArray(cf.fields) &&
                                      cf.fields?.length > 0 &&
                                      cf.fields?.map((innerCf, j) => {
                                        return (
                                          <TableRow key={j}>
                                            <TableCell
                                              className="checkbox"
                                              style={{
                                                minWidth: '30px',
                                                maxWidth: '30px',
                                                width: '30px',
                                              }}
                                            >
                                              <Checkbox
                                                style={{
                                                  padding: 0,
                                                  marginRight: '8px',
                                                }}
                                                color="primary"
                                                disabled={
                                                  innerCf.required === true
                                                }
                                                checked={
                                                  innerCf.checked === true
                                                }
                                                onChange={(e) =>
                                                  handleCheckboxChangeForInnerCf(
                                                    e,
                                                    i,
                                                    j,
                                                  )
                                                }
                                              />
                                            </TableCell>
                                            <TableCell align="left">
                                              {innerCf.fieldName}{' '}
                                              {innerCf.required && (
                                                <span className="required">
                                                  *
                                                </span>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className="checkbox"
                          style={{
                            minWidth: '30px',
                            maxWidth: '30px',
                            width: '30px',
                          }}
                        >
                          <Checkbox
                            style={{
                              padding: 0,
                              marginRight: '8px',
                            }}
                            color="primary"
                            disabled={cf.required}
                            checked={cf.checked ? true : false}
                            onChange={(e) => handleCheckboxChange(e, i)}
                          />
                        </TableCell>
                        <TableCell align="left" colSpan={2}>
                          {cf.fieldName}{' '}
                          {cf.required && <span className="required">*</span>}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {isLoading && <Loader />}
    </Box>
  );
}

export default Opportunity;
