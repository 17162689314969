import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    fetchAllOpportunityContactsRequest: ['data'],
    fetchAllOpportunityContactsSuccess: ['contacts', 'totalCount', 'filterCount'],
    fetchAllOpportunityContactsError: ['message'],
    fetchOpportunityContactRequest: ['data'],
    fetchOpportunityContactSuccess: ['contact'],
    fetchOpportunityContactError: ['message'],
    createOpportunityContactRequest: ['data'],
    createOpportunityContactSuccess: ['contact', 'contacts', 'totalCount', 'filterCount'],
    createOpportunityContactError: ['message'],
    updateOpportunityContactRequest: ['data'],
    updateOpportunityContactSuccess: ['contact', 'contacts', 'totalCount', 'filterCount'],
    updateOpportunityContactError: ['message'],
    deleteOpportunityContactRequest: ['data'],
    deleteOpportunityContactSuccess: ['contacts', 'totalCount', 'filterCount'],
    deleteOpportunityContactError: ['message'],
    updateMultipleOpportunityContactsRequest: ['data'],
    updateMultipleOpportunityContactsSuccess: ['contacts', 'totalCount', 'filterCount'],
    updateMultipleOpportunityContactsError: ['message'],
    assignOrUnassignOpportunityContactsRequest: ['data'],
    assignOrUnassignOpportunityContactsSuccess: ['contacts', 'totalCount', 'filterCount'],
    assignOrUnassignOpportunityContactsError: ['message'],
    getSmartGroupsContactsRequest: ['data'],
    getSmartGroupsContactsSuccess: ['contacts', 'totalCount', 'filterCount'],
    getSmartGroupsContactsError: ['message'],
    addUpdateIncludeSubGroupContactRequest: ['data'],
    addUpdateIncludeSubGroupContactSuccess: ['message'],
    addUpdateIncludeSubGroupContactError: ['message'],

});

export const Constants = Types;

export default Creators;
